import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import '../../style/slidemenu.css';

function SlideMenu({ toggleSlideMenu, slide_menu, title, children }) {
  return (
    <Container className={`slideout ${slide_menu ? 'slidein' : ''} px-0`}>
      <Container>
        <Row className="text-left">
          <Col className="d-flex justify-content-start align-items-center">
            <Button
              onClick={toggleSlideMenu}
              close
              style={{ fontSize: '2rem' }}
            />
            <h3 className="ml-3 my-0">{title}</h3>
          </Col>
        </Row>
      </Container>
      {children}
    </Container>
  );
}

export default SlideMenu;
