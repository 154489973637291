import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "../actions/address-actions";
import { add_toast } from "../actions/toasts-actions";
import { set_sel_form } from "../actions/platform-actions";
import { getInfoUser } from "./user-sagas";
import { getStateLang } from "./utils";
import { api_private } from "../../services";

function* putUpdateAddress({ idAddress, dataAddress }) {
  yield put(actions.reset_address());
  yield put(actions.request_address());
  const lang = yield getStateLang();
  try {
    const updated = yield call(api_private.updateAddress, idAddress, {
      ...dataAddress
    });
    if (updated.error) {
      yield put(actions.error_address(updated.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: updated.error[lang]
        })
      );
      yield put(actions.cancel_address());
    } else {
      yield put(actions.success_address(updated.message[lang]));
      yield put(
        add_toast({
          error: false,
          text: updated.message[lang]
        })
      );
      yield put(actions.cancel_address());
      yield getInfoUser();
    }
  } catch (error) {
    yield put(actions.error_address(error.toString()));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_address());
  } finally {
    yield put(set_sel_form(""));
  }
}

export function* watchAddress() {
  yield takeLatest(actions.START_UPDATE_ADDRESS, putUpdateAddress);
}
