import React from "react";
import { connect } from "react-redux";
import { AddressForm } from "../../components/forms";
import { start_add_address_user } from "../../redux/actions/user-actions";

function AddressFormCont({ start_add_address_user, ...rest }) {
  return <AddressForm startAddAddress={start_add_address_user} {...rest} />;
}

const mapStateToProps = ({ addressReducer }) => ({
  ...addressReducer
});

const mapActionsToProps = {
  start_add_address_user
};

export default connect(mapStateToProps, mapActionsToProps)(AddressFormCont);
