import {
  REQUEST_CATEGORY,
  CANCEL_CATEGORY,
  SUCCESS_CATEGORY,
  ERROR_CATEGORY,
  RESET_CATEGORY,
  SET_ALL_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SET_FILTER_CATEGORIES,
  ADD_FILTER_CATEGORY,
  REMOVE_FILTER_CATEGORY
} from '../actions/category-actions';

export const categoryReducer = (
  state = {
    isFetching: false,
    allCategories: null,
    message: null,
    error: null,
    selectedCategory: null,
    filterCategories: []
  },
  action
) => {
  const {
    isFetching,
    allCategories,
    message,
    error,
    selectedCategory,
    filterCategories,
    categoryId,
    category,
    type
  } = action;
  switch (type) {
    case REQUEST_CATEGORY:
      return { ...state, isFetching };
    case CANCEL_CATEGORY:
      return { ...state, isFetching };
    case SUCCESS_CATEGORY:
      return { ...state, message };
    case ERROR_CATEGORY:
      return { ...state, error };
    case SET_ALL_CATEGORIES:
      return { ...state, allCategories };
    case SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory };
    case SET_FILTER_CATEGORIES:
      return { ...state, filterCategories };
    case ADD_FILTER_CATEGORY:
      const foundAdd = state.filterCategories.find(
        (cat) => cat._id === category._id
      );
      if (foundAdd) {
        return { ...state };
      }
      return {
        ...state,
        filterCategories: [...state.filterCategories, category]
      };

    case REMOVE_FILTER_CATEGORY:
      const foundRem = state.filterCategories.find(
        (cat) => cat._id === categoryId
      );
      if (!foundRem) {
        return { ...state };
      }
      const remCatIndex = state.filterCategories.indexOf(foundRem);
      return {
        ...state,
        filterCategories: [
          ...state.filterCategories.slice(0, remCatIndex),
          ...state.filterCategories.slice(remCatIndex + 1)
        ]
      };
    case RESET_CATEGORY:
      return { ...state, isFetching, message, error };
    default:
      return { ...state };
  }
};
