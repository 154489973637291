import { v4 as uuidv4 } from "uuid";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const ADD_TOAST = "ADD_TOAST";

export const add_toast = (toast) => ({
  type: ADD_TOAST,
  toast: { ...toast, idToast: uuidv4() }
});

export const remove_toast = (idToast) => ({
  type: REMOVE_TOAST,
  idToast
});
