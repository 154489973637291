import { connect } from 'react-redux';
import { InlineSignup } from '../../components/elements';
import { set_slide_content } from '../../redux/actions/platform-actions';

function InlineSignupCont({ set_slide_content, ...rest }) {
  return <InlineSignup setSlideContent={set_slide_content} {...rest} />;
}

const mapStateToProps = ({
  signupReducer,
  globalReducer: { lang },
  loginReducer: { user }
}) => ({
  ...signupReducer,
  user,
  lang
});

const mapActionsToProps = {
  set_slide_content
};

export default connect(mapStateToProps, mapActionsToProps)(InlineSignupCont);
