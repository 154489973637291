import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductForm } from '../../components/forms';
import {
  create_product,
  cancel_one_product
} from '../../redux/actions/product-actions';
import { list_categories } from '../../redux/actions/category-actions';
import { set_sel_form } from '../../redux/actions/platform-actions';
import { add_toast } from '../../redux/actions/toasts-actions';

function ProductFormCont({
  list_categories,
  cancel_one_product,
  set_sel_form,
  create_product,
  add_toast,
  allCategories,
  ...rest
}) {
  const cancelRequest = () => {
    cancel_one_product();
    set_sel_form('');
  };
  useEffect(() => {
    if (allCategories === null) {
      list_categories('');
    }
  }, [list_categories, allCategories]);
  return (
    <ProductForm
      createProduct={create_product}
      cancelRequest={cancelRequest}
      allCategories={allCategories}
      addToast={add_toast}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  productReducer,
  categoryReducer,
  globalReducer: { lang }
}) => ({
  ...categoryReducer,
  ...productReducer,
  lang
});

const mapActionsToProps = {
  list_categories,
  create_product,
  cancel_one_product,
  set_sel_form,
  add_toast
};

export default connect(mapStateToProps, mapActionsToProps)(ProductFormCont);
