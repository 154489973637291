import { combineReducers } from 'redux';

import { globalReducer } from './global-reducer';
import { loginReducer } from './login-reducer';
import { signupReducer } from './signup-reducer';
import { toastsReducer } from './toasts-reducer';
import { platformReducer } from './platform-reducer';
import { cartReducer } from './cart-reducer';
import { productReducer } from './product-reducer';
import { contactReducer } from './contact-reducer';
import { orderReducer } from './order-reducer';
import { categoryReducer } from './category-reducer';
import { checkoutReducer } from './checkout-reducer';
import { addressReducer } from './address-reducer';
import { userReducer } from './user-reducer';

const rootReducer = () =>
  combineReducers({
    globalReducer,
    loginReducer,
    toastsReducer,
    platformReducer,
    cartReducer,
    productReducer,
    contactReducer,
    signupReducer,
    orderReducer,
    categoryReducer,
    checkoutReducer,
    addressReducer,
    userReducer
  });

export default rootReducer;
