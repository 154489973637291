import { Container, Row, Col } from 'reactstrap';
import {
  SideBarCont,
  FormModalCont,
  ProfileFormCont,
  CategoryFormCont,
  UserProfileCont,
  // UsersSubmenuCont,
  UsersTableCont,
  // ProductSubmenuCont,
  ProductsTableCont,
  ProductFormCont,
  SubmenuCont,
  OrdersTableCont,
  EditableAddressCont,
  EditableProductCont
} from '../../containers';
import { SEO } from '../elements';
import {
  PLATF_SECTIONS_MENU,
  FORMS,
  FORM_NAMES,
  USER_ROLES,
  LABELS_PLATFORM
} from '../../constants/platform';
import '../../style/platform.css';

function Platform({
  match: {
    params: { section }
  },
  lang,
  sel_form,
  select_id,
  user_role
}) {
  const renderSelForm = () => {
    switch (sel_form) {
      case FORM_NAMES.PROFILE_UPDATE:
        return <ProfileFormCont />;
      case FORM_NAMES.PRODUCT_FORM:
        return <ProductFormCont />;
      case FORM_NAMES.CATEGORY_FORM:
        return <CategoryFormCont />;
      case FORM_NAMES.EDIT_ADDRESS:
        return <EditableAddressCont />;
      case FORM_NAMES.EDIT_PRODUCT:
        return <EditableProductCont select_id={select_id} />;
      default:
        return null;
    }
  };
  return (
    <section className="platform bg-light">
      <SEO title="Platform" />
      <FormModalCont info={FORMS[sel_form]}>{renderSelForm()}</FormModalCont>

      <Container fluid>
        <Row className="flex-md-nowrap">
          <SideBarCont className="side_bar d-none d-lg-flex" />
          <Col className="px-0 content_col">
            <InnerRoute routeName={''} currSect={section} user_role={user_role}>
              {user_role === USER_ROLES.USER ||
              user_role === USER_ROLES.MANAGER ? (
                <Container className="">
                  <h1>Perfil de usuario</h1>

                  <Row className="justify-content-center">
                    <Col xs={12} md={5}>
                      <UserProfileCont />
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Container>
                  <h1>Órdenes</h1>

                  <OrdersTableCont user_role={user_role} className="my-3" />
                </Container>
              )}
            </InnerRoute>
            <InnerRoute
              routeName={'orders'}
              currSect={section}
              user_role={user_role}
            >
              <Container className="">
                <h1>Órdenes</h1>

                <OrdersTableCont user_role={user_role} className="my-3" />
                <p>
                  En caso de que tengas problemas, no dudes en contactarnos al
                  Teléfono (222) 5828031
                </p>
              </Container>
            </InnerRoute>
            <InnerRoute
              routeName={'profile'}
              currSect={section}
              user_role={user_role}
            >
              <Container className="">
                <h1>Perfil de usuario</h1>
                <Row className="justify-content-center">
                  <Col xs={12} md={5}>
                    <UserProfileCont />
                  </Col>
                </Row>
              </Container>
            </InnerRoute>
            <InnerRoute
              routeName={'products'}
              currSect={section}
              user_role={user_role}
            >
              <Container className="">
                <Row>
                  <Col xs={4}>
                    <h1>Productos</h1>
                  </Col>
                  <Col className="d-none d-md-flex">
                    <SubmenuCont
                      formToSetCategory={FORM_NAMES.CATEGORY_FORM}
                      formToSet={FORM_NAMES.PRODUCT_FORM}
                      buttonLabel={LABELS_PLATFORM.CREATE_PRODUCT[lang]}
                    />
                  </Col>
                </Row>
                <Row className="d-flex d-md-none">
                  <Col>
                    <SubmenuCont
                      formToSetCategory={FORM_NAMES.CATEGORY_FORM}
                      formToSet={FORM_NAMES.PRODUCT_FORM}
                      buttonLabel={LABELS_PLATFORM.CREATE_PRODUCT[lang]}
                    />
                  </Col>
                </Row>

                <ProductsTableCont formEditAction={FORM_NAMES.EDIT_PRODUCT} />
              </Container>
            </InnerRoute>
            <InnerRoute
              routeName={'users'}
              currSect={section}
              user_role={user_role}
            >
              <Container className="">
                <Row>
                  <Col xs={4}>
                    <h1>Usuarios</h1>
                  </Col>
                  <Col>{/* <UsersSubmenuCont /> */}</Col>
                </Row>

                <UsersTableCont className="my-3" />
              </Container>
            </InnerRoute>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function InnerRoute({ children, user_role, routeName, currSect = '' }) {
  const found = PLATF_SECTIONS_MENU.find(
    (el) => el.shortname === routeName && el.shortname === currSect
  );
  return (
    <>
      {found && found.visible_for_roles.includes(user_role) ? children : null}
    </>
  );
}

export default Platform;
