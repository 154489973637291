import { useHistory } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  // Table,
  ListGroup,
  ListGroupItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LABELS_PLATFORM,
  CART_LABELS,
  SLIDE_MENU_NAMES_CONTENT
} from '../../constants/platform';
import { formatNmxn } from '../../utils';

import '../../style/cart.css';

function Cart({
  incProductQuantity,
  decProductQuantity,
  remProductFromCart,
  setCheckout,
  setSlideContent,
  setSelectedProduct,
  setContinueBuying,
  total,
  cartProducts,
  lang,
  className
}) {
  const history = useHistory();
  const continueShopping = () => {
    setSlideContent('');
    // setContinueBuying();
    let path = `/configurator`;
    history.push({ pathname: path, state: { detail: 'continue_shoping' } });
  };
  const currency = useMemo(() => {
    if (!cartProducts.length) {
      return null;
    }
    const currencies = new Map();
    cartProducts.forEach((prod) => currencies.set(prod.currency));
    return [...currencies.keys()][0];
  }, [cartProducts]);

  const [currentOption, setCurrentOption] = useState(null);

  if (currentOption != null) {
    setSelectedProduct(currentOption);
    setSlideContent(SLIDE_MENU_NAMES_CONTENT.UNITS_CALCULATOR);
  }

  return (
    <Container className={`${className} animated fadeInLeft mt-3`}>
      <Row>
        <Col>
          {cartProducts && cartProducts.length ? (
            <>
              <ListGroup>
                {cartProducts.map((el, i) => (
                  <ListGroupItem
                    className="d-md-flex align-items-center border-bottom px-0"
                    key={i}
                  >
                    <Container>
                      <Row className="align-items-center">
                        <Col xs="2">
                          <img
                            className="img-fluid cart_img rounded"
                            src={
                              el.images[1] === undefined
                                ? el.images[0]
                                : el.images[1]
                            }
                            alt={`product ${el.short_name}`}
                          />
                        </Col>
                        <Col xs="4">
                          {el.short_name} <br />
                          <small className="text-muted">
                            {el.long_name}
                          </small>{' '}
                          <br />
                          <small>
                            <b>{`sku: ${el.sku}`}</b>
                          </small>
                        </Col>
                        <Col xs="6">
                          <div className="d-flex align-items-center justify-content-between">
                            <ButtonGroup>
                              <Button
                                onClick={() => decProductQuantity(el._id)}
                                outline
                              >
                                <FontAwesomeIcon icon="minus-circle" />
                              </Button>
                              <Button disabled outline>
                                {el.quantity}
                              </Button>
                              <Button
                                onClick={() => incProductQuantity(el._id)}
                                outline
                              >
                                <FontAwesomeIcon icon="plus-circle" />
                              </Button>
                            </ButtonGroup>
                            <Button
                              onClick={() => remProductFromCart(i)}
                              close
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-start">
                        <Col xs={8} className="">
                          <p className="text-bold">{`${formatNmxn(
                            el.price
                          )} x ${el.quantity} = ${formatNmxn(
                            el.quantity * el.price
                          )} ${el.currency} `}</p>
                        </Col>
                      </Row>
                      <Button
                        className="bg-transparent border-0 text-dark"
                        tag={Button}
                        onClick={() => {
                          setCurrentOption(el);
                        }}
                      >
                        {' '}
                        <FontAwesomeIcon icon="calculator" /> Calcular rollos
                      </Button>
                    </Container>
                  </ListGroupItem>
                ))}
              </ListGroup>
              <Row className="pl-4 pt-3 ">
                <p className="text-dark">
                  {' '}
                  * ¿No sabes cuantos rollos necesitas? <br />
                  &nbsp;&nbsp;&nbsp;Utiliza nuestra calculadora.
                </p>
              </Row>{' '}
            </>
          ) : (
            <ListGroupItem className="empty_cart">
              <FontAwesomeIcon className="mb-5" size="3x" icon="store-slash" />
              <h3>{CART_LABELS.EMPTY_CART[lang]}</h3>
            </ListGroupItem>
          )}
        </Col>
      </Row>
      {total > 0 && (
        <>
          <hr />
          <Row className="text-right">
            <Col>
              <h3 className="d-flex justify-content-between">
                <b>Total</b>
                <b>{`${formatNmxn(total)} ${currency}`}</b>
              </h3>
            </Col>
          </Row>
          <hr />
        </>
      )}
      {cartProducts.length === 0 ? null : (
        <Row className="mt-1">
          <Col>
            <Button onClick={setCheckout} color="primary" block>
              {LABELS_PLATFORM.PROCEED_TO_CHECKOUT[lang]}
            </Button>
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        <Col>
          <Button
            onClick={continueShopping}
            tag="a"
            className="rounded-pill btn_black"
            block
          >
            {LABELS_PLATFORM.CONTINUE_SHOPPING[lang]}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Cart;
