import React from 'react';

export default function Overlay({ slide_menu }) {
  return (
    <div
      style={{
        opacity: slide_menu ? 1 : 0,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.85)',
        zIndex: slide_menu ? 999 : -1,
        transition: 'opacity 0.5s ease'
      }}
    ></div>
  );
}
