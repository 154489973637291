import React from 'react';
import { connect } from 'react-redux';
import { CreatedOrder } from '../../components/elements';

function CreatedOrderCont({ ...rest }) {
  return <CreatedOrder {...rest} />;
}

const mapStateToProps = ({ checkoutReducer, globalReducer: { lang } }) => ({
  ...checkoutReducer,
  lang
});

export default connect(mapStateToProps)(CreatedOrderCont);
