import React from "react";
import { connect } from "react-redux";
import { SecBarAd } from "../../components/elements";

function SecBarAdCont({ ...rest }) {
  return <SecBarAd {...rest} />;
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(SecBarAdCont);
