import React from 'react';
import {
  Row,
  FormGroup,
  Label,
  Input,
  Col,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

function RadioListItems({ getValue, radioOptions = [], selValue }) {
  return (
    <Row>
      <Col>
        <ListGroup>
          {radioOptions.map(({ label, name, value }, i) => (
            <ListGroupItem key={i}>
              <FormGroup check>
                <Label check>
                  <Input
                    onChange={getValue}
                    type="radio"
                    checked={selValue === value}
                    value={value}
                    name={name}
                  />
                  &nbsp;&nbsp;
                  {label}
                </Label>
              </FormGroup>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
    </Row>
  );
}

export default RadioListItems;
