export const LIST_ORDERS = 'LIST_ORDERS';
export const REQUEST_ORDER = 'REQUEST_ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const REQUEST_ONE_ORDER = 'REQUEST_ONE_ORDER';
export const CANCEL_ONE_ORDER = 'CANCEL_ONE_ORDER';
export const SUCCESS_ORDER = 'SUCCESS_ORDER';
export const ERROR_ORDER = 'ERROR_ORDER';
export const RESET_ORDER = 'RESET_ORDER';

export const SET_ALL_ORDERS = 'SET_ALL_ORDERS';
export const SET_SELECT_ORDER = 'SET_SELECT_ORDER';

export const START_UPDATE_ORDER = 'START_UPDATE_ORDER';
export const START_ADD_STATUS_ORDER = 'START_ADD_STATUS_ORDER';

export const ADD_STATUS_ORDER_PRODUCT = 'ADD_STATUS_ORDER_PRODUCT';

export const REQUEST_ONE_ORDER_PRODUCT = 'REQUEST_ONE_ORDER_PRODUCT';
export const CANCEL_ONE_ORDER_PRODUCT = 'CANCEL_ONE_ORDER_PRODUCT';
export const SET_FETCHING_PRODUCT_ID = 'SET_FETCHING_PRODUCT_ID';

export const START_ONE_ORDER_CHECKOUT = 'START_ONE_ORDER_CHECKOUT';
export const SET_ORDER_CHECKOUT = 'SET_ORDER_CHECKOUT';

export const list_orders = () => ({
  type: LIST_ORDERS
});

export const start_one_order_checkout = (orderId) => ({
  type: START_ONE_ORDER_CHECKOUT,
  orderId
});

export const set_order_checkout = (orderCheckout) => ({
  type: SET_ORDER_CHECKOUT,
  orderCheckout
});

export const start_update_order = (order_id, dataOrder) => ({
  type: START_UPDATE_ORDER,
  order_id,
  dataOrder
});

export const start_add_status_order = (order_id, dataOrder) => ({
  type: START_ADD_STATUS_ORDER,
  order_id,
  dataOrder
});

export const request_order = () => ({
  type: REQUEST_ORDER,
  isFetching: true
});

export const cancel_order = () => ({
  type: CANCEL_ORDER,
  isFetching: false
});

export const request_one_order = () => ({
  type: REQUEST_ONE_ORDER,
  isFetchingOne: true
});

export const cancel_one_order = () => ({
  type: CANCEL_ONE_ORDER,
  isFetchingOne: false
});

export const success_order = (message) => ({
  type: SUCCESS_ORDER,
  message
});

export const error_order = (error) => ({
  type: ERROR_ORDER,
  error
});

export const set_all_orders = (allOrders) => ({
  type: SET_ALL_ORDERS,
  allOrders
});
export const set_select_order = (selectOrder) => ({
  type: SET_SELECT_ORDER,
  selectOrder
});

export const reset_order = () => ({
  type: RESET_ORDER,
  isFetching: false,
  isFetchingOne: false,
  error: null,
  message: null,
  isFetchingProduct: false,
  fetchingProductId: null
});

export const add_status_order_product = (
  order_id,
  product_order_id,
  dataOrder
) => ({
  type: ADD_STATUS_ORDER_PRODUCT,
  order_id,
  product_order_id,
  dataOrder
});

export const request_one_order_product = () => ({
  type: REQUEST_ONE_ORDER_PRODUCT,
  isFetchingProduct: true
});

export const cancel_one_order_product = () => ({
  type: CANCEL_ONE_ORDER_PRODUCT,
  isFetchingProduct: false
});

export const set_fetching_product_id = (fetchingProductId) => ({
  type: SET_FETCHING_PRODUCT_ID,
  fetchingProductId
});
