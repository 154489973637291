import React from "react";
import { connect } from "react-redux";
import { SignupForm } from "../../components/forms";
import { add_toast } from "../../redux/actions/toasts-actions";
import {
  start_signup,
  cancel_signup
} from "../../redux/actions/signup-actions";

function SignupFormCont({
  setLogin,
  add_toast,
  cancel_signup,
  start_signup,
  ...rest
}) {
  const cancelSignup = () => {
    cancel_signup();
    setLogin();
  };
  return (
    <SignupForm
      cancelSignup={cancelSignup}
      startSignup={start_signup}
      addToast={add_toast}
      setLogin={setLogin}
      {...rest}
    />
  );
}

const mapStateToProps = ({ signupReducer, globalReducer: { lang } }) => ({
  ...signupReducer,
  lang
});

const mapActionsToProps = {
  add_toast,
  start_signup,
  cancel_signup
};

export default connect(mapStateToProps, mapActionsToProps)(SignupFormCont);
