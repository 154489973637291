import { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Row,
  Col,
  // Collapse,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
  FormText
} from 'reactstrap';
import { AnimFailSucc } from '../elements';
// import { AddressForm } from './';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOCAL_MESSAGES, MIN_LENGTH_PASSWORD } from '../../constants';
import { LABELS_FORM_LOGIN } from '../../constants/platform';

function SignupForm({
  isFetching,
  cancelSignup,
  startSignup,
  addToast,
  error,
  message,
  setLogin,
  lang
}) {
  const [formVals, setFormVals] = useState({
    firstname: '',
    lastname: '',
    telephone: '',
    login: '',
    passW: '',
    passWConf: '',
    mailConf: '',

    // address
    city: '',
    // country: "",
    line_1: '',
    line_2: '',
    postal_code: '',
    state: ''
  });
  const [showPass, setShowPass] = useState(false);
  // const [showAddress, setShowAddress] = useState(false);
  const passWname = 'passW';
  const loginName = 'login';
  const loginConf = 'mailConf';
  const passWconfName = 'passWConf';

  // const toggleAddress = () => {
  //   setShowAddress(!showAddress);
  // };

  const getValue = ({ target: { value, name } }) => {
    setFormVals({ ...formVals, [name]: value });
  };

  const { login, mailConf, passW, passWConf, firstname, lastname, telephone } =
    formVals;

  const handleForm = (e) => {
    e.preventDefault();
    if (mailConf === '') {
      addToast({
        text: 'Confirme su correo',
        error: true
      });
    } else if (passW === '') {
      addToast({
        text: LOCAL_MESSAGES.EMPTY_PASS[lang],
        error: true
      });
    } else if (passW.length < MIN_LENGTH_PASSWORD) {
      addToast({
        text: LOCAL_MESSAGES.SHORT_PASS[lang],
        error: true
      });
    } else if (passW !== passWConf) {
      addToast({
        text: LOCAL_MESSAGES.PASSW_INVALID[lang],
        error: true
      });
    } else if (passWConf !== '' && passW === passWConf && mailConf === login) {
      setFormVals({
        login: '',
        mailConf: '',
        passW: '',
        passWConf: ''
      });
      startSignup({
        ...formVals,
        password: passW,
        email: login
      });
    }
  };
  const showPassW = () => {
    setShowPass(!showPass);
  };

  const validUsername = new RegExp(/@/).test(login);
  const testTel = new RegExp(/^\d{10}$/).test(telephone);
  return (
    <Card className="animated fadeInPlace mt-5">
      <CardHeader className="font-weight-bold text-center">
        {LABELS_FORM_LOGIN.CREATE_ACCOUNT[lang]}
      </CardHeader>
      <div className="signup_card_body pb-2">
        <CardBody>
          <Form onSubmit={handleForm}>
            {isFetching ? (
              message || error ? (
                <Row className="justify-content-center">
                  <Col>
                    <AnimFailSucc error={error} endAnim={cancelSignup} />
                  </Col>
                </Row>
              ) : null
            ) : (
              <>
                <FormGroup>
                  <Label for={loginName}>Correo</Label>
                  <Input
                    onChange={getValue}
                    valid={login.length ? validUsername : null}
                    invalid={login.length ? null : validUsername}
                    className=""
                    required
                    placeholder="someone@web.com"
                    autoComplete="username"
                    autocapitalize="none"
                    value={login}
                    type="email"
                    name={loginName}
                    id={loginName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Confirmar Correo</Label>
                  <Input
                    onChange={getValue}
                    valid={mailConf.length ? mailConf === login : null}
                    invalid={mailConf.length ? mailConf !== login : null}
                    className=""
                    required
                    placeholder="someone@web.com"
                    autoComplete="username"
                    autocapitalize="none"
                    value={mailConf}
                    type="email"
                    name={loginConf}
                    id={loginConf}
                  />
                  <FormFeedback valid>
                    La confirmación del correo coincide
                  </FormFeedback>
                  <FormFeedback>
                    {LOCAL_MESSAGES.PASSW_INVALID[lang]}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="telephone">Teléfono</Label>
                  <Input
                    onChange={getValue}
                    className=""
                    required
                    valid={telephone !== '' ? testTel : null}
                    invalid={telephone !== '' ? null : testTel}
                    pattern={'[0-9]{10}'}
                    autoComplete="telephone"
                    maxLength={10}
                    value={telephone}
                    type="text"
                    name="telephone"
                    id="telephone"
                  />
                  <FormText>(10 números)</FormText>
                </FormGroup>

                <FormGroup>
                  <Label>{LABELS_FORM_LOGIN.PASS_EIGHT_CH[lang]}</Label>
                  <InputGroup>
                    <Input
                      onChange={getValue}
                      className=""
                      required
                      minLength="6"
                      autoComplete="new-password"
                      value={passW}
                      type={showPass ? 'text' : 'password'}
                      name={passWname}
                      id={passWname}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={showPassW}
                        color="info"
                        active={showPass}
                      >
                        <FontAwesomeIcon
                          icon={showPass ? 'eye' : 'eye-slash'}
                        />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormText>{LABELS_FORM_LOGIN.PASS_RESTR[lang]}</FormText>
                </FormGroup>
                <FormGroup>
                  <Label>{LABELS_FORM_LOGIN.CONFIRM_PASS[lang]}</Label>
                  <Input
                    onChange={getValue}
                    className=""
                    required
                    valid={passWConf.length ? passWConf === passW : null}
                    invalid={passWConf.length ? passWConf !== passW : null}
                    autoComplete="new-password"
                    value={passWConf}
                    type={showPass ? 'text' : 'password'}
                    name={passWconfName}
                    id={passWconfName}
                  />
                  <FormFeedback valid>
                    {LOCAL_MESSAGES.PASSW_VALID[lang]}
                  </FormFeedback>
                  <FormFeedback>
                    {LOCAL_MESSAGES.PASSW_INVALID[lang]}
                  </FormFeedback>
                </FormGroup>
                {/* <FormGroup onClick={toggleAddress}>
                  Añadir dirección&nbsp;
                  {showAddress ? (
                    <FontAwesomeIcon
                      className="animated fadeInUp"
                      icon="minus-circle"
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="animated fadeInUp"
                      icon="plus-circle"
                    />
                  )}
                </FormGroup> */}

                {/* <Collapse isOpen={showAddress}>
                  <AddressForm
                    inline
                    lang={lang}
                    formExtVals={formVals}
                    getExtValue={getValue}
                  />
                </Collapse> */}

                <FormGroup>
                  <Button color="primary" block type="submit">
                    {LABELS_FORM_LOGIN.REGISTER[lang]}
                  </Button>
                </FormGroup>
              </>
            )}
          </Form>
        </CardBody>
      </div>

      <CardFooter className="text-center">
        <b>{LABELS_FORM_LOGIN.HAS_ACCOUNT[lang]}</b>
        <Button onClick={setLogin} className="d-inline" color="link">
          {LABELS_FORM_LOGIN.LOGIN[lang]}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default SignupForm;
