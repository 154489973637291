export const START_CHECKOUT = 'START_CHECKOUT';
export const REQUEST_CHECKOUT = 'REQUEST_CHECKOUT';
export const CANCEL_CHECKOUT = 'CANCEL_CHECKOUT';
export const SUCCESS_CHECKOUT = 'SUCCESS_CHECKOUT';
export const ERROR_CHECKOUT = 'ERROR_CHECKOUT';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const SET_ORDER_CHECKOUT = 'SET_ORDER_CHECKOUT';
export const START_PAY = 'START_PAY';
export const SET_CHECKOUT_SESSION = 'SET_CHECKOUT_SESSION';

export const start_checkout = (dataCheckout) => ({
  type: START_CHECKOUT,
  dataCheckout
});

export const start_pay = (orderId, coupon) => ({
  type: START_PAY,
  orderId,
  coupon
});

export const set_order_checkout = (createdOrder) => ({
  type: SET_ORDER_CHECKOUT,
  createdOrder
});

export const set_checkout_session = (checkoutSession) => ({
  type: SET_CHECKOUT_SESSION,
  checkoutSession
});

export const request_checkout = () => ({
  type: REQUEST_CHECKOUT,
  isFetching: true
});

export const cancel_checkout = () => ({
  type: REQUEST_CHECKOUT,
  isFetching: false
});

export const success_checkout = (message) => ({
  type: SUCCESS_CHECKOUT,
  message
});

export const error_checkout = (error) => ({
  type: ERROR_CHECKOUT,
  error
});

export const reset_checkout = () => ({
  type: RESET_CHECKOUT,
  error: false,
  message: false
});
