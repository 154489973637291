import { connect } from 'react-redux';
import {
  start_signup,
  set_stop_fetch_on_end
} from '../../redux/actions/signup-actions';
import { add_toast } from '../../redux/actions/toasts-actions';
import { UserAccount } from '../../components/forms';

function UserAccountCont({
  add_toast,
  start_signup,
  set_stop_fetch_on_end,
  ...rest
}) {
  return (
    <UserAccount
      addToast={add_toast}
      setStopFetchOnEnd={set_stop_fetch_on_end}
      startSignup={start_signup}
      {...rest}
    />
  );
}

const mapStateToProps = ({ signupReducer, globalReducer: { lang } }) => ({
  ...signupReducer,
  lang
});

const mapActionsToProps = {
  start_signup,
  add_toast,
  set_stop_fetch_on_end
};

export default connect(mapStateToProps, mapActionsToProps)(UserAccountCont);
