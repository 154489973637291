import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/order-actions';
import { add_toast } from '../actions/toasts-actions';
import { getStateLang } from './utils';
import { api_private, api_public } from '../../services';

function* getAllOrders() {
  yield put(actions.reset_order());
  yield put(actions.request_order());
  const lang = yield getStateLang();
  try {
    const orders = yield call(api_private.getAllOrders);
    if (orders.error) {
      yield put(actions.error_order(orders.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: orders.error[lang]
        })
      );
      yield put(actions.set_all_orders(orders.orders));
      yield put(actions.cancel_order());
    } else {
      yield put(actions.success_order(orders.message[lang]));
      yield put(actions.set_all_orders(orders.orders));
      yield put(actions.cancel_order());
    }
  } catch (error) {
    yield put(actions.error_order(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_order());
  }
}

function* getOneOrderCheckout({ orderId }) {
  yield put(actions.reset_order());
  yield put(actions.request_one_order());
  const lang = yield getStateLang();
  try {
    const resp = yield call(api_public.getOneOrder, orderId);
    if (resp.order) {
      yield put(actions.success_order(resp.message[lang]));
      yield put(actions.set_order_checkout(resp.order));
      yield put(
        add_toast({
          error: false,
          text: resp.message[lang]
        })
      );
    } else {
      yield put(actions.error_order(resp.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: resp.error[lang]
        })
      );
    }
  } catch (error) {
    yield put(actions.error_order(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
  } finally {
    yield put(actions.cancel_one_order());
  }
}

function* putUpdateStatusOrder({ order_id, dataOrder }) {
  yield put(actions.reset_order());
  yield put(actions.request_one_order());
  const lang = yield getStateLang();
  try {
    const updated = yield call(
      api_private.updateStatusOrder,
      order_id,
      dataOrder
    );
    if (updated.order) {
      yield put(
        add_toast({
          error: false,
          text: updated.message[lang]
        })
      );
      yield put(actions.cancel_one_order());
      yield getAllOrders();
    } else {
      yield put(actions.error_order(updated.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: updated.error[lang] || updated.error
        })
      );
      yield put(actions.cancel_one_order());
    }
  } catch (error) {
    yield put(actions.error_order(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_one_order());
  }
}

function* putUpdateOrder({ order_id, dataOrder }) {
  yield put(actions.reset_order());
  yield put(actions.request_one_order());
  const lang = yield getStateLang();
  try {
    const updated = yield call(api_private.updateOrder, order_id, dataOrder);
    if (updated.order) {
      yield put(
        add_toast({
          error: false,
          text: updated.message[lang]
        })
      );
      yield put(actions.cancel_one_order());
      yield getAllOrders();
    } else {
      yield put(actions.error_order(updated.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: updated.error[lang]
        })
      );
      yield put(actions.cancel_one_order());
    }
  } catch (error) {
    yield put(actions.error_order(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_one_order());
  }
}

function* putUpdateProductOrder({ order_id, product_order_id, dataOrder }) {
  yield put(actions.reset_order());
  yield put(actions.request_one_order_product());
  yield put(actions.set_fetching_product_id(product_order_id));
  const lang = yield getStateLang();
  try {
    const update = yield call(
      api_private.updateOrderProduct,
      order_id,
      product_order_id,
      dataOrder
    );
    if (update.error) {
      yield put(actions.error_order(update.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: update.error[lang] || update.error
        })
      );
    } else {
      yield put(actions.success_order(update.message[lang]));
      yield put(
        add_toast({
          error: false,
          text: update.message[lang]
        })
      );
      yield put(actions.cancel_one_order_product());
      yield getAllOrders();
    }
  } catch (error) {
    yield put(actions.error_order(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_one_order());
  }
}

export function* watchOrder() {
  yield takeLatest(actions.LIST_ORDERS, getAllOrders);
  yield takeLatest(actions.START_ADD_STATUS_ORDER, putUpdateStatusOrder);
  yield takeLatest(actions.START_UPDATE_ORDER, putUpdateOrder);
  yield takeLatest(actions.ADD_STATUS_ORDER_PRODUCT, putUpdateProductOrder);
  yield takeLatest(actions.START_ONE_ORDER_CHECKOUT, getOneOrderCheckout);
}
