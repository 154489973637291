import React, { useEffect } from "react";
import { connect } from "react-redux";
import { renew_token } from "../../redux/actions/login-actions";
// import { PLATFORM_ROUTES } from "../../constants/platform";

function CheckLoginCont({ user, token, renew_token }) {
  useEffect(() => {
    if (token && !user) {
      renew_token();
    } 
  }, [renew_token,token,user]);
  return <div />;
}

const mapStateToProps = ({ loginReducer }) => ({
  ...loginReducer
});

const mapActionsToProps = {
  renew_token
};

export default connect(mapStateToProps, mapActionsToProps)(CheckLoginCont);
