import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  FormText
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LABELS_FORM_LOGIN } from '../../constants/platform';

function RegisterUserForm({
  isFetching,
  cancelSignup,
  registerUser,
  addToast,
  error,
  message,
  setLogin,
  lang
}) {
  const [formVals, setFormVals] = useState({
    firstname: '',
    lastname: '',
    telephone: '',
    login: ''
  });
  const loginName = 'login';
  const getValue = ({ target: { value, name } }) => {
    setFormVals({ ...formVals, [name]: value });
  };
  const { login, firstname, lastname, telephone } = formVals;
  const handleForm = (e) => {
    e.preventDefault();
    registerUser({
      ...formVals,
      password: 'passW',
      email: login
    });
    setFormVals({
      firstname: '',
      lastname: '',
      telephone: '',
      login: ''
    });
  };

  const validUsername = new RegExp(/@/).test(login);
  const testTel = new RegExp(/^\d{10}$/).test(telephone);
  return (
    <Card className="animated fadeInPlace mt-5">
      <CardHeader className="font-weight-bold text-center">
        {LABELS_FORM_LOGIN.CREATE_ACCOUNT[lang]}
      </CardHeader>
      <CardBody>
        {isFetching ? (
          <FontAwesomeIcon spin icon="cog" />
        ) : (
          <Form onSubmit={handleForm}>
            <FormGroup>
              <Label for="firstname">Nombre Completo</Label>
              <Row>
                <Col>
                  <Input
                    onChange={getValue}
                    className=""
                    required
                    placeholder="Juan"
                    autoComplete="firstname"
                    value={firstname}
                    type="text"
                    name="firstname"
                    id="firstname"
                  />
                </Col>
                <Col>
                  <Input
                    onChange={getValue}
                    className=""
                    required
                    placeholder="Gómez"
                    autoComplete="lastname"
                    value={lastname}
                    type="text"
                    name="lastname"
                    id="lastname"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label for={loginName}>Correo</Label>
              <Input
                onChange={getValue}
                valid={login.length ? validUsername : null}
                invalid={login.length ? null : validUsername}
                className=""
                required
                placeholder="someone@web.com"
                autoComplete="username"
                value={login}
                type="text"
                name={loginName}
                id={loginName}
              />
            </FormGroup>
            <FormGroup>
              <Label for="telephone">Teléfono</Label>
              <Input
                onChange={getValue}
                className=""
                required
                valid={telephone !== '' ? testTel : null}
                invalid={telephone !== '' ? null : testTel}
                pattern={'[0-9]{10}'}
                autoComplete="telephone"
                maxLength={10}
                value={telephone}
                type="text"
                name="telephone"
                id="telephone"
              />
              <FormText>(10 números)</FormText>
            </FormGroup>

            <FormGroup>
              <Button color="primary" block type="submit">
                {LABELS_FORM_LOGIN.REGISTER[lang]}
              </Button>
            </FormGroup>
          </Form>
        )}
      </CardBody>
    </Card>
  );
}

export default RegisterUserForm;
