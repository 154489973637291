import React from 'react';
import { connect } from 'react-redux';
import { ContactForm } from '../../components/forms';
import { start_post_contact } from '../../redux/actions/contact-actions';

function ContactFormCont({ start_post_contact, ...rest }) {
  return <ContactForm startSend={start_post_contact} {...rest} />;
}

const mapStateToProps = ({ globalReducer: { lang }, contactReducer }) => ({
  lang,
  ...contactReducer
});

const mapActionsToProps = {
  start_post_contact
};

export default connect(mapStateToProps, mapActionsToProps)(ContactFormCont);
