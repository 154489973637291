import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { OrdersTable } from '../../components/platform/tables';
import {
  list_orders,
  start_update_order,
  start_add_status_order,
  set_select_order
} from '../../redux/actions/order-actions';
import { start_add_address_user } from '../../redux/actions/user-actions';
import { set_slide_content } from '../../redux/actions/platform-actions';
import { start_get_usage_user } from '../../redux/actions/user-actions';
import { set_proceed_to_verify } from '../../redux/actions/signup-actions';
import { USER_ROLES } from '../../constants/platform';

function OrdersTableCont({
  set_select_order,
  set_slide_content,
  set_proceed_to_verify,
  start_update_order,
  start_add_address_user,
  start_add_status_order,
  list_orders,
  start_get_usage_user,
  user,
  user_role,
  allOrders,
  error,
  ...rest
}) {
  const { addresses = [], _id } = (typeof user !== 'undefined' && user) || {};
  useEffect(() => {
    if (!error && allOrders === null && user) {
      if (user.role === 3) {
        start_get_usage_user(user._id);
      } else {
        list_orders();
      }
    }
  }, [
    allOrders,
    error,
    list_orders,
    start_get_usage_user,
    user?._id,
    user?.role,
    user
  ]);

  let flatAllOrders = allOrders
    ? allOrders.map((el) => ({
        ...el,
        full_name: `${el?.user?.firstname} ${el?.user?.lastname}`,
        email: el?.user?.email
      }))
    : [];
  if (user_role === USER_ROLES.MANAGER) {
    flatAllOrders = flatAllOrders.filter((el) => el.collect_code);
  }
  const hasAddress = addresses.length > 0;
  return (
    <OrdersTable
      set_select_order={set_select_order}
      hasAddress={hasAddress}
      startAddAddress={start_add_address_user}
      set_proceed_to_verify={set_proceed_to_verify}
      setSlideContent={set_slide_content}
      startUpdateOrder={start_update_order}
      addStatusOrder={start_add_status_order}
      canEdit={user_role === USER_ROLES.ADMIN}
      canUpdate={
        user_role === USER_ROLES.ADMIN || user_role === USER_ROLES.MANAGER
      }
      restrictedGifts={user_role === USER_ROLES.MANAGER}
      // esta es la variable que tiene directamente el número de estatus posibles para este
      // tipo de usuario 👇
      restrictedEditStatus={user_role === USER_ROLES.MANAGER ? 1 : null}
      allOrders={flatAllOrders}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  signupReducer,
  userReducer,
  globalReducer: { lang },
  loginReducer: { user },
  orderReducer
}) => ({
  lang,
  user,
  ...userReducer,
  ...signupReducer,
  ...orderReducer
});

const mapActionsToProps = {
  set_select_order,
  start_add_address_user,
  set_proceed_to_verify,
  list_orders,
  start_get_usage_user,
  start_update_order,
  start_add_status_order,
  set_slide_content
};
export default connect(mapStateToProps, mapActionsToProps)(OrdersTableCont);
