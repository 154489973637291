import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { VerifyPayment } from '../../components/routes';
import { start_add_status_order } from '../../redux/actions/order-actions';
import { useQuery } from '../../hooks';

function VerifyPaymentCont({ start_add_status_order, ...rest }) {
  const location = useLocation();
  const params = useQuery(location.search);
  return (
    <VerifyPayment success={params.get('success') ? true : false} {...rest} />
  );
}

const mapStateToProps = ({ orderReducer, globalReducer: { lang } }) => ({
  ...orderReducer,
  lang
});

const mapActionsToProps = { start_add_status_order };

export default connect(mapStateToProps, mapActionsToProps)(VerifyPaymentCont);
