import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Verify } from '../../components/forms';
import { PLATFORM_ROUTES, PLATF_REDIR_TIMEOUT } from '../../constants/platform';
import { start_verify } from '../../redux/actions/signup-actions';

function VerifyCont({ start_verify, user, ...rest }) {
  const { userId, verificationCode } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (!user && userId && verificationCode) {
      start_verify({
        userId,
        verificationCode
      });
    }
  }, [start_verify, userId, verificationCode, user]);
  useEffect(() => {
    if (user && user._id) {
      setTimeout(() => {
        history.push(`/${PLATFORM_ROUTES.PLATFORM}`);
      }, PLATF_REDIR_TIMEOUT);
    }
  }, [user, history]);
  return <Verify {...rest} />;
}

const mapStateToProps = ({ signupReducer, loginReducer: { user } }) => ({
  ...signupReducer,
  user
});

const mapActionsToProps = {
  start_verify
};

export default connect(mapStateToProps, mapActionsToProps)(VerifyCont);
