import React from 'react';
import { Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CartNav({ productTotalItems, showCart, className }) {
  return (
    <Button
      onClick={showCart}
      color="transparent"
      className={`hvr-underline-from-left text-dark ${className}`}
    >
      <FontAwesomeIcon icon="shopping-cart" />
      &nbsp;<Badge color="info">{productTotalItems}</Badge>
    </Button>
  );
}

export default CartNav;
