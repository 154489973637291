export const AUTHOR = 'N12 Estudio <connection@n12.mx>';
export const DEFAULT_DESCRIPTION = 'Tienda NeoCarpets';
export const URL = 'https://www.neocarpets.com';
export const LOCATION = '';
export const DEFAULT_TITLE = 'Neocarpets';
export const SOCIAL = {
  facebook: 'https://www.facebook.com/n12estudio',
  twitter: 'https://twitter.com/N12Estudio',
  linkedin: 'https://www.linkedin.com/company/n12-estudio/',
  instagram: 'https://www.instagram.com/n12estudio/'
};

export const LOCAL_MESSAGES = {
  DUPLICATED_ADD_VALUE: 'Sólo puedes agregar una vez el valor',
  NOT_EMPTY: 'No puede dejar el campo vacío',
  ARRAY_EMPTY: 'Seleccione al menos un valor',
  FORMAT_MISMATCH: 'El valor no corresponde con el formato necesario',
  LANGUAGE_SET: {
    es: 'Se ha cambiado el idioma a español',
    en: 'English has been set as language'
  },
  PASSW_VALID: {
    es: 'Las confirmación de contraseña coincide',
    en: 'Confirmation match'
  },
  PASSW_INVALID: {
    es: 'No coincide la confirmación',
    en: "Confirmation don't match"
  },
  EMPTY_PASS: {
    es: 'Proporcione una contraseña',
    en: 'Provide a password'
  },
  SHORT_PASS: {
    es: 'La contraseña debe tener al menos 8 caracteres',
    en: 'Password should be at least 8 characters long'
  },
  INSUFICIENT_CREDIT: {
    es: 'No hay suficientes puntos para la transacción',
    en: 'Not enough points for transaction'
  },
  INSUFICIENT_STOCK: {
    es: 'No hay suficiente productos en existencia',
    en: 'Not enough products for transaction'
  },
  ADDED_TO_CART: {
    es: 'Añadido al carrito',
    en: 'Added to cart'
  }
};

export const LABELS_NAVBAR = {
  LOGIN: {
    es: 'Ingresar',
    en: 'Log in'
  },
  PRICING: {
    es: 'Precio',
    en: 'Pricing'
  },
  TO_MAIN: {
    es: 'NeoCarpets',
    en: 'NeoCarpets'
  }
};

export const LABELS_COMPANY = {
  SINCE: {
    es: 'Fundada en',
    en: 'Since'
  },
  INDUSTRY: {
    es: 'Industria',
    en: 'Industry'
  }
};

export const LABELS_FILTER = {
  SEARCH: {
    es: 'Buscar',
    en: 'Search'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar...',
    en: 'Search...'
  },
  CATHEGORIES: {
    es: 'Filtros',
    en: 'Filters'
  },
  CLEAN: {
    es: 'Limpiar',
    en: 'Clean'
  }
};

export const TOAST_TITLE = 'NeoCarpets';

export const DELAY_TEXT = 3000;
export const ANIM_TRANS = 3000;

export const PROMO_SHOW = process.env.NODE_ENV === 'development' ? 1500 : 30000;

export const DEBOUNCE_TIME = {
  DEFAULT: 500,
  SEARCH: 1500
};

export const PAGINATION_ITEMS = 20;
export const PAGINATION_LINKS = 8;

export const THROTTLE_TIME = 500;

export const DELAY_ANIM = 0.4;

export const CARDS_PER_ROW_ROAST = 4;
export const CARDS_PER_ROW_ROAST_MEDIUM = 3;

export const MIN_LENGTH_PASSWORD = 6;

export const CODE_LENGTH_VERIFY = 6;

// export const ARRAY_ROAST = [1,2,3]
// export const ARRAY_ROAST_MEDIUM = [1,2,3,4]

export const SMALL_BREAKPOINT = 1201;

export const CHANGE_FEATURES = 8000;
export const CHANGE_USAGE = 3000;

export const TOAST_TIMEOUT = {
  main: 6000,
  pad: 500
};

export const GET_CONTACT_LABEL = {
  en: 'Save',
  es: 'Guardar'
};

export const COUNTRY_CODES = {
  MX: '52'
};

export const STATES_DICTIONARY = [
  { value: 'AG', label: 'AGUASCALIENTES' },
  { value: 'BC', label: 'BAJA CALIFORNIA' },
  { value: 'BS', label: 'BAJA CALIFORNIA SUR' },
  { value: 'CDMX', label: 'CIUDAD DE MEXICO' },
  { value: 'CM', label: 'CAMPECHE' },
  { value: 'CS', label: 'CHIAPAS' },
  { value: 'CH', label: 'CHIHUAHUA' },
  { value: 'CO', label: 'COAHUILA' },
  { value: 'CL', label: 'COLIMA' },
  { value: 'DG', label: 'DURANGO' },
  { value: 'ME', label: 'ESTADO DE MEXICO' },
  { value: 'GT', label: 'GUANAJUATO' },
  { value: 'GR', label: 'GUERRERO' },
  { value: 'HG', label: 'HIDALGO' },
  { value: 'JA', label: 'JALISCO' },
  { value: 'MI', label: 'MICHOACAN' },
  { value: 'MO', label: 'MORELOS' },
  { value: 'NA', label: 'NAYARIT' },
  { value: 'NL', label: 'NUEVO LEON' },
  { value: 'OA', label: 'OAXACA' },
  { value: 'PB', label: 'PUEBLA' },
  { value: 'QE', label: 'QUERETARO' },
  { value: 'QR', label: 'QUINTANA ROO' },
  { value: 'SL', label: 'SAN LUIS POTOSI' },
  { value: 'SI', label: 'SINALOA' },
  { value: 'SO', label: 'SONORA' },
  { value: 'TB', label: 'TABASCO' },
  { value: 'TL', label: 'TLAXCALA' },
  { value: 'TM', label: 'TAMAULIPAS' },
  { value: 'VE', label: 'VERACRUZ' },
  { value: 'YU', label: 'YUCATAN' },
  { value: 'ZA', label: 'ZACATECAS' }
];

export const GLOBAL_LANGUAGES = {
  en: { lang: 'en', langName: 'English', langKey: 'EN' },
  es: { lang: 'es', langName: 'Español', langKey: 'ES' }
};

export const FILTERS = {
  company: { icon: 'satellite', info: 'Company', urlQry: 'search_company' },
  tag: { icon: 'tags', info: 'Tags', urlQry: 'tags' },
  user: { icon: 'user-astronaut', info: 'User', urlQry: 'handle' }
};

export const PRODUCT_KIND = [
  {
    label: {
      es: 'Producto Promocional',
      en: ''
    },
    value: 'product'
  },
  {
    label: {
      es: 'Alimento de regalo',
      en: ''
    },
    value: 'promotional_bag'
  },
  {
    label: {
      es: 'Tarjeta de regalo',
      en: ''
    },
    value: 'productcard'
  },
  {
    label: {
      es: 'Ropa',
      en: ''
    },
    value: 'clothing'
  }
];
