import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { InlineVerify } from '../../components/forms';
import { start_verify } from '../../redux/actions/signup-actions';
import { set_slide_content } from '../../redux/actions/platform-actions';

import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';

function InlineVerifyCont({
  set_slide_content,
  start_verify,
  proceedToCheckout,
  ...rest
}) {
  const location = useLocation();
  useEffect(() => {
    if (proceedToCheckout) {
      if (location.pathname === '/platform/orders') {
        set_slide_content('');
      } else {
        set_slide_content(SLIDE_MENU_NAMES_CONTENT.CHECKOUT);
      }
    }
  }, [proceedToCheckout, set_slide_content, location]);
  const setCheckout = () => {
    set_slide_content(SLIDE_MENU_NAMES_CONTENT.CHECKOUT);
  };
  return (
    <InlineVerify
      location={location}
      setCheckout={setCheckout}
      startVerify={start_verify}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  loginReducer,
  orderReducer,
  globalReducer: { lang },
  signupReducer: { proceedToCheckout }
}) => ({
  ...orderReducer,
  ...loginReducer,
  lang,
  proceedToCheckout
});

const mapActionsToProps = {
  start_verify,
  set_slide_content
};

export default connect(mapStateToProps, mapActionsToProps)(InlineVerifyCont);
