import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Alert, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddressForm } from './';
import { RadioListItems } from './questTypes';
import { LABELS_CHECKOUT } from '../../constants/platform';
import '../../style/checkout.css';

const initialFormAddress = {
  city: '',
  line_1: '',
  line_2: '',
  postal_code: '',
  state: ''
};

function ShippingAddress({
  set_slide_content,
  selectOrder,
  startUpdateOrder,
  className,
  lang,
  startAddAddress,
  isFetchingAddress,
  user: { addresses = [], _id }
}) {
  console.log(addresses);
  const [selAddress, setSelAddress] = useState(null);
  const [formVals, setFormVals] = useState(initialFormAddress);
  useEffect(() => {
    if (addresses.length && selAddress === null) {
      setSelAddress(addresses[0]._id);
    }
  }, [addresses, selAddress]);

  const getValue = ({ target: { value, name } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  const handleSetAddress = ({ target: { value } }) => {
    setSelAddress(value);
  };

  const onSubmitAddress = (e) => {
    e.preventDefault();
    startAddAddress(_id, {
      ...formVals
    });
  };
  const reloadPage = () => {
    window.location.reload(false);
  };
  const addressOptions = addresses.map(
    ({ _id, line_1, state, city, postal_code }) => ({
      label: `${line_1}, ${state}, ${city}, ${postal_code}`,
      name: 'selAddress',
      value: _id
    })
  );
  const hasAddress = addresses.length > 0;
  const handleConfirm = () => {
    startUpdateOrder(selectOrder._id, { address: addresses[0]._id });
    set_slide_content('');
    reloadPage();
  };
  return (
    <Container className={`${className} animated fadeInRight mt-3`}>
      {hasAddress ? (
        <>
          <Row>
            <Col>
              <Alert color="primary">{LABELS_CHECKOUT.USE_ADDRESS[lang]}</Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{LABELS_CHECKOUT.SELECT_ADDRESS[lang]}</p>
            </Col>
          </Row>
          <Form>
            <RadioListItems
              selValue={selAddress}
              getValue={handleSetAddress}
              radioOptions={addressOptions}
            />
          </Form>

          <Row className="mt-5">
            <Col>
              <Button color="primary" block onClick={handleConfirm}>
                {LABELS_CHECKOUT.COMFIRM[lang]}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <Alert color="warning">
                {LABELS_CHECKOUT.NO_ADDRESSES[lang]}
              </Alert>
            </Col>
          </Row>
        </>
      )}
      {selAddress ? null : (
        <Row className="justify-content-start">
          {isFetchingAddress ? (
            <Col className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon spin icon="cog" />
            </Col>
          ) : (
            <Col>
              <AddressForm
                inline
                lang={lang}
                formExtVals={formVals}
                onSubmit={onSubmitAddress}
                getExtValue={getValue}
                id="address_form"
                className="py-2"
              />
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
}

export default ShippingAddress;
