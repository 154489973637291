import React from "react"

import wh from "../../assets/img/wh.png"
function WhastappIcon() {
  return (
    <div
      className="whatsapp_icon"
      style={{
        position: "fixed",
        right: "42px",
        bottom: "80px",
        zIndex: "1200",
      }}
    >
      <a
        className="text-light"
        target="_blank"
        rel="noopener noreferrer"
        href="https://api.whatsapp.com/send?phone=522227651045&text=%C2%A1Hola%21"
      >
        <img src={wh} alt="whatsapp" />
        {/* <FontAwesomeIcon
          style={{ width: "60px" }}
          size="4x"
          icon={["fab", "whatsapp"]}
        /> */}
      </a>
    </div>
  )
}

export default WhastappIcon