import React from 'react';
import { Footer } from '../../components/sections';
import { Decking } from '../../components/landing/sections';

function DeckingR() {
  return (
    <>
      <Decking />
      <Footer />
    </>
  );
}

export default DeckingR;
