import { SET_LANG, SET_CONTACT_FORM } from "../actions/global-actions.js";

export const globalReducer = (
  state = {
    lang: "es",
    contact_form: ""
  },
  action
) => {
  const { type, lang, contact_form } = action;
  switch (type) {
    case SET_LANG:
      return { ...state, lang };
    case SET_CONTACT_FORM:
      return { ...state, contact_form };

    default:
      return { ...state };
  }
};
