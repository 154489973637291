import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
  FormText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "date-input-polyfill-react";

export default class InputDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limD: null
    };
  }
  componentDidMount = () => {
    const {
      charact: { startToday, name }
    } = this.props;
    if (startToday) {
      const today = new Date();
      const numD = today.getDate();
      const today_format = `${today.getFullYear()}-${
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : `${today.getMonth() + 1}`
      }-${numD > 9 ? numD : `0${numD}`}`;
      this.setState({
        limD: today_format
      });
      this.props.getValue({ target: { name, value: today_format } });
    }
  };
  render() {
    const {
      charact: {
        required,
        sizeSM,
        forL,
        id,
        label,
        icon,
        pattern,
        name,
        longQ,
        autoFocus
      },
      value
    } = this.props;
    const { limD } = this.state;
    return (
      <FormGroup row>
        <Label sm={sizeSM[0]} size="lg" for={forL} id={id}>
          <FontAwesomeIcon icon={`${icon}`} />
          &nbsp;
          {label}
        </Label>
        <Col xs={12} sm={sizeSM[1]}>
          <Input
            onChange={this.props.getValue}
            className={"custom"}
            value={value}
            pattern={pattern ? pattern : null}
            id={id}
            type={"date"}
            date-format="mm/dd/yyyy"
            autoFocus={autoFocus ? true : false}
            required={required}
            min={limD}
            name={name}
          />
          <FormFeedback className="animated fadeIn" valid>
            Súper
          </FormFeedback>
          <FormFeedback>Ingresa 10 dígitos</FormFeedback>
          <FormText>{longQ}</FormText>
        </Col>
      </FormGroup>
    );
  }
}
