import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import '../../style/carousel.css';

import crea from '../../images/landing_products/creatuespacio.png';
import bgmobile from '../../images/landing_products/bgmobile.png';

function Create() {
  return (
    <Container
      className="d-flex flex-column bg_white justify-content-center align-items-center py-5"
      fluid
    >
      <Row className="mt-md-2">
        <Col className="d-flex flex-column text-center">
          <h1 className="text_dark_pink font-weight-bold">Diseña tu espacio</h1>
          <h6 className=" ">Encuentra tu producto</h6>
          <img
            src={crea}
            alt=""
            className="d-md-flex d-none my-md-4 img-fluid mw-80"
          />
          <img
            src={bgmobile}
            alt=""
            className="d-md-none d-flex my-4 img-fluid"
          />
        </Col>
      </Row>

      <Row className="d-flex  mb-md-4">
        <Link to="/products" className=" text-decoration-none">
          {' '}
          <Button color="primary" className=" rounded-pill px-5 py-3 d-flex flex-center align-items-center">
            <h6 className="mb-0"> Ver productos</h6>
          </Button>
        </Link>
      </Row>
    </Container>
  );
}

export default Create;
