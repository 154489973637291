import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/product-actions';
import { add_toast } from '../actions/toasts-actions';
import { getStateLang, getLoginUser } from './utils';
import { api_private, api_public } from '../../services';

function* postDataProduct({ dataProduct, images }) {
  yield put(actions.reset_product());
  yield put(actions.request_one_product());
  const user = yield getLoginUser();
  const lang = yield getStateLang();
  try {
    if (images) {
      const upload = yield call(api_private.uploadImages, images, user._id);
      if (upload.image_urls && upload.image_urls.length) {
        let dataImg = {
          ...dataProduct,
          images: [upload.image_urls[0]]
        };
        const created = yield call(api_private.createProduct, dataImg);
        if (created.product) {
          yield put(actions.success_product(created.message[lang]));
          yield put(
            add_toast({
              error: false,
              text: created.message[lang]
            })
          );
          yield getAllProducts({ product_URL: '?all_products=true' });
        }
      }
    } else {
      const created = yield call(api_private.createProduct, dataProduct);
      if (created.product) {
        yield put(
          add_toast({
            error: false,
            text: created.message[lang]
          })
        );
        yield getAllProducts();
      } else if (created.error) {
        yield put(actions.error_product(created.error));
        yield put(
          add_toast({
            error: true,
            text: created.error[lang]
          })
        );
        yield put(actions.cancel_one_product());
      }
    }
  } catch (error) {
    yield put(actions.error_product(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_one_product());
  }
}

function* putUpdateProduct({ product_id, dataProduct }) {
  yield put(actions.reset_product());
  yield put(actions.request_one_product());
  const lang = yield getStateLang();
  try {
    // en esta variable se almacena el resultado de lo que entregue la siguiente función
    const updated_product = yield call(
      api_private.updateProduct,
      product_id,
      dataProduct
    );
    if (updated_product.error) {
      yield put(actions.error_product(updated_product.error));
      yield put(
        add_toast({
          error: true,
          text: updated_product.error[lang]
        })
      );
      yield put(actions.cancel_one_product());
    } else {
      yield put(actions.success_product(updated_product.message[lang]));
      yield put(actions.cancel_one_product());
      yield getAllProducts({ product_URL: '?all_products=true' });
    }
  } catch (error) {
    yield put(actions.error_product(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_one_product());
  }
}

export function* getAllProducts(props) {
  yield put(actions.reset_product());
  yield put(actions.request_product());
  const lang = yield getStateLang();
  try {
    const products = yield call(
      api_public.getAllProducts,
      props?.product_URL || ''
    );
    if (products.error) {
      yield put(actions.error_product(products.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: products.error[lang]
        })
      );
      yield put(actions.cancel_product());
    } else {
      yield put(actions.success_product(products.message[lang]));
      yield put(actions.set_all_products(products.products));
      yield put(actions.cancel_product());
    }
  } catch (error) {
    yield put(actions.error_product(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_product());
  }
}

function* getSearchProducts({ searchURL }) {
  yield put(actions.reset_product());
  yield put(actions.request_product());
  const lang = yield getStateLang();
  try {
    const products = yield call(api_public.getSearchProduct, searchURL);
    if (products.error) {
      yield put(actions.error_product(products.error[lang]));
      yield put(actions.cancel_product());
    } else {
      // yield put(actions.set_pagination_products(0));
      yield put(actions.success_product(products.message[lang]));
      yield put(actions.set_filtered_products(products.products));
      yield put(actions.cancel_product());
    }
  } catch (error) {
    yield put(actions.error_product(error));
    yield put(actions.cancel_product());
  }
}

export function* watchProduct() {
  // estas acciones vienen de redux
  yield takeLatest(actions.CREATE_PRODUCT, postDataProduct);
  yield takeLatest(actions.LIST_PRODUCTS, getAllProducts);
  yield takeLatest(actions.START_UPDATE_PRODUCT, putUpdateProduct);
  yield takeLatest(actions.START_SEARCH_PRODUCTS, getSearchProducts);
}
