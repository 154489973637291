import React from 'react';
import { Footer } from '..';
import { Calculator } from '../landing';

function CalculatorPage() {
  return (
    <>
      <Calculator />
      <Footer />
    </>
  );
}

export default CalculatorPage;
