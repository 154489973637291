import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function EditableImg({ src, initialCrop, handle, getFiles, nameOfFile }) {
  const [crop, setCrop] = useState({ ...initialCrop });
  const [imgRef, setImgRef] = useState(null);
  // const fileUrl = useRef()
  const handleCrop = (crop) => {
    setCrop(crop);
  };
  const onCropComplete = ({ width, height }) => {
    if (imgRef && width && height) {
      makeClientCrop(crop);
    }
  };
  const onImageLoaded = (image) => {
    setImgRef(image);
  };
  const makeClientCrop = async (crop) => {
    if (crop.width && crop.height) {
      const blobImg = await getCroppedImg(
        // src,
        crop,
        `${nameOfFile}.${src.split(";")[0].split("/")[1]}`
      );
      getFiles({ target: { files: [blobImg] } });
    }
  };
  const getCroppedImg = (crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = imgRef.naturalWidth / imgRef.width;
    const scaleY = imgRef.naturalHeight / imgRef.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imgRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(fileUrl.current);
        // fileUrl.current = window.URL.createObjectURL(blob);
        // resolve(fileUrl.current);
        resolve(blob);
      }, `image/${src.split(";")[0].split("/")[1]}`);
    });
  };
  return (
    <FormGroup>
      {src ? (
        <ReactCrop
          src={src}
          crop={crop} 
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={handleCrop}
        />
      ) : null}
    </FormGroup>
  );
}

export default EditableImg;
