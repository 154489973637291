import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfiguratorR } from '../../components/elements';
import { add_to_cart } from '../../redux/actions/cart-actions';
import {
  list_products,
  set_selected_product
} from '../../redux/actions/product-actions';
import { isCartContent } from '../../services';
import { set_slide_content } from '../../redux/actions/platform-actions';

import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';

function ConfiguratorCont({
  lang,
  add_to_cart,
  list_products,
  error,
  allProducts,
  filterCategories,
  set_slide_content,
  set_selected_product,
  ...rest
}) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!error && allProducts === null) {
      list_products('');
    }
  }, [allProducts, error, list_products]);
  const handleAddToCart = (selProd) => {
    add_to_cart(selProd);
  };

  useEffect(() => {
    const continueShoping = location?.state?.detail;

    if (isCartContent() && !continueShoping) {
      set_slide_content(SLIDE_MENU_NAMES_CONTENT.CART);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredProducts = useMemo(() => {
    if (allProducts === null) {
      return [];
    }
    const categoriesFilterIds = filterCategories.map((cat) => cat._id);
    return allProducts.filter(
      (prod) =>
        prod?.categories.findIndex((cat) =>
          categoriesFilterIds.includes(cat._id)
        ) > -1
    );
  }, [allProducts, filterCategories]);

  return (
    <ConfiguratorR
      addToCart={handleAddToCart}
      allProducts={filteredProducts}
      setSlideContent={set_slide_content}
      setSelectedProduct={set_selected_product}
      lang={lang}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  productReducer,
  globalReducer: { lang },
  categoryReducer: { filterCategories }
}) => ({
  ...productReducer,
  lang,
  filterCategories
});

const mapActionsToProps = {
  add_to_cart,
  list_products,
  set_slide_content,
  set_selected_product
};

export default connect(mapStateToProps, mapActionsToProps)(ConfiguratorCont);
