import React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../images/footer/logoneocarpets.svg';
import n12 from '../../images/footer/n12logo.png';
import smbar from '../../images/footer/smallhero.png';
import bar from '../../images/footer/barra_footer.png';

import '../../style/footer.css';

const footerLinks = [
  {
    to: '/products',
    name: 'Productos'
  },
  {
    to: '/configurator',
    name: 'Diseña tu espacio'
  },
  {
    to: '/services',
    name: 'Servicios'
  }
];


function Footer() {

    // Modal open state
    const [modal, setModal] = React.useState(false);
  
    // Toggle for Modal
    const toggle = () => setModal(!modal);
  
  return (
    <Container className="d-flex bg_white flex-column" fluid>
      <Row className="custom_bar">
        <img src={smbar} alt="small bar" className="vw-100 d-flex d-md-none img-fluid"/>
        <img src={bar} alt="large bar" className="vw-100 d-none d-md-flex img-fluid" />
      </Row>
      <Row className="d-flex justify-content-center align-items-center my-5">
        <Col sm={12} md={2} className="d-flex justify-content-center ">
          <img src={logo} alt="" className="my-md-2 my-0 ml-0 ml-md-5 img-fluid" />
        </Col>
        <Col sm={12} md={5} className="d-flex justify-content-center">
          <Nav className="p-0 d-flex justify-content-center ">
            {footerLinks.map(({ to, name }, i) => (
              <NavItem key={i}>
                <NavLink className={`text-dark`} to={to} href={to}>
                  <h6 className="tablet_text my-2 my-md-0 mx-5 mx-md-0">{name}</h6>
                </NavLink>
              </NavItem>
            ))}
            <NavItem className='d-flex justify-content-center align-items-center'> 
              <h6 onClick={toggle} className="pointer py-md-2 py-0 pt-md-0 pt-2 px-5 px-md-4">
                Política de compra
              </h6>
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Política de compra
                </ModalHeader>
                <ModalBody>
                  <p>
                  Para que su compra sea 100% satisfactoria es importante leer las siguientes políticas de compra:
                  <br/><br/>
                  • Todos nuestros productos se encuentran sujetos a disponibilidad en nuestros almacenes; una vez recibido su orden de compra, un asesor se pondrá en contacto con usted para confirmar la existencia.<br/>
                  • Los tiempos de entrega pueden variar dependiendo el modelo seleccionado, dicha información se encuentra en la imagen de cada producto. tomando en cuenta que nos encontramos en Puebla, para pedidos foráneos el tiempo de entrega puede extenderse dependiendo la ciudad destino.<br/>
                  • El tiempo de entrega empieza a contar un día después de realizar su pago y son considerados como días hábiles.<br/>
                  • Todas las imágenes que se ilustran en nuestro sitio web son fotografías del producto, por lo tanto puede variar el color, textura que se percibe.<br/>
                  • No aceptamos devoluciones o cancelaciones de pedidos una vez recibido su pago.<br/>
                  • Sólo se realizaran cambios de material si recibe un producto diferente al que compro, el producto deberá estar sellado y no presentar daños (cortes, manchas)<br/>
                  • Los precios pueden cambiar sin previo aviso.<br/>
                  </p>
                </ModalBody>
              </Modal>
            </NavItem>
          </Nav>           
        </Col>


        <Col sm={12} md={4} className="d-flex text-center align-items-center justify-content-center">
          <Row className="d-flex text-center align-items-center justify-content-center">
            <Col className="d-flex flex-row justify-content-center align-items-center "sm={6} md={4}> 
              <a
              className=""
              target="_blank"
              rel="noopener noreferrer"
              href=" https://www.facebook.com/neocarpets "
            >
              <FontAwesomeIcon
                size="2x"
                className="socials_f text-dark"
                icon={['fab', 'facebook-f']}
              />
            </a>
            <a
            className=""
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/neocarpets/ "
            >
              <FontAwesomeIcon
                size="2x"
                className="mr-lg-5 mr-md-4 ml-lg-3 ml-md-2 mt-md-0 mt-1 ml-4 socials text-dark"
                icon={['fab', 'instagram']}
              />
            </a>
            </Col>

            <Col className="d-flex align-items-center justify-content-center " sm={12} md={8}>
              <img src={n12} alt="n12 logo" className='n12_logo img-fluid'/>
              <p className="n12-text my-2 my-md-0">Copyright {`${new Date().getFullYear()}`} @N12 Estudio</p>
            </Col>
          </Row>
        </Col>

      </Row>
    </Container>
  );
}

export default Footer;
