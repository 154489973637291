import React from "react";
import { Row, Col } from "reactstrap";
import { LABELS_PLATFORM } from "../../../constants/platform";
import logo from "../../../assets/img/logo/adm_icon_white.svg";

function UserPoints({ userBalance, className, lang }) {
  return (
    <Row className={`${className} fadeIn`}>
      <Col>
        <img className="img-fluid mb-3" src={logo} alt="" /> <br />
        <h3 className="orange_text">
          {userBalance} <br />
          <span className="text-light">
            {LABELS_PLATFORM.USER_POINTS[lang]}
          </span>
        </h3>
        <h5>Puntos de lealtad</h5>
      </Col>
    </Row>
  );
}

export default UserPoints;
