import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
  LoginFormCont,
  SignupFormCont,
  ForgPassFormCont
} from '../../containers';
import { ErrorBoundary } from '../../components/elements';
import '../../style/login.css';

import bg_login from '../../images/carousel/muebles2.png';

function Login({ history }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [forgPassword, setForgPass] = useState(false);
  const setSignUp = () => {
    setIsSignUp(true);
    setForgPass(false);
  };
  const setForgot = () => {
    setForgPass(true);
    setIsSignUp(false);
  };
  const setLogin = () => {
    setForgPass(false);
    setIsSignUp(false);
  };
  const fond = {
    backgroundImage: `linear-gradient(to top, rgba(0,0,0,.3),rgba(0,0,0,.8)),url(${bg_login})`,
    position: 'relative',
    backgroundSize: 'cover'
  };
  return (
    <>
      <Container className="login_bg abs_cont" style={fond} fluid>
        <Container className="">
          <Row className="h-100 login_cont align-items-center">
            <Col
              xs={12}
              md={isSignUp ? 8 : 6}
              lg={isSignUp ? 6 : 4}
              className="ml-auto"
            >
              <ErrorBoundary>
                {!isSignUp && !forgPassword ? (
                  <LoginFormCont
                    history={history}
                    setSignUp={setSignUp}
                    setForgot={setForgot}
                  />
                ) : isSignUp ? (
                  <SignupFormCont
                    setLogin={setLogin}
                    // {...rest}
                  />
                ) : (
                  <ForgPassFormCont setSignUp={setSignUp} setLogin={setLogin} />
                )}
              </ErrorBoundary>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Login;
