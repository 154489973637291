export const LIST_BRANDS = "LIST_BRANDS";

export const REQUEST_BRAND = "REQUEST_BRAND";
export const REQUEST_ONE_BRAND = "REQUEST_ONE_BRAND";
export const CANCEL_BRAND = "CANCEL_BRAND";
export const CANCEL_ONE_BRAND = "CANCEL_ONE_BRAND";

export const SUCCESS_BRAND = "SUCCESS_BRAND";
export const ERROR_BRAND = "ERROR_BRAND";

export const RESET_BRAND = "RESET_BRAND";
export const RESET_ONE_BRAND = "RESET_ONE_BRAND";

export const CREATE_BRAND = "CREATE_BRAND";

export const SET_ALL_BRANDS = "SET_ALL_BRANDS";

export const START_UPDATE_BRAND = "START_UPDATE_BRAND";

export const set_all_brands = (allBrands) => ({
  type: SET_ALL_BRANDS,
  allBrands
});

export const create_brand = (dataBrand, images) => ({
  type: CREATE_BRAND,
  dataBrand,
  images
});

export const udpate_brand = (dataBrand, images) => ({
  type: START_UPDATE_BRAND,
  dataBrand,
  images
});

export const list_brands = (searchURL) => ({
  type: LIST_BRANDS,
  searchURL
});

export const request_brand = () => ({
  type: REQUEST_BRAND,
  isFetching: true
});

export const cancel_brand = () => ({
  type: CANCEL_BRAND,
  isFetching: false
});

export const request_one_brand = () => ({
  type: REQUEST_ONE_BRAND,
  isFetchingOne: true
});

export const cancel_one_brand = () => ({
  type: CANCEL_ONE_BRAND,
  isFetchingOne: false
});

export const success_brand = (message) => ({
  type: SUCCESS_BRAND,
  message
});

export const error_brand = (error) => ({
  type: ERROR_BRAND,
  error
});

export const reset_brand = () => ({
  type: RESET_BRAND,
  message: false,
  error: false,
  isFetching: false
});

export const reset_one_brand = () => ({
  type: RESET_ONE_BRAND,
  message: false,
  error: false,
  isFetchingOne: false
});
