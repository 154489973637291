import React from 'react';
import { Footer } from '../sections';
import { Carousel, Products, Create, Configurator } from '../landing';
import { ContactFormLandingCont } from '../../containers';

function Landing() {
  return (
    <>
      <Carousel />
      <Products />
      <Create />
      <Configurator />
      <ContactFormLandingCont />
      <Footer />
    </>
  );
}

export default Landing;
