import { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  FormFeedback,
  FormText,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOCAL_MESSAGES, MIN_LENGTH_PASSWORD } from '../../constants';
import { LABELS_FORM_LOGIN } from '../../constants/platform';

const initialForm = process.env.REACT_APP_PREFILL_DATA
  ? {
      login: 'casillasljaime@gmail.com',
      mailConf: 'casillasljaime@gmail.com',
      firstname: 'Luis',
      lastname: 'Casillas',
      passW: 'contrasena',
      passWConf: 'contrasena',
      telephone: '2322342342'
    }
  : {
      login: '',
      firstname: '',
      lastname: '',
      passW: '',
      passWConf: '',
      mailConf: '',
      telephone: ''
    };

function UserAccount({
  lang,
  isFetching,
  addToast,
  startSignup,
  setStopFetchOnEnd
}) {
  const passWname = 'passW';
  const loginName = 'login';
  const loginConf = 'mailConf';
  const passWconfName = 'passWConf';
  const [showPass, setShowPass] = useState(false);
  const [formVals, setFormVals] = useState(initialForm);
  const { login, mailConf, firstname, lastname, passW, passWConf, telephone } =
    formVals;
  const showPassW = () => {
    setShowPass(!showPass);
  };
  const getValue = ({ target: { value, name, type, checked } }) => {
    const val = type === 'checkbox' ? checked : value;
    setFormVals({ ...formVals, [name]: val });
  };
  const handleForm = (e) => {
    e.preventDefault();
    if (mailConf === '') {
      addToast({
        text: 'Confirme su correo',
        error: true
      });
    } else if (passW === '') {
      addToast({
        text: LOCAL_MESSAGES.EMPTY_PASS[lang],
        error: true
      });
    } else if (passW.length < MIN_LENGTH_PASSWORD) {
      addToast({
        text: LOCAL_MESSAGES.SHORT_PASS[lang],
        error: true
      });
    } else if (passW !== passWConf) {
      addToast({
        text: LOCAL_MESSAGES.PASSW_INVALID[lang],
        error: true
      });
    } else if (passWConf !== '' && passW === passWConf) {
      setFormVals({
        login: '',
        passW: '',
        passWConf: ''
      });
      setStopFetchOnEnd(true);
      startSignup({
        ...formVals,
        password: passW,
        email: login
      });
    }
  };
  const validUsername = new RegExp(/@/).test(login);
  const testTel = new RegExp(/^\d{10}$/).test(telephone);
  return (
    <Form onSubmit={handleForm}>
      {isFetching ? (
        <Row className="justify-content-center">
          <Col className="text-center">
            <FontAwesomeIcon spin icon="spinner" />
          </Col>
        </Row>
      ) : (
        <>
          <FormGroup>
            <Label for={loginName}>{LABELS_FORM_LOGIN.EMAIL[lang]}</Label>
            <Input
              onChange={getValue}
              valid={login.length ? validUsername : null}
              invalid={login.length ? null : validUsername}
              className=""
              required
              placeholder="someone@web.com"
              autoComplete="username"
              value={login}
              type="email"
              name={loginName}
              id={loginName}
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirmar Correo</Label>
            <Input
              onChange={getValue}
              valid={mailConf?.length ? mailConf === login : null}
              invalid={mailConf?.length ? mailConf !== login : null}
              className=""
              required
              placeholder="someone@web.com"
              autoComplete="username"
              value={mailConf}
              type="email"
              name={loginConf}
              id={loginConf}
            />
            <FormFeedback valid>
              La confirmación del correo coincide
            </FormFeedback>
            <FormFeedback>{LOCAL_MESSAGES.PASSW_INVALID[lang]}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="telephone">Teléfono</Label>
            <Input
              onChange={getValue}
              className=""
              required
              valid={telephone !== '' ? testTel : null}
              invalid={telephone !== '' ? null : testTel}
              pattern={'[0-9]{10}'}
              autoComplete="telephone"
              maxLength={10}
              value={telephone}
              type="text"
              name="telephone"
              id="telephone"
            />
            <FormText>(10 números)</FormText>
          </FormGroup>

          <FormGroup>
            <Label>{LABELS_FORM_LOGIN.PASS_EIGHT_CH[lang]}</Label>
            <InputGroup>
              <Input
                onChange={getValue}
                className=""
                required
                minLength="6"
                autoComplete="new-password"
                value={passW}
                type={showPass ? 'text' : 'password'}
                name={passWname}
                id={passWname}
              />
              <InputGroupAddon addonType="append">
                <Button onClick={showPassW} color="info" active={showPass}>
                  <FontAwesomeIcon icon={showPass ? 'eye' : 'eye-slash'} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <FormText>{LABELS_FORM_LOGIN.PASS_RESTR[lang]}</FormText>
          </FormGroup>
          <FormGroup>
            <Label>{LABELS_FORM_LOGIN.CONFIRM_PASS[lang]}</Label>
            <Input
              onChange={getValue}
              className=""
              required
              valid={passWConf.length ? passWConf === passW : null}
              invalid={passWConf.length ? passWConf !== passW : null}
              autoComplete="new-password"
              value={passWConf}
              type={showPass ? 'text' : 'password'}
              name={passWconfName}
              id={passWconfName}
            />
            <FormFeedback valid>
              {LOCAL_MESSAGES.PASSW_VALID[lang]}
            </FormFeedback>
            <FormFeedback>{LOCAL_MESSAGES.PASSW_INVALID[lang]}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Button color="primary" block type="submit">
              {LABELS_FORM_LOGIN.REGISTER[lang]}
            </Button>
          </FormGroup>
        </>
      )}
    </Form>
  );
}

export default UserAccount;
