import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PointsForm } from "../../components/forms";
import {
  start_add_points_user,
  start_search_users
} from "../../redux/actions/user-actions";
import { list_brands } from "../../redux/actions/brand-actions";

function PointsFormCont({
  list_brands,
  start_search_users,
  start_add_points_user,
  suggestedUsers,
  allBrands,
  ...rest
}) {
  useEffect(() => {
    if (!allBrands.length) {
      list_brands("");
    }
  }, [allBrands, list_brands]);
  const filteredUsers = suggestedUsers.filter((u) => u.role === 3);
  return (
    <PointsForm
      startSearchUsers={start_search_users}
      suggestedUsers={filteredUsers}
      addPointsUser={start_add_points_user}
      allBrands={allBrands}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  userReducer,
  globalReducer: { lang },
  brandReducer: { allBrands }
}) => ({
  ...userReducer,
  lang,
  allBrands
});

const mapActionsToProps = {
  start_add_points_user,
  start_search_users,
  list_brands
};

export default connect(mapStateToProps, mapActionsToProps)(PointsFormCont);
