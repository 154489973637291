import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Badge,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Collapse
} from 'reactstrap';
import { formatNmxn } from '../../../utils';
import '../../../style/utils.css';
import { ProductTableRowCont } from '../../../containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from '../../../hooks';
import {
  LABELS_PLATFORM,
  STATUS_ORDER,
  SLIDE_MENU_NAMES_CONTENT
} from '../../../constants/platform';
import ToolTipLoyalty from './ToolTipLoyalty';
import {
  DEBOUNCE_TIME,
  PAGINATION_ITEMS,
  PAGINATION_LINKS
} from '../../../constants';
import { STATUS_ORDER_LABEL } from '../../../constants/labels';

const searchEnabled = ['full_name', 'email', '_id', 'collect_code', 'sku', 'tracking_guide'];

function OrdersTable({
  set_select_order,
  startUpdateOrder,
  hasAddress,
  addStatusOrder,
  lang,
  user,
  set_proceed_to_verify,
  proceedToVerify,
  setSlideContent,
  allOrders = [],
  isFetchingOne,
  className,
  canEdit,
  canUpdate,
  statusUser,
  restrictedEditStatus
}) {
  const [editRowId, setEditRow] = useState('');
  const [expanded, setExpanded] = useState('');
  const [search, setSearch] = useState('');
  const [formVals, setFormVals] = useState({
    tracking_guide: '',
    delivery_company: ''
  });
  const [pagination, setPagination] = useState(0);
  const [paginIndex, setPagIndex] = useState(0);

  const [scrollListenTable, setScrollListenTable] = useState(false);
  const myScrollTable = useRef(null);

  useEffect(() => {
    moveScrollEndTable();
  }, [scrollListenTable]);

  const moveScrollEndTable = () => {
    myScrollTable.current.scrollIntoView({ inline: 'end', block: 'nearest' });
  };

  const searchDebounce = useDebounce(search, DEBOUNCE_TIME.SEARCH);

  const pageCount = Math.ceil(allOrders.length / PAGINATION_ITEMS);
  useEffect(() => {
    setPagIndex(0);
  }, [searchDebounce]);
  console.log(allOrders);
  const pagSect = Math.ceil(pageCount / PAGINATION_LINKS);

  const decPag = () => {
    const nextIndex = paginIndex - 1;
    if (nextIndex >= 0) setPagIndex(nextIndex);
  };

  const incPag = () => {
    const nextIndex = paginIndex + 1;
    if (nextIndex < pagSect) setPagIndex(nextIndex);
  };
  const filterFunction = (el) => {
    if (searchDebounce !== '') {
      return Object.entries(el)
        .filter(([key]) => searchEnabled.indexOf(key) > -1)
        .some(([_, val]) => {
          switch (typeof val) {
            case 'number':
              return (
                val
                  .toString()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .indexOf(search.toLowerCase()) !== -1
              );
            case 'string':
              return (
                val
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .indexOf(search.toLowerCase()) !== -1
              );
            default:
              return true;
          }
        });
    } else {
      return true;
    }
  };
  const handleExpand = (expandId) => {
    if (expandId === expanded) {
      setExpanded('');
    } else {
      setExpanded(expandId);
    }
  };
  const handleGetSearch = ({ target: { value } }) => {
    setSearch(value);
  };
  const handleSetEdit = (id) => {
    setEditRow(id);
    setScrollListenTable(!scrollListenTable);
    setFormVals({
      ...formVals,
      tracking_guide: ''
    });
  };
  const handleCancel = () => {
    setEditRow('');
  };
  const getValue = ({ target: { value, name } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  const { tracking_guide, delivery_company } = formVals;
  const handleAddGuide = () => {
    if (tracking_guide !== '') {
      startUpdateOrder(editRowId, { tracking_guide, delivery_company });
      setEditRow('');
    }
  };
  const handleKeyDownEnter = (e) => {
    if (e.key === 'Enter') {
      handleAddGuide();
    }
  };
  const handleChangeStatus = ({ target: { value } }, order_id) => {
    if (value !== '') {
      addStatusOrder(order_id, { status: value });
    }
  };

  const reloadPage = () => {
    window.location.reload(false);
  };
  return (
    <Card className={className}>
      <CardBody>
        {canEdit || canUpdate ? (
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon="search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  onChange={handleGetSearch}
                  value={search}
                  disabled={!allOrders.length}
                  placeholder="búsqueda"
                />
              </InputGroup>
            </Col>
          </Row>
        ) : null}
        {canEdit ? null : (
          <Row className="justify-content-end">
            <Button onClick={reloadPage} color="link">
              <p className="text-dark">
                Actualizar órdenes&nbsp;{' '}
                <FontAwesomeIcon className="text-dark" icon="sync" />
              </p>
            </Button>
          </Row>
        )}
        <Row>
          <Col>
            <Table innerRef={myScrollTable} responsive hover size="sm">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Total</th>
                  {canEdit || canUpdate ? (
                    <>
                      <th>Usuario</th>
                      <th>Correo </th>
                    </>
                  ) : null}

                  {canEdit ? (
                    <th style={{ minWidth: `${canEdit ? '20vw' : ''}` }}>
                      Dirección
                    </th>
                  ) : allOrders.address ? (
                    <th>Dirección</th>
                  ) : null}
                  {canEdit ? <th>Teléfono</th> : null}
                  <th>Creada</th>
                  <th>Último estatus</th>
                  {!restrictedEditStatus && (canEdit || canUpdate) ? (
                    <th style={{ minWidth: '15vw' }}>Actualizar estatus</th>
                  ) : null}
                  {restrictedEditStatus ? null : <th># Rastreo</th>}
                  {restrictedEditStatus ? null : <th>Pagar</th>}
                  {canEdit ? <th>Añadir guía</th> : null}
                </tr>
              </thead>
              <tbody>
                {allOrders
                  .filter(filterFunction)
                  .slice(
                    pagination * PAGINATION_ITEMS,
                    (pagination + 1) * PAGINATION_ITEMS
                  )
                  .map(
                    (
                      {
                        email = '',
                        full_name = '',
                        user,
                        total,
                        address,
                        _id,
                        createdAt,
                        status = [],
                        products = [],
                        tracking_guide: trackingGuide,
                        collect_code,
                        needsShipping,
                        is_payable: isPayable
                      },
                      i
                    ) => (
                      <>
                        <tr key={_id}>
                          <td
                            onClick={() => handleExpand(_id)}
                            className="align-middle px-2"
                          >
                            <div className="caret">
                              Ver orden &nbsp;
                              <FontAwesomeIcon
                                icon={
                                  _id === expanded ? 'caret-down' : 'caret-up'
                                }
                              />
                            </div>
                          </td>

                          <td className="align-middle px-2">{`$${formatNmxn(
                            total
                          )}`}</td>
                          {canEdit || canUpdate ? (
                            <>
                              <td className="align-middle px-2">
                                <b>{full_name}</b>
                              </td>
                              <td className="align-middle px-2">{email}</td>
                            </>
                          ) : null}

                          {canEdit ? (
                            <td className="align-middle px-2">
                              {address?.city}, {address?.line_1},
                              {address?.line_2}
                            </td>
                          ) : allOrders.address ? (
                            <td className="align-middle px-2">
                              {address?.city}, {address?.line_1},
                              {address?.line_2}
                            </td>
                          ) : null}
                          {canEdit ? (
                            <td className="align-middle px-2">
                              {user?.telephone}
                            </td>
                          ) : null}

                          <td className="align-middle px-2">
                            {new Date(createdAt).toLocaleString('es-MX')}
                          </td>
                          <td className="align-middle px-2">
                            <Badge color="info">
                              {
                                STATUS_ORDER_LABEL[
                                  status.length && status.slice(-1)[0].status
                                ]
                              }
                            </Badge>
                          </td>
                          {!restrictedEditStatus && (canEdit || canUpdate) ? (
                            <td className="align-middle px-2">
                              {_id === editRowId && isFetchingOne ? (
                                <FontAwesomeIcon spin icon="cog" />
                              ) : (      
                                      (  STATUS_ORDER_LABEL[
                                        status.length && status.slice(-1)[0].status
                                        ])==="CANCELADA"
                                      ? 
                              <Input
                                type="select"
                              >
                                <option value="">
                                 CANCELADA
                                </option>
                              </Input> : 
                              <Input
                                  onChange={(e) => handleChangeStatus(e, _id)}
                                  type="select"
                                >
                                  <option value="">
                                    {LABELS_PLATFORM.CHOOSE_VALUE[lang]}
                                  </option>
                                  {restrictedEditStatus
                                    ? Object.entries(STATUS_ORDER)
                                        .slice(0, restrictedEditStatus)
                                        .map(([k, v], i) => (
                                          <option key={i} value={k}>
                                            {v}
                                          </option>
                                        ))
                                    : Object.entries(STATUS_ORDER).map(
                                        ([k, v], i) => (
                                          <option key={i} value={k}>
                                            {v}
                                          </option>
                                        )
                                      )}
                                </Input>
                                
                              )}
                            </td>
                          ) : null}
                          {restrictedEditStatus ? null : (
                            <td className="align-middle px-2">
                              {trackingGuide ? (
                                <ToolTipLoyalty
                                  id={i}
                                  labelText="Ver más"
                                  content={trackingGuide}
                                />
                              ) : (
                                'no añadida'
                              )}
                            </td>
                          )}
                          {!restrictedEditStatus ? (
                            <td className="align-middle px-2">
                              {statusUser === 'INACTIVE' ? (
                                <Button
                                  color="info"
                                  onClick={() => {
                                    setSlideContent(
                                      SLIDE_MENU_NAMES_CONTENT.VERIFY
                                    );
                                  }}
                                >
                                  Verificar
                                </Button>
                              ) : !hasAddress ? (
                                <Button
                                  color="info"
                                  onClick={() => {
                                    setSlideContent(
                                      SLIDE_MENU_NAMES_CONTENT.ADD_ADDRESS
                                    );
                                    set_select_order(allOrders[i]);
                                  }}
                                >
                                  Dirección
                                </Button>
                              ) : status[status.length - 1].status ===
                                'CANCELED' ? (
                                <Button color="danger" tag={Link} to={`/`}>
                                  {LABELS_PLATFORM.CANCELLED[lang]}
                                </Button>
                              ) : status[status.length - 1].status !==
                                  'AUTHORIZE_PAYMENT' &&
                                status[status.length - 1].status !==
                                  'CREATED' &&
                                status[status.length - 1].status !==
                                  'PROCESSED' &&
                                status[status.length - 1].status !==
                                  'UPDATED' &&
                                isPayable ? (
                                <Button disabled={true}  color="info" tag={Link} to={`/`}>
                                  {LABELS_PLATFORM.PAID[lang]}
                                </Button>
                              ) : (
                                <Button
                                  disabled={!isPayable}
                                  color={isPayable ? 'info' : 'secondary'}
                                  tag={Link}
                                  to={`/orders/${_id}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {LABELS_PLATFORM.PAY[lang]}
                                </Button>
                              )}
                            </td>
                          ) : null}

                          {canEdit ? (
                            <td
                              style={
                                _id === editRowId ? { minWidth: '400px' } : null
                              }
                              className="align-middle px-2"
                            >
                              {_id === editRowId ? (
                                <InputGroup>
                                  <Input
                                    value={tracking_guide}
                                    name="tracking_guide"
                                    onChange={getValue}
                                    placeholder="tracking guide"
                                    onKeyDown={handleKeyDownEnter}
                                  />

                                  <InputGroupAddon addonType="append">
                                    <Button
                                      onClick={handleAddGuide}
                                      color="info"
                                    >
                                      {LABELS_PLATFORM.ADD[lang]}

                                    </Button>
                                  </InputGroupAddon>
                                  <InputGroupAddon addonType="append">
                                    <Button
                                      onClick={handleCancel}
                                      color="danger"
                                    >
                                      &times;
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              ) : (
                                <Button
                                  onClick={() => handleSetEdit(_id)}
                                  color="dark"
                                >
                                  {LABELS_PLATFORM.EDIT[lang]}
                                </Button>
                              )}
                            </td>
                          ) : null}
                        </tr>
                        <tr className="border-0">
                          <td
                            style={{
                              padding: _id === expanded ? 'inherit' : 0
                            }}
                            colSpan={canEdit || canUpdate ? 11 : 9}
                          >
                            <Collapse isOpen={_id === expanded}>
                              <Table>
                                <tbody>
                                  {collect_code ? (
                                    <tr className="align-middle">
                                      <td
                                        colSpan={canEdit || canUpdate ? 6 : 5}
                                      >
                                        <b>Código de recolección:</b> &nbsp;
                                        {collect_code}
                                      </td>
                                    </tr>
                                  ) : null}

                                  <tr className="align-middle">
                                    <td colSpan={canEdit || canUpdate ? 6 : 5}>
                                      <b>
                                        {`Código único de orden: ${_id.slice(
                                          -6
                                        )}`}
                                      </b>
                                    </td>
                                  </tr>
                                  {address?.line_1 && needsShipping ? (
                                    <tr className="align-middle">
                                      <td
                                        colSpan={canEdit || canUpdate ? 6 : 5}
                                      >
                                        <b>{`Enviar a: `}</b>
                                        {`${address?.line_1} ${address?.line_2} ${address?.postal_code} ${address?.state} ${address?.city}`}
                                      </td>
                                    </tr>
                                  ) : null}
                                  {trackingGuide ? (
                                    <tr className="align-middle">
                                      <td
                                        colSpan={canEdit || canUpdate ? 6 : 5}
                                      >
                                        <b>
                                          {`Guía de seguimiento: ${trackingGuide}`}
                                        </b>
                                      </td>
                                    </tr>
                                  ) : null}
                                  {products.length > 0 && (
                                    <tr>
                                      <th>Imagen</th>
                                      <th>Categoría</th>
                                      <th>SKU</th>
                                      <th>Productos</th>
                                      <th>Total</th>
                                    </tr>
                                  )}
                                  {products?.map(
                                    (
                                      {
                                        product,
                                        quantity,
                                        status,
                                        _id: product_order_id
                                      },
                                      i
                                    ) => (
                                      <>
                                        {product ? (
                                          <ProductTableRowCont
                                            key={product_order_id}
                                            product={product}
                                            quantity={quantity}
                                            statusProductOrder={status}
                                            productOrderId={product_order_id}
                                            canEdit={canEdit || canUpdate}
                                            orderId={_id}
                                          />
                                        ) : (
                                          <tr key={i}>
                                            <td
                                              colSpan="5"
                                              className="align-middle"
                                            >
                                              No encontrado
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Collapse>
                          </td>
                        </tr>
                      </>
                    )
                  )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {canEdit ? (
          <Row className="justify-content-center">
            <Pagination>
              {pageCount > PAGINATION_LINKS && (
                <PaginationItem disabled={paginIndex === 0}>
                  <PaginationLink first onClick={decPag} />
                </PaginationItem>
              )}
              {[...Array(pageCount).keys()]
                .slice(
                  paginIndex * PAGINATION_LINKS,
                  (paginIndex + 1) * PAGINATION_LINKS
                )
                .map((el, i) => (
                  <PaginationItem active={el === pagination} key={i}>
                    <PaginationLink
                      onClick={() => setPagination(parseInt(el, 10))}
                    >
                      {parseInt(el, 10) + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
              {pageCount > PAGINATION_LINKS && (
                <PaginationItem disabled={paginIndex === pagSect - 1}>
                  <PaginationLink onClick={incPag} last />
                </PaginationItem>
              )}
            </Pagination>
          </Row>
        ) : null}
      </CardBody>
    </Card>
  );
}

export default OrdersTable;
