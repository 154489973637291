import { Container, Row, Col } from 'reactstrap';
import { Footer } from '../sections';
import { OrderCheckoutCont } from '../../containers';

import '../../style/order-page.css';

function OrderPage() {
  return (
    <>
      <Container className="bg-light" fluid>
        <Container>
          <Row className="order_details pt-5">
            <Col className="d-flex flex-column justify-content-center">
              <OrderCheckoutCont />
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default OrderPage;
