import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductTableRow } from '../../components/pieces';
import { add_status_order_product } from '../../redux/actions/order-actions';
import { list_categories } from '../../redux/actions/category-actions';
import { USER_ROLES_NUMBER } from '../../constants/platform';

function ProductTableRowCont({
  list_categories,
  allCategories,
  add_status_order_product,
  user: { role },
  ...rest
}) {
  useEffect(() => {
    if (allCategories === null) {
      list_categories('');
    }
  }, [list_categories, allCategories]);
  return (
    <ProductTableRow
      allCategories={allCategories}
      addStatusOrderProduct={add_status_order_product}
      restrictedEditStatus={role === USER_ROLES_NUMBER.MANAGER ? 1 : null}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  categoryReducer,
  globalReducer: { lang },
  loginReducer: { user },
  orderReducer
}) => ({
  lang,
  user,
  ...categoryReducer,
  ...orderReducer
});

const mapActionsToProps = {
  add_status_order_product,
  list_categories
};

export default connect(mapStateToProps, mapActionsToProps)(ProductTableRowCont);
