import React from "react";
import { connect } from "react-redux";
import { RegisterUserForm } from "../../components/forms";
import { start_preregister } from "../../redux/actions/signup-actions";

function RegisterUserFormCont({ form_modal, start_preregister, ...rest }) {
  return <RegisterUserForm registerUser={start_preregister} {...rest} />;
}

const mapStateToProps = ({
  userReducer,
  platformReducer: { sel_form },
  globalReducer: { lang }
}) => ({
  ...userReducer,
  form_modal: sel_form !== "" ? true : false,
  lang
});

const mapActionsToProps = {
  start_preregister
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(RegisterUserFormCont);
