export const LIST_CATEGORIES = 'LIST_CATEGORIES';
export const REQUEST_CATEGORY = 'REQUEST_CATEGORY';
export const CANCEL_CATEGORY = 'CANCEL_CATEGORY';
export const SUCCESS_CATEGORY = 'SUCCESS_CATEGORY';
export const ERROR_CATEGORY = 'ERROR_CATEGORY';
export const RESET_CATEGORY = 'RESET_CATEGORY';
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_FILTER_CATEGORIES = 'SET_FILTER_CATEGORIES';
export const ADD_FILTER_CATEGORY = 'ADD_FILTER_CATEGORY';
export const REMOVE_FILTER_CATEGORY = 'REMOVE_FILTER_CATEGORY';
export const CREATE_CATEGORY = 'CFREATE_CATEGORY';

export const list_categories = (category_URL) => ({
  type: LIST_CATEGORIES,
  category_URL
});
export const create_category = (data) => ({
  type: CREATE_CATEGORY,
  data
});
export const request_category = () => ({
  type: REQUEST_CATEGORY,
  isFetching: true
});

export const cancel_category = () => ({
  type: CANCEL_CATEGORY,
  isFetching: false
});

export const success_category = (message) => ({
  type: SUCCESS_CATEGORY,
  message
});

export const error_category = (error) => ({
  type: ERROR_CATEGORY,
  error
});

export const reset_category = () => ({
  type: RESET_CATEGORY,
  message: null,
  isFetching: false,
  selCategory: null
});

export const set_all_categories = (allCategories) => ({
  type: SET_ALL_CATEGORIES,
  allCategories
});

export const set_selected_category = (selCategory) => ({
  type: SET_SELECTED_CATEGORY,
  selCategory
});

export const set_filter_categories = (filterCategories) => ({
  type: SET_FILTER_CATEGORIES,
  filterCategories
});

export const add_filter_category = (category) => ({
  type: ADD_FILTER_CATEGORY,
  category
});

export const remove_filter_category = (categoryId) => ({
  type: REMOVE_FILTER_CATEGORY,
  categoryId
});
