import React from 'react';
import { connect } from 'react-redux';
import { Navb } from '../components/sections';
import { set_lang } from '../redux/actions/global-actions';
import { add_toast } from '../redux/actions/toasts-actions';
import { reset_signup } from '../redux/actions/signup-actions';
import { LOCAL_MESSAGES } from '../constants';
import { USER_ROLES } from '../constants/platform';

function NavbCont({ add_toast, set_lang, log_out, reset_signup, ...rest }) {
  const setLanguage = (lang) => {
    set_lang(lang);
    add_toast({
      error: false,
      text: LOCAL_MESSAGES.LANGUAGE_SET[lang]
    });
  };
  return (
    <Navb reset_signup={reset_signup} setLanguage={setLanguage} {...rest} />
  );
}

const setUserRole = (user = {}) => {
  if (!user) {
    return null;
  }
  return user.isAdmin
    ? USER_ROLES.ADMIN
    : user.role === 2
    ? USER_ROLES.MANAGER
    : USER_ROLES.USER;
};

export default connect(
  ({
    navbarReducer,
    loginReducer: { token, user },
    globalReducer: { lang }
  }) => ({
    ...navbarReducer,
    token,
    user_role: setUserRole(user),
    lang
  }),
  {
    set_lang,
    add_toast,
    reset_signup
  }
)(NavbCont);
