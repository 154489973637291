import React from 'react';
import { Button } from 'reactstrap';
// import {Link} from 'react-router-dom';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Submenu({ className, createAction, createCategory, buttonLabel }) {
  return (
    <div className={`${className} w-md-25 ml-md-auto shadow-md rounded`}>
      <Button onClick={createAction} color="success" block>
        {buttonLabel}
      </Button>
      {/*<Button block className="mt-2" color="primary" tag={Link} to="/cards" target="_blank" rel="noopener noreferrer">
      {LABELS_PLATFORM.SEARCH[lang]}&nbsp;<FontAwesomeIcon icon="id-badge"/>
    </Button>*/}
      <Button onClick={createCategory} color="success" block>
        Crear Catálogo
      </Button>
    </div>
  );
}

export default Submenu;
