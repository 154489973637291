export const USER_SIGNUP_MESSAGES = {
  CREATE_ACCOUNT: {
    es: 'Por favor crea una cuenta antes de continuar',
    en: 'To continue, please create an account'
  },
  ACCESS_ACCOUNT: {
    es: '¿O ya tienes una? Accede a tu cuenta',
    en: 'Already have one? Access your account'
  }
};

export const USER_VERIFY_MESSAGES = {
  VERIFY_ACCOUNT: {
    es:
      'Envíamos a tu correo un código de verificación, por favor también revisa tu carpeta "de correo no deseado"',
    en:
      'We sent a verification code to your email account please check your "Spam" folder as well'
  }
};
