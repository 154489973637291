import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Tooltip,
  Col,
  Button,
  DropdownItem,
  CustomInput,
  Collapse, CardBody, Card
} from 'reactstrap';

import '../../style/tag_cloud.css';

function TagCloud({ remTag, addTag, tags, lang, filters }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggler = () => setIsOpen(!isOpen);
  return (
    <>
      <Row noGutters className="d-none d-md-flex justify-content-start mb-3 content_tags">
        {tags?.map((tag, i) => (
        <DropDownItemCategory
                    addTag={addTag}
                    remTag={remTag}
                    filters={filters}
                    lang={lang}
                    info={tag}
                    key={i}
                  />
        ))}
      </Row>      
      <Row noGutters className="d-flex d-md-none justify-content-center h-25 mh-100 mb-3">
      <div>
        <Button onClick={toggler} className="bg-white text-dark" style={{ marginBottom: '1rem', }} >
          Encuentra tu colección<FontAwesomeIcon  className="ml-5" icon="angle-down" />
        </Button>
        <Collapse  isOpen={isOpen}>
          <Card>
            <CardBody>
              {tags?.map((tag, i) => (
              <DropDownItemCategory
                          addTag={addTag}
                          remTag={remTag}
                          filters={filters}
                          lang={lang}
                          info={tag}
                          key={i}
                        />
              ))}
            </CardBody>
          </Card>
        </Collapse>
      </div>
      </Row>
    </>
  );
}

function DropDownItemCategory({ addTag, remTag, lang, info, filters }) {
  const [open, setOpen] = useState(false);
  const handleEnter = () => {
    console.log('happening');
    if (!open) setOpen(true);
  };
  const handleLeave = () => {
    if (open) setOpen(false);
  };
  const { _id, description, name } = info;

  const active = !!filters.find((el) => el._id === _id);

  const handleAddTag = () => {
    if (active) {
      remTag(_id);
    } else {
      addTag(info);
    }
  };
  return (
<>

      <DropdownItem>
        {description ? (
          <Tooltip
            placement="right"
            isOpen={open}
            target={`tooltip_${_id}`}
            toggle={() => setOpen(!open)}
          >
            {description[lang]}
          </Tooltip>
        ) : null}
        <CustomInput
          onClick={handleAddTag}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          id={`tooltip_${_id}`}
          checked={active}
          type="switch"
          name="customSwitch"
          label={`${name[lang]}`}u
          readOnly
        />
      </DropdownItem>
</>
  );
}


export default TagCloud;
