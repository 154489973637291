import { fetchStatus, getErrors, setHJson, setHFData } from '../utils';
const API = '/api/v1';
const MAIN_URL = 'https://neocarpets.com.mx/';

export const local = {
  setLocalToken(token) {
    return sessionStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
  },
  revokeLocalToken() {
    return sessionStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
  },
  saveState(state) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('malta_cleyton_state', serializedState);
    } catch (error) {
      console.warn('storage state error', error);
    }
  },
  loadState() {
    try {
      const serializedState = localStorage.getItem('malta_cleyton_state');
      if (serializedState === null) return undefined;
      const state = JSON.parse(serializedState);
      return {
        ...state
      };
    } catch (error) {
      return undefined;
    }
  }
};
// export const api = {
//     postArticle(dataPost){
//         let init
//         init = setHJson(init,'POST',dataPost)
//         return fetch(`${API}/articles`,init)
//         .then(fetchStatus)
//         .catch(getErrors)
//     }
// }

export const isCartContent = () => {
  const dataCart = localStorage.getItem('malta_cleyton_state');
  const cart = JSON.parse(dataCart);
  return cart?.cartProducts?.length ? true : false;
};

export const api_private = {
  getAllBrands(searchURL = '') {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/brands${searchURL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getRenewToken() {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/auth/renew_token`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postDeactivateUser(dataDeactivate) {
    let init;
    init = setHJson(init, 'POST', dataDeactivate);
    return fetch(`${API}/private/auth/user_deactivate`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  uploadImages(images, userId) {
    let init;
    init = setHFData(init, 'POST', images);
    return fetch(`${API}/private/upload/multiple?userId=${userId}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  createCategory(data) {
    let init;
    init = setHJson(init, 'POST', data);
    return fetch(`${API}/private/categories`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  createProduct(data) {
    let init;
    init = setHJson(init, 'POST', data);
    return fetch(`${API}/private/products`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateStatusOrder(order_id, data) {
    let init;
    init = setHJson(init, 'PUT', data);
    return fetch(`${API}/private/orders/${order_id}/status`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateOrder(order_id, data) {
    let init;
    init = setHJson(init, 'PUT', data);
    return fetch(`${API}/private/orders/${order_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateOrderProduct(order_id, product_order_id, data) {
    let init;
    init = setHJson(init, 'PUT', data);
    return fetch(
      `${API}/private/orders/${order_id}/products/${product_order_id}/status`,
      init
    )
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateAddress(address_id, data) {
    let init;
    init = setHJson(init, 'PUT', data);
    return fetch(`${API}/private/addresses/${address_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateProduct(productId, data) {
    let init;
    init = setHJson(init, 'PUT', data);
    return fetch(`${API}/private/products/${productId}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  createOrder(data) {
    let init;
    init = setHJson(init, 'POST', data);
    return fetch(`${API}/private/orders`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllUsers() {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/users`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllOrders() {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/orders`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getSearchUsers(searchURL) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/users${searchURL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateUser(dataUser, user_id) {
    let init;
    init = setHJson(init, 'PUT', dataUser);
    return fetch(`${API}/private/users/${user_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getOneUser(user_id, paramsURL = '') {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/users/${user_id}${paramsURL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  checkUserBalance(user_id) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/private/users/${user_id}/credit`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  addCreditUser(user_id, dataPoints) {
    let init;
    init = setHJson(init, 'POST', dataPoints);
    return fetch(`${API}/private/users/${user_id}/credit`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  addUserAddress(user_id, dataAddress) {
    let init;
    init = setHJson(init, 'POST', dataAddress);
    return fetch(`${API}/private/users/${user_id}/address`, init)
      .then(fetchStatus)
      .catch(getErrors);
  }
};

export const api_public = {
  getOneProduct(product_id) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/products/${product_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getOneOrder(orderId) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/orders/${orderId}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getSearchProduct(product_URL) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/products${product_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllProducts(product_URL) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/products${product_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllCategories(category_URL) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/categories${category_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllCompanies(company_URL) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/companies${company_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postSignUp(dataSignup) {
    let init;
    init = setHJson(init, 'POST', dataSignup);
    return fetch(`${API}/public/auth/sign_up`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postLogin(dataLogin) {
    let init;
    init = setHJson(init, 'POST', dataLogin);
    return fetch(`${API}/public/auth/login`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postForgPass(dataForgP) {
    let init;
    init = setHJson(init, 'POST', dataForgP);
    return fetch(`${API}/public/auth/forgot_password`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postResetPass(dataResetP) {
    let init;
    init = setHJson(init, 'POST', dataResetP);
    return fetch(`${API}/public/auth/password_reset`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getVerifyToken(reset_pass_token) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/auth/validate_token/${reset_pass_token}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postVerify(dataVerify) {
    let init;
    init = setHJson(init, 'POST', dataVerify);
    return fetch(`${API}/public/auth/verify_account`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postOffer(dataOffer) {
    let init;
    init = setHJson(init, 'POST', dataOffer);
    return fetch(`${API}/public/coupon`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  verifyOffer(couponURL) {
    let init;
    init = setHJson(init, 'GET');
    return fetch(`${API}/public/coupon_verify${couponURL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postOrder(dataOrder) {
    let init;
    init = setHJson(init, 'POST', dataOrder);
    return fetch(`${API}/public/order`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postContact(dataContact) {
    let init;
    console.log(dataContact," datacontact")
    init = setHJson(init, 'POST', dataContact);
    return fetch(`${API}/public/contacts`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postPaymentSession(dataPayment) {
    let init;
    init = setHJson(init, 'POST', dataPayment);
    return fetch(`${API}/public/create-checkout-session`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postPurchase(dataBuy) {
    let init;
    init = setHJson(init, 'POST', dataBuy);
    return fetch(`${API}/public/transaction-complete`, init)
      .then(fetchStatus)
      .catch(getErrors);
  }
};

export const api = {
  postContact(contactData) {
    let init;
    init = setHFData(init, 'POST', contactData);
    return fetch(`${MAIN_URL}/php/mail_launch.php`, init)
      .then(fetchStatus)
      .catch(getErrors);
  }
};
