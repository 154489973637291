import { connect } from 'react-redux';
import { UnitsCalculator } from '../../components/forms';
import {
  set_products_cart,
  set_total_cart
} from '../../redux/actions/cart-actions';
import { set_slide_content } from '../../redux/actions/platform-actions';
import { LOCAL_MESSAGES } from '../../constants';

function UnitsCalculatorCont({
  set_products_cart,
  set_total_cart,
  cartProducts,
  total: cartTotal,
  set_slide_content,
  lang,
  ...rest
}) {
  const handleAddProduct = (selProd, prodQuantity, totalPriceProd) => {
    const foundProd = cartProducts.find((prod) => prod._id === selProd._id);
    if (!foundProd) {
      set_products_cart([
        ...cartProducts,
        { ...selProd, quantity: prodQuantity }
      ]);
      set_total_cart(+cartTotal + totalPriceProd);
    } else {
      const prodIndex = cartProducts.indexOf(foundProd);
      set_products_cart([
        ...cartProducts.slice(0, prodIndex),
        {
          ...selProd,
          quantity: +cartProducts[prodIndex].quantity + prodQuantity
        },
        ...cartProducts.slice(prodIndex + 1)
      ]);
      set_total_cart(+cartTotal + totalPriceProd);
    }
  };
  return (
    <UnitsCalculator
      handleAddProduct={handleAddProduct}
      setSlideContent={set_slide_content}
      lang={lang}
      {...rest}
    />
  );
}

const mapActionsToProps = {
  set_products_cart,
  set_total_cart,
  set_slide_content
};

const mapStateToProps = ({
  productReducer,
  cartReducer,
  globalReducer: { lang }
}) => ({
  ...productReducer,
  ...cartReducer,
  lang
});
export default connect(mapStateToProps, mapActionsToProps)(UnitsCalculatorCont);
