import { useEffect } from 'react';
import { connect } from 'react-redux';
import { TagCloud } from '../../components/elements';
import {
  list_categories,
  add_filter_category,
  set_filter_categories,
  remove_filter_category
} from '../../redux/actions/category-actions';

function CategoriesCloudCont({
  list_categories,
  add_filter_category,
  set_filter_categories,
  remove_filter_category,
  allCategories,
  filterCategories,
  error,
  ...rest
}) {
  useEffect(() => {
    if (!error && allCategories === null) {
      list_categories('');
    }
  }, [list_categories, error, allCategories]);

  useEffect(() => {
    if (allCategories !== null && filterCategories.length === 0) {
      const sortCategories = allCategories.sort((a, b) =>
        a.name.es < b.name.es ? -1 : 1
      );
      set_filter_categories(sortCategories.slice(0, 1));
    }
  }, [allCategories, set_filter_categories, filterCategories]);

  const handleRemoveTag = (tagId) => {
    if (filterCategories.length > 1) {
      remove_filter_category(tagId);
    }
  };

  const handleAddTag = (tag) => {
    add_filter_category(tag);
  };

  return (
    <TagCloud
      remTag={handleRemoveTag}
      addTag={handleAddTag}
      tags={allCategories}
      filters={filterCategories}
      error={error}
      {...rest}
    />
  );
}

const mapStateToProps = ({ categoryReducer, globalReducer: { lang } }) => ({
  ...categoryReducer,
  lang
});

const mapActionsToProps = {
  list_categories,
  add_filter_category,
  set_filter_categories,
  remove_filter_category
};

export default connect(mapStateToProps, mapActionsToProps)(CategoriesCloudCont);
