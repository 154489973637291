export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const REQUEST_ONE_PRODUCT = 'REQUEST_ONE_PRODUCT';
export const CANCEL_ONE_PRODUCT = 'CANCEL_ONE_PRODUCT';
export const CANCEL_PRODUCT = 'CANCEL_PRODUCT';
export const SUCCESS_PRODUCT = 'SUCCESS_PRODUCT';
export const ERROR_PRODUCT = 'ERROR_PRODUCT';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';
export const SET_FILTERED_PRODUCTS = 'SET_FILTERED_PRODUCTS';

export const START_SEARCH_PRODUCTS = 'START_SEARCH_PRODUCTS';
export const START_UPDATE_PRODUCT = 'START_UPDATE_PRODUCT';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
// export const GET_PRODUCT_DELETE = "GET_PRODUCT_DELETE";
// export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
// export const PRODUCT_DELETE_ERROR = "PRODUCT_DELETE_ERROR";

export const create_product = (dataProduct, images) => ({
  type: CREATE_PRODUCT,
  dataProduct,
  images
});

export const list_products = (product_URL) => ({
  type: LIST_PRODUCTS,
  product_URL
});

export const start_search_products = (searchURL) => ({
  type: START_SEARCH_PRODUCTS,
  searchURL
});

export const start_update_product = (product_id, dataProduct, images) => ({
  type: START_UPDATE_PRODUCT,
  product_id,
  dataProduct,
  images
});

export const request_product = () => ({
  type: REQUEST_PRODUCT,
  isFetching: true
});

export const cancel_product = () => ({
  type: CANCEL_PRODUCT,
  isFetching: false
});

export const success_product = (message) => ({
  type: SUCCESS_PRODUCT,
  message
});

export const error_product = (error) => ({
  type: ERROR_PRODUCT,
  error
});

export const reset_product = () => ({
  type: RESET_PRODUCT,
  message: null,
  error: null,
  isFetching: false,
  isFetchingOne: false,
  filteredProducts: []
});

export const set_all_products = (allProducts) => ({
  type: SET_ALL_PRODUCTS,
  allProducts
});

export const set_filtered_products = (filteredProducts) => ({
  type: SET_FILTERED_PRODUCTS,
  filteredProducts
});

export const request_one_product = () => ({
  type: REQUEST_ONE_PRODUCT,
  isFetchingOne: true
});

export const cancel_one_product = () => ({
  type: CANCEL_ONE_PRODUCT,
  isFetchingOne: false
});

export const set_selected_product = (selectedProduct) => ({
  type: SET_SELECTED_PRODUCT,
  selectedProduct
});

// export const get_product_delete = (_id) => ({
//   type: GET_PRODUCT_DELETE,
//   isFetchingOne: false,
// });
//
// export const product_delete_success = (message) => ({
//   type: PRODUCT_DELETE_SUCCESS,
//   message,
// });
//
// export const product_delete_error = (error) => ({
//   type: PRODUCT_DELETE_ERROR,
//   error,
// });
