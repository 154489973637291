import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PLATF_SECTIONS_MENU } from '../../constants/platform';

function SideBar({ className, reset_signup, lang, user_role }) {
  const logOut = () => {
    reset_signup();
  };
  return (
    <>
      <Col
        xs={12}
        md={1}
        className={`flex-column justify-content-between ${className}`}
      >
        <div className="text-center">
          {PLATF_SECTIONS_MENU.map(
            ({ to, name, faIcon, visible_for_roles }, i) =>
              visible_for_roles.indexOf(user_role) > -1 ? (
                <Button
                  tag={Link}
                  to={to}
                  className="text-light px-1 animated fadeIn"
                  color="link"
                  block
                  key={i}
                >
                  <FontAwesomeIcon className="side_bar_icon" icon={faIcon} />
                  <br />
                  {name[lang]}
                </Button>
              ) : null
          )}
        </div>
        <div>
          <Button
            onClick={logOut}
            tag={Link}
            to="/login"
            className="text-light px-1"
            color="link"
            block
          >
            <FontAwesomeIcon className="side_bar_icon" icon="sign-out-alt" />
            <br />
            Salir
          </Button>
        </div>
      </Col>
    </>
  );
}

export default SideBar;
