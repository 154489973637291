import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Alert,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CODE_LENGTH_VERIFY } from '../../constants';
import { LABELS_FORM_VERIFY } from '../../constants/labels';
import { USER_VERIFY_MESSAGES } from '../../constants/messages';

import '../../style/inline_verify.css';

const initialForm = {
  verify_code: ''
};

function InlineVerify({
  startVerify,
  location,
  lang,
  isFetching,
  user,
  allOrders = [],
  setCheckout
}) {
  const [formVals, setFormVals] = useState(initialForm);
  const [reachedLastInput, setReachedLastInput] = useState(false);
  const [codeSlots, setCodeSlots] = useState([]);

  const inputRefs = useRef(new Map());
  const storeRef = (ref, key) => {
    inputRefs.current = inputRefs.current.set(key, ref);
  };
  useEffect(() => {
    const slotsToSet = Array(CODE_LENGTH_VERIFY).fill('');
    setCodeSlots(slotsToSet);
  }, []);

  useEffect(() => {
    setFormVals({ verify_code: codeSlots.join('') });
  }, [codeSlots]);

  const getCodeValue = (val, key) => {
    setCodeSlots((prevSlots) => [
      ...prevSlots.slice(0, key),
      val,
      ...prevSlots.slice(key + 1)
    ]);

    const nextRef = inputRefs.current.get(key + 1);

    if (nextRef) {
      nextRef.focus();
    } else {
      setReachedLastInput(true);
    }
  };

  const { verify_code } = formVals;

  const handleSubmit = (e) => {
    e.preventDefault();
    startVerify({
      shortVerificationCode: verify_code,
      userId: user._id ? user._id : allOrders[0].user._id
    });
  };

  const canSubmit = useMemo(
    () => reachedLastInput && verify_code.length === CODE_LENGTH_VERIFY,
    [reachedLastInput, verify_code]
  );

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Alert color="info">
            {USER_VERIFY_MESSAGES.VERIFY_ACCOUNT[lang]}
          </Alert>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="text-center">
          {isFetching ? (
            <FontAwesomeIcon spin icon="spinner" />
          ) : (
            <Form onSubmit={handleSubmit}>
              <FormGroup className="text-center">
                <Label htmlFor="verify_code">
                  {LABELS_FORM_VERIFY.VERIFY_CODE[lang]}
                </Label>
                <Row className="justify-content-center">
                  {codeSlots.map((value, key) => (
                    <Col xs={1} className="px-0 mx-1" key={key}>
                      <Input
                        onChange={({ target: { value, name } }) =>
                          getCodeValue(value, key, name)
                        }
                        innerRef={(input) => storeRef(input, key)}
                        className="code_input px-0 text-center"
                        value={value}
                        maxLength={1}
                        name={`verify_code_${key}`}
                        id={`verify_code_${key}`}
                        type="text"
                      />
                    </Col>
                  ))}
                </Row>
              </FormGroup>
              <FormGroup className="text-center">
                <Row className="justify-content-center">
                  <Col className="text-center">
                    <Button color="primary" disabled={!canSubmit}>
                      {LABELS_FORM_VERIFY.SUBMIT[lang]}
                    </Button>
                  </Col>
                  {location.pathname === '/platform/orders' ? null : (
                    <Col className="text-center">
                      <Button onClick={setCheckout} color="primary">
                        {LABELS_FORM_VERIFY.CHECK_LATER[lang]}
                      </Button>
                    </Col>
                  )}
                </Row>
              </FormGroup>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default InlineVerify;
