import React, { useState } from "react";
import { Form, ListGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATES_DICTIONARY } from "../../constants";
import { EditableEl, EditableElOptions } from "./editableEls";
import { remEmpty, notEmpty } from "../../utils";
import {
  LABELS_FORM_ADDRESS,
  LABELS_FORM_USER
} from "../../constants/platform";

function EditableAddress({ updateAddress, lang, address, isFetching }) {
  const [formVals, setFormVals] = useState({
    city: "",
    // country: "",
    line_1: "",
    line_2: "",
    state: "",
    postal_code: ""
  });
  const [editAll, setEditAll] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = remEmpty({
      ...formVals
    });
    if (notEmpty(data)) {
      updateAddress(address._id, data);
    }
  };
  const handleEditAll = () => {
    setEditAll(true);
    setTimeout(() => setEditAll(false), 1000);
  };
  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  const { city, line_1, line_2, postal_code, state } = formVals;
  return (
    <Form id="editable_address" onSubmit={handleSubmit}>
      {isFetching ? (
        <FontAwesomeIcon icon="atom" spin />
      ) : (
        <ListGroup className="text-left">
          <Button
            className="mb-2"
            onClick={handleEditAll}
            block
            color="primary">
            Editar todo
          </Button>
          <EditableEl
            editValue={getValue}
            setData={address.line_1}
            editAll={editAll}
            editedValue={line_1}
            title={LABELS_FORM_ADDRESS.LINE_ONE[lang]}
            nameInput={"line_1"}
            type="text"
          />
          <EditableEl
            editValue={getValue}
            setData={address.line_2}
            editAll={editAll}
            editedValue={line_2}
            title={LABELS_FORM_ADDRESS.LINE_TWO[lang]}
            nameInput={"line_2"}
            type="text"
          />
          <EditableEl
            editValue={getValue}
            setData={address.city}
            editAll={editAll}
            editedValue={city}
            title={LABELS_FORM_ADDRESS.CITY[lang]}
            nameInput={"city"}
            type="text"
          />
          <EditableEl
            editValue={getValue}
            setData={address.postal_code}
            editAll={editAll}
            editedValue={postal_code}
            title={LABELS_FORM_ADDRESS.POSTAL_CODE[lang]}
            nameInput={"postal_code"}
            type="text"
          />
          <EditableElOptions
            editValue={getValue}
            setData={address.state}
            editAll={editAll}
            editedValue={state}
            options={STATES_DICTIONARY}
            title={LABELS_FORM_ADDRESS.STATE[lang]}
            nameInput={"state"}
            type="text"
          />
          <Button
            // onClick={this.startSend}
            block
            color="primary"
            disabled={isFetching}
            form="editable_address"
            type="submit">
            {LABELS_FORM_USER.UPDATE[lang]}&nbsp;
            <FontAwesomeIcon icon="rocket" />
          </Button>
        </ListGroup>
      )}
    </Form>
  );
}

export default EditableAddress;
