import {
  REQUEST_CONTACT,
  CANCEL_CONTACT,
  SUCCESS_CONTACT,
  ERROR_CONTACT,
  RESET_CONTACT
} from '../actions/contact-actions.js';

export const contactReducer = (
  state = {
    isFetching: false,
    message: null,
    error: false
  },
  action
) => {
  const { type, isFetching, message, error } = action;
  switch (type) {
    case REQUEST_CONTACT:
      return { ...state, isFetching };
    case CANCEL_CONTACT:
      return { ...state, isFetching };
    case SUCCESS_CONTACT:
      return { ...state, message };
    case ERROR_CONTACT:
      return { ...state, error };
    case RESET_CONTACT:
      return { ...state, message, error };

    default:
      return { ...state };
  }
};
