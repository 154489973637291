export const STATUS_ORDER = {
  CREATED: 'CREADA',
  PROCESSED: 'PROCESADA',
  AUTHORIZE_PAYMENT: 'AUTORIZAR_PAGO',
  CANCELED: 'CANCELADA',
  UPDATED: 'ACTUALIZADA',
  SENT: 'ENVIADA',
  IN_TRANSIT: 'EN_TRANSITO',
  DELIVERED: 'ENTREGADA',
  RECEIVED: 'RECIBIDA'
};

export const STATUS_PRODUCT = {
  CREATED: 'CREADO',
  PROCESSED: 'PROCESADO',
  CANCELED: 'CANCELADO',
  UPDATED: 'ACTUALIZADO',
  SENT: 'ENVIADO',
  IN_TRANSIT: 'EN_TRANSITO',
  DELIVERED: 'ENTREGADO',
  RECEIVED: 'RECIBIDO'
};

export const USER_ROLES = {
  USER: 'USER',
  MANAGER: 'DISTRIBUITOR',
  ADMIN: 'ADMIN'
};

export const USER_ROLES_NUMBER = {
  USER: 3,
  MANAGER: 2,
  ADMIN: 1
};

export const USER_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE'
};

export const PLATFORM_ROUTES = {
  LOGIN: 'login',
  PLATFORM: 'platform'
};

export const CREDIT_RULES = {
  range_proportional: 'range_proportional',
  range_cumulative: 'range_cumulative'
};

// TODO: Add named keys to object
export const PLATF_SECTIONS_MENU = [
  {
    shortname: '',
    to: `/${PLATFORM_ROUTES.PLATFORM}`,
    isPublic: true,
    visible_for_roles: [USER_ROLES.USER, USER_ROLES.ADMIN, USER_ROLES.MANAGER],
    faIcon: 'home',
    name: {
      es: 'Inicio',
      en: 'Home'
    }
  },
  // {
  //   shortname: 'points',
  //   to: `/${PLATFORM_ROUTES.PLATFORM}/points`,
  //   visible_for_roles: [USER_ROLES.USER, USER_ROLES.MANAGER, USER_ROLES.ADMIN],
  //   faIcon: 'plus-circle',
  //   name: {
  //     es: 'Puntos',
  //     en: 'Points'
  //   }
  // },
  {
    shortname: 'products',
    to: `/${PLATFORM_ROUTES.PLATFORM}/products`,
    faIcon: 'box',
    visible_for_roles: [USER_ROLES.ADMIN, USER_ROLES.MANAGER],
    name: {
      es: 'Productos',
      en: 'Products'
    }
  },
  {
    shortname: 'orders',
    to: `/${PLATFORM_ROUTES.PLATFORM}/orders`,
    visible_for_roles: [USER_ROLES.USER],
    faIcon: 'truck-moving',
    name: {
      es: 'Órdenes',
      en: 'Orders'
    }
  },
  // {
  //   shortname: 'profile',
  //   to: `/${PLATFORM_ROUTES.PLATFORM}/profile`,
  //   visible_for_roles: [USER_ROLES.USER],
  //   faIcon: 'user',
  //   name: {
  //     es: 'Perfil',
  //     en: 'Profile'
  //   }
  // },
  {
    shortname: 'users',
    to: `/${PLATFORM_ROUTES.PLATFORM}/users`,
    visible_for_roles: [USER_ROLES.ADMIN],
    faIcon: 'users',
    name: {
      es: 'Usuarios',
      en: 'Users'
    }
  }
  // {
  //   shortname: "salepoints",
  //   to: `/${PLATFORM_ROUTES.PLATFORM}/salepoints`,
  //   visible_for_roles: [USER_ROLES.MANAGER, USER_ROLES.ADMIN],
  //   faIcon: "map-marker-alt",
  //   name: {
  //     es: "P.Venta",
  //     en: "POS",
  //   },
  //   component: PointOfSales,
  // },
];

export const SLIDE_MENU_CONTENT = {
  CART: {
    title: {
      es: 'Carrito de compra',
      en: 'Cart'
    }
  },
  CHECKOUT: {
    title: {
      es: 'Finalizar compra',
      en: 'Checkout'
    }
  },
  CREATED_ORDER: {
    title: {
      es: 'Confirmación de orden',
      en: 'Order confirmation'
    }
  },
  UNITS_CALCULATOR: {
    title: {
      es: 'Calcular unidades',
      en: 'Calculate units'
    }
  },
  SIGNUP: {
    title: { es: 'Crear cuenta', en: 'Create Account' }
  },
  VERIFY: {
    title: { es: 'Verifica tu cuenta', en: 'Verify Account' }
  }
};

export const SLIDE_MENU_NAMES_CONTENT = {
  CART: 'CART',
  CHECKOUT: 'CHECKOUT',
  CREATED_ORDER: 'CREATED_ORDER',
  UNITS_CALCULATOR: 'UNITS_CALCULATOR',
  ADD_ADDRESS: 'ADD_ADDRESS',
  SIGNUP: 'SIGNUP',
  VERIFY: 'VERIFY'
};

export const FORM_NAMES = {
  PROFILE_UPDATE: 'PROFILE_UPDATE',
  PRODUCT_FORM: 'PRODUCT_FORM',
  CATEGORY_FORM: 'CATEGORY_FORM',
  ADD_POINTS: 'ADD_POINTS',
  REGISTER_MINORIST: 'REGISTER_MINORIST',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  EDIT_PRODUCT: 'EDIT_PRODUCT'
};

export const FORMS = {
  PROFILE_UPDATE: {
    title: {
      en: 'Fill your profile',
      es: 'Completa tu perfil'
    }
  },
  PRODUCT_FORM: {
    title: {
      es: 'Crear un producto nuevo',
      en: 'Create a new product'
    }
  },
  ADD_POINTS: {
    title: {
      es: 'Añadir puntos a un usuario',
      en: 'Add points to a user'
    }
  },
  REGISTER_MINORIST: {
    title: {
      es: 'Pre Registrar usuario',
      en: 'Pre register user'
    }
  },
  EDIT_ADDRESS: {
    title: {
      es: 'Editar domicilio',
      en: 'Edit Address'
    }
  }
};

export const LABELS_FORM_SIGNUP = {};

export const LABELS_FORM_LOGIN = {
  DISTRIBUITOR: {
    es: 'Soy Distribuidor',
    en: "I'm Distribuitor"
  },
  MINORIST: {
    es: 'Soy Punto de Venta',
    en: "I'm minorist"
  },
  VALID: {
    es: 'Súper',
    en: 'Super'
  },
  INVALID: {
    es: 'Inválido',
    en: 'Invalid'
  },
  PASS_FORG_TITLE: {
    es: 'Contraseña olvidada',
    en: 'Forgotten Password'
  },
  SEND_FORG_EMAIL: {
    es: 'Enviar correo de recuperación',
    en: 'Send password reset mail'
  },
  NO_ACCOUNT: {
    es: '¿No tienes cuenta?',
    en: "¿Don't have an account?"
  },
  CREATE_ACCOUNT: {
    es: 'Crea una cuenta',
    en: 'Create account'
  },
  PASS_FORG: {
    es: '¿Olvidaste tu contraseña?',
    en: 'Forgot your password?'
  },
  HAS_ACCOUNT: {
    es: '¿Ya tienes cuenta?',
    en: 'Have an account?'
  },
  REGISTER: {
    es: 'Registrarse',
    en: 'Register'
  },
  CONFIRM_PASS: {
    es: 'Confirma la contraseña',
    en: 'Confirm Password'
  },
  PASS_EIGHT_CH: {
    es: 'Contraseña',
    en: 'Password'
  },
  PASS_RESTR: {
    es: '(6 Caracteres)',
    en: 'Password (6 Chars.)'
  },
  RESET_PASS: {
    es: 'Reestablecer contraseña',
    en: 'Reset password'
  },
  LOGIN: {
    es: 'Ingresa',
    en: 'Log in'
  },
  EMAIL: {
    es: 'Correo',
    en: 'Email'
  },
  FULL_NAME: {
    es: 'Nombre complet',
    en: 'Full Name'
  },
  TELEPHONE: {
    es: 'Teléfono',
    en: 'Phone number'
  }
};

export const PRODUCT_TYPE_OPTIONS = [
  {
    val: 'product',
    label: 'Producto'
  },
  {
    val: 'promotional_bag',
    label: 'Saco promocional'
  },
  {
    val: 'productcard',
    label: 'Tarjeta de regalo'
  },
  {
    val: 'clothing',
    label: 'Ropa'
  }
];

export const PRODUCT_TYPES = {
  promotional_bag: 'promotional_bag'
};

export const LABELS_FORM_USER = {
  EDIT_ALL: {
    es: 'Editar todo',
    en: 'Edit all fields'
  },
  UPDATE: {
    es: 'Actualizar',
    en: 'Update'
  },
  ADD_IMAGE: {
    es: 'Añadir una imagen a la BeCard',
    en: 'Add an image to the BeCard'
  },
  IMAGE_FORM_TITLE: {
    es: 'Imagen',
    en: 'Add an image to the BeCard'
  },
  TAGS: {
    es: 'Etiquetas',
    en: 'Tags'
  },
  TAGS_EXPL: {
    es: 'Para filtrar la búsqueda y maneras en las que te pueden encontrar',
    en: 'For filtering search'
  },
  BIO: {
    es: 'Biografía',
    en: 'Bio'
  },
  BIO_EXPL: {
    es: 'Mientras más concreta mejor',
    en: 'The more succint the better'
  },
  BIRTHDATE: {
    es: 'Fecha nacimiento',
    en: 'Birthday'
  },
  BIRTHDATE_EXPL: {
    es: '',
    en: ''
  },
  PHONE: {
    es: 'Teléfono',
    en: 'Telephone'
  },
  PHONE_EXPL: {
    es: '10 dígitos p.ej 1234567890',
    en: '10 digits format'
  },
  PHONE_VALID: {
    es: 'Súper',
    en: 'Super'
  },
  PHONE_INVALID: {
    es: 'Ingresa 10 dígitos',
    en: 'Please use 10 digits'
  },
  PHONE_EXT: {
    es: 'Extensión',
    en: 'Extension'
  },
  HANDLE: {
    es: 'Handle único',
    en: 'Unique handle'
  },
  SHORTNAME: {
    es: 'Nombre corto',
    en: 'Shortname'
  },
  SHORTNAME_EXPL: {
    es: 'Nombre corto para identificación rápida',
    en: 'Short name for rapid identification'
  },
  TITLE: {
    es: 'Ocupación/Puesto',
    en: 'Job Title'
  },
  HANDLE_EXPL: {
    es: 'El título único de tu BeCard p.ej https://www.becards.app/cards/community/juan.gomez ',
    en: 'Unique title for your BeCard ex. https://www.becards.app/cards/community/john.doe'
  },
  FIRST_NAME: {
    es: 'Nombre(s)',
    en: 'Name'
  },
  TREATMENT: {
    es: 'Prefijo',
    en: 'Treatment'
  },
  LAST_NAME: {
    es: 'Apellido(s)',
    en: 'Last name'
  },
  SHOW_BIRTHDATE: {
    es: 'Mostrar cumpleaños',
    en: 'Show BirthDate'
  },
  FILE_UPLOAD: {
    es: 'Apellido(s)',
    en: 'Last name'
  },
  FILE_UPLOAD_EXPL: {
    es: 'Apellido(s)',
    en: 'Last name'
  },
  PROFILE_UPLOAD: {
    es: 'Foto perfil',
    en: 'Profile Picture'
  },
  PROFILE_UPLOAD_EXPL: {
    es: 'Una foto para personalizar tu perfil, debe ser de al menos 60px por 60px',
    en: 'A profile picture to personalize your profile, it should be at least 60px square'
  },
  SUBMIT: {
    es: 'Crear Producto',
    en: 'Create Product'
  }
};

// TODO: Move all thats publicly available outside of platform constants
export const LABELS_FILTER = {
  PRODUCT_TYPE: {
    es: 'Tipo de producto',
    en: 'Product Types'
  },
  PRICE: {
    es: 'Por Nutribonos',
    en: 'By Nutribonos'
  },
  SHIPPING: {
    es: 'Por envío',
    en: 'By shipping'
  }
};

export const LABELS_FORM_POINTS = {
  SEARCH_USER: {
    es: 'Buscar usuario',
    en: 'Search user'
  },
  SELECT_VALUE: {
    es: 'Seleccione un valor...',
    en: 'Choolse a value'
  },
  TICKET_REF: {
    es: 'Ticket de referencia',
    en: 'Ref ticket'
  },
  BOUGHT_KG: {
    es: 'Kilogramos comprados',
    en: 'Bougth Kilograms'
  },
  ANIMAL_SELECTED: {
    es: 'Línea seleccionada',
    en: 'Line selected'
  },
  ADD_POINTS: {
    es: 'Añadir puntos',
    en: 'Add Points'
  }
};

export const LABELS_FORM_ADDRESS = {
  CITY: {
    es: 'Ciudad',
    en: 'City'
  },
  LINE_ONE: {
    es: 'Calle y Número',
    en: 'Line 1'
  },
  LINE_TWO: {
    es: 'Colonia',
    en: 'Line 2'
  },
  POSTAL_CODE: {
    es: 'CP',
    en: 'ZIP'
  },
  STATE: {
    es: 'Estado',
    en: 'State/County'
  },
  SHIPPING: {
    es: 'Domicilio de entregas',
    en: 'Shipping Address'
  },
  SAVE_ADDRESS: {
    es: 'Guardar Domicilio',
    en: 'Save Address'
  },
  CONFIRM_ADDRESS: {
    es: 'Confirmar domicilio',
    en: 'Confirm Address'
  }
};

export const LABELS_CALENDAR = {
  ACTIVITIES: {
    es: 'Actividades',
    en: 'Activites'
  },
  NO_ACTIVITIES: {
    es: 'Proximamente asistente personal',
    en: 'Soon personal assistant'
  }
};

export const CART_LABELS = {
  ADD_TO_CART: {
    es: 'Añadir al carrito',
    en: 'Add to Cart'
  },
  EMPTY_CART: {
    es: '😦 No hay productos en el carrito',
    en: '😦 No products in the cart'
  }
};

export const LABELS_PLATFORM = {
  CLOSE: {
    en: 'Close',
    es: 'Cerrar'
  },
  CANCEL: {
    en: 'Cancel',
    es: 'Cancelar'
  },
  CREATE_PRODUCT: {
    en: 'Create Product',
    es: 'Crear Producto'
  },
  ADD_POINTS: {
    es: 'Añadir Puntos',
    en: 'Add Points'
  },
  PRE_REGISTER: {
    es: 'Pre registrar usuario',
    en: 'Pre register user'
  },
  SEARCH: {
    en: 'Search',
    es: 'Buscar'
  },
  NO_PRODUCTS: {
    es: 'No hay Productos disponibles',
    en: 'No available Products'
  },
  USER_POINTS: {
    es: 'Nutribonos',
    en: 'Nutripoints'
  },
  EMPTY_OPTIONS: {
    es: 'Seleccione una opción...',
    en: 'Choose an option...'
  },
  PROCEED_TO_CHECKOUT: {
    es: 'Continuar',
    en: 'Proceed to checkout'
  },
  CONTINUE_SHOPPING: {
    es: 'Seguir comprando',
    en: 'Keep buying'
  },
  ASCENDENT: {
    es: 'De menor a mayor',
    en: 'Ascendent'
  },
  DESCENDENT: {
    es: 'De mayor a menor',
    en: 'Descendent'
  },
  SHIPPABLE: {
    es: 'Se envía a domicilio',
    en: 'Ascendent'
  },
  NOT_SHIPPABLE: {
    es: 'Se recoge en sucursal',
    en: 'Descendent'
  },
  ACTIVATE_DISTRIBUITOR_USERS: {
    es: 'Activar usuarios Distribuidores',
    en: 'Activate Distribuitor users'
  },
  ORDERS: {
    es: 'Órdenes por usuario',
    en: 'Orders by users'
  },
  POINTS_USAGE: {
    es: 'Uso de puntos',
    en: 'Points usage'
  },
  CHOOSE_VALUE: {
    es: 'Escoja un valor',
    en: 'Choose a value'
  },
  ADD_GUIDE: {
    es: 'Añadir guía',
    en: 'Add guide'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit'
  },
  ADD: {
    es: 'Añadir',
    en: 'Add'
  },
  PAY: {
    es: 'Pagar',
    en: 'Pay'
  },
  PAID: {
    es: 'Pagado',
    en: 'Paid'
  },
  CANCELLED: {
    es: 'Cancelado',
    en: 'Cancelled'
  }
};

export const LABELS_CHECKOUT = {
  TOTAL_TO_PAY: {
    es: 'Total a pagar:',
    en: 'Total to pay:'
  },
  CREATE_ORDER: {
    es: 'Confirmar Pedido',
    en: 'Create Order'
  },
  EDIT_CART: {
    es: 'Editar Carrito',
    en: 'Edit Cart'
  },
  OF: { es: 'por', en: 'for' },
  DISCLAIMER_ORDER: {
    es: 'Todas las órdenes serán comprobadas contra existencia, te enviaremos un recibo de pago una vez hecho esto, el tiempo de entrega es de 10 a 15 días hábiles.',
    en: 'All orders are confirmed against inventory'
  },
  NO_ADDRESSES: {
    es: 'No tienes ningún domicilio regsitrado',
    en: "You don't have any address registered"
  },
  ADD_ADDRESS: {
    es: 'Añadir domicilio',
    en: 'Add Address'
  },
  SELECT_ADDRESS: {
    es: 'Seleccione un domicilio para la orden',
    en: 'Select an address to send the order to'
  },
  SHIPPING_COST: {
    es: 'Costo de envío',
    en: 'Shipping cost'
  },
  USE_ADDRESS: {
    es: '¿Quieres usar esta dirección como tu dirección de envío?',
    en: 'Do you want to use this address as your shipping address?'
  },
  COMFIRM: {
    es: 'Confirmar',
    en: 'Confirm'
  }
};

export const LABELS_ORDER = {
  THANK_YOU: {
    es: 'Muchas gracias por su orden',
    en: 'Thank you for your order'
  },
  ORDER: {
    es: 'Orden',
    en: 'Order'
  },
  LATEST_STATUS: {
    es: 'Último estatus',
    en: 'Latest status'
  },
  USED_POINTS: {
    es: 'usados',
    en: 'used in this order'
  },
  IMAGE: {
    es: 'Imagen',
    en: 'Image'
  },
  PRODUCT: {
    es: 'Producto',
    en: 'Product'
  },
  SUBTOTAL: {
    es: 'Subtotal',
    en: 'Subtotal'
  },
  TOTAL: {
    es: 'Total',
    en: 'Total'
  },
  PAY: { es: 'Pagar total', en: 'Pay total' },
  CREATED_ORDER: {
    es: `⚠️ Gracias por tu orden, a la brevedad te enviaremos un correo con los detalles para realizar el pago`,
    en: `⚠️ Thank you for placing an order, will contact you to pay and fulfill the order`
  }
};

export const LABELS_ADMIN_USER = {
  ACTIVATE: {
    es: 'Activar',
    en: 'Activate'
  },
  DEACTIVATE: {
    es: 'Desactivar',
    en: 'Deactivate'
  }
};

export const SHORT_ID = 6;

export const MAX_TAGS = 6;

export const TAGS_MESSAGES = {
  REPEATED: {
    es: 'La etiqueta ya está agregada',
    en: 'Tag is already added'
  },
  MAXED: {
    es: `Máximo ${MAX_TAGS} etiquetas`,
    en: `You can add ${MAX_TAGS} tops`
  }
};

export const SOCIAL_NETWORKS = [
  {
    network: 'facebook',
    faIcon: 'facebook',
    placeholder: 'example_user',
    urlEx: 'https://www.facebook.com/'
  },
  {
    network: 'instagram',
    faIcon: 'instagram',
    placeholder: 'example_user',
    urlEx: 'https://www.instagram.com/'
  },
  {
    network: 'twitter',
    faIcon: 'twitter',
    placeholder: 'example_user',
    urlEx: 'https://twitter.com/'
  },
  {
    network: 'linkedin',
    faIcon: 'linkedin',
    placeholder: 'example_user',
    urlEx: 'https://www.linkedin.com/in/'
  },
  {
    network: 'whatsapp',
    faIcon: 'whatsapp',
    placeholder: 'número a 10 dígitos',
    type: 'tel',
    maxLength: '10',
    pattern: '[0-9]{10}',
    regexp: /^\d{10}$/
  }
];

export const MAX_USER_ADDRESSES = 2;

export const PLATF_REDIR_TIMEOUT = 4000;
export const RESET_FORM = 4100;
