export const SET_PRODUCTS_CART = "SET_PRODUCTS_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REM_FROM_CART = "REM_FROM_CART";
export const INC_PRODUCT_QUANTITY_CART = "INC_PRODUCT_QUANTITY_CART";
export const DEC_PRODUCT_QUANTITY_CART = "DEC_PRODUCT_QUANTITY_CART";
export const SET_PRODUCT_QUANTITY = "SET_PRODUCT_QUANTITY";
export const UPDATE_PRODUCT_CART = "UPDATE_PRODUCT_CART";
export const SET_TOTAL_CART = "SET_TOTAL_CART";
export const RESET_CART = "RESET_CART";

export const add_to_cart = (prod) => ({
  type: ADD_TO_CART,
  prod
});

export const rem_from_cart = (prod_id) => ({
  type: REM_FROM_CART,
  prod_id
});

export const inc_product_quantity_cart = (prod_id, userBalance) => ({
  type: INC_PRODUCT_QUANTITY_CART,
  prod_id,
  userBalance
});

export const dec_product_quantity_cart = (prod_id) => ({
  type: DEC_PRODUCT_QUANTITY_CART,
  prod_id
});

export const update_product_cart = (prod_id, updated_product) => ({
  type: UPDATE_PRODUCT_CART,
  prod_id,
  updated_product
});

export const set_product_quantity=(prod_id,quantity)=>({
  type:SET_PRODUCT_QUANTITY,
  prod_id,
  quantity
})

export const set_total_cart = (total) => ({
  type: SET_TOTAL_CART,
  total
});

export const set_products_cart = (cartProducts) => ({
  type: SET_PRODUCTS_CART,
  cartProducts
});

export const reset_cart = () => ({
  type: RESET_CART
});
