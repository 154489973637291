import { takeLatest, put, call } from 'redux-saga/effects';
import { getAllProducts } from './product-sagas';
import * as actions from '../actions/checkout-actions';
import { add_toast } from '../actions/toasts-actions';
// import { check_balance_user } from "../actions/toasts-actions";
import { getStateLang } from './utils';
import { api_private, api_public } from '../../services';

function* postDataCheckout({ dataCheckout }) {
  yield put(actions.reset_checkout());
  yield put(actions.request_checkout());
  const lang = yield getStateLang();
  // const user = yield getLoginUser();
  try {
    const checkout = yield call(api_private.createOrder, dataCheckout);
    if (checkout.error) {
      yield put(actions.error_checkout(checkout.error));
      yield put(
        add_toast({
          error: true,
          text: checkout.error[lang] || checkout.error
        })
      );
    } else {
      yield put(actions.set_order_checkout(checkout.order));
      yield put(
        add_toast({
          error: false,
          text: checkout.message[lang]
        })
      );
      // local.saveState({});
      // yield put(actions.cancel_checkout());
      // yield put(check_balance_user(user._id));
      yield getAllProducts();
    }
  } catch (error) {
    yield put(actions.error_checkout(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_checkout());
  }
}

function* postDataPayment({ orderId, coupon }) {
  yield put(actions.reset_checkout());
  yield put(actions.request_checkout());
  const lang = yield getStateLang();
  try {
    const payment = yield call(api_public.postPaymentSession, {
      orderId,
      coupon
    });
    if (payment.error) {
      yield put(actions.error_checkout(payment.error));
      yield put(
        add_toast({
          error: true,
          text: payment.error[lang] || payment.error
        })
      );
    } else {
      yield put(actions.success_checkout(payment.message[lang]));
      yield put(
        add_toast({
          error: false,
          text: payment.message[lang]
        })
      );
      yield put(actions.set_checkout_session(payment.session));
    }
  } catch (error) {
    yield put(actions.error_checkout(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
  } finally {
    yield put(actions.cancel_checkout());
  }
}

export function* watchCheckout() {
  yield takeLatest(actions.START_CHECKOUT, postDataCheckout);
  yield takeLatest(actions.START_PAY, postDataPayment);
}
