import { Footer } from '../sections';
import { ConfiguratorCont } from '../../containers';

function ConfiguratorPage() {
  return (
    <>
      <ConfiguratorCont />
      <Footer />
    </>
  );
}

export default ConfiguratorPage;
