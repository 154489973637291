import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LABELS_ORDER, SHORT_ID } from '../../constants/platform';
import { LABELS_VERIFY_PAYMENT } from '../../constants/labels';
import { formatNmxn } from '../../utils';

import '../../style/order-checkout.css';

import { ReactComponent as StripeBadge } from '../../assets/img/utils/stripe/powered_by_stripe.svg';
function OrderCheckout({ startPay, orderCheckout, isFetching, lang }) {
  const { _id, products, total } = orderCheckout || {};
  let rolls = 0;
  for (let i = 0; i < products?.length; i++) {
    rolls += products[i].quantity;
  }
  const shippingCost = rolls <= 5 ? 500 : rolls <= 10 ? 200 : 0;
  const [coupon, setCoupon] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (orderCheckout) {
      console.log(orderCheckout.status);
      const orderStatus = orderCheckout?.status.filter(
        (e) => e.status === 'CANCELED'
      )?.[0];
      if (orderStatus) {
        history.push('/platform/orders', { replace: true });
      }
    }
  }, [orderCheckout]);

  const currency = useMemo(() => {
    if (!products?.length) {
      return null;
    }
    const currencies = new Map();
    products.forEach((el) => currencies.set(el?.product.currency));
    return [...currencies.keys()][0];
  }, [products]);
  const handleStartPayment = () => {
    startPay(_id, coupon);
  };
  if (!orderCheckout) {
    return null;
  }

  return (
    <Card className="mt-5">
      {isFetching ? (
        <div className="d-flex justify-content-center p-2">
          <FontAwesomeIcon size="2x" icon="cog" spin /> <br />
          <h3>{`${LABELS_VERIFY_PAYMENT.BEING_REDIRECTED[lang]}`}</h3>
        </div>
      ) : (
        <>
          <CardHeader>
            <h2>
              {`${LABELS_ORDER.THANK_YOU[lang]} `}{' '}
              <span className="text_orange">{`${_id.slice(-SHORT_ID)}`}</span>
            </h2>
          </CardHeader>
          <CardBody>
            <ListGroup flush>
              {products?.map(
                ({
                  product: {
                    short_name,
                    description,
                    price,
                    images = [],
                    _id,
                    currency
                  },
                  quantity
                }) => (
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-start"
                    key={_id}
                  >
                    <div className="product_img flex justify-content-center-align-items-center">
                      <img
                        className="img-fluid product_img"
                        src={
                          images[1] === undefined ? images[0] : images[1] || ''
                        }
                        alt={`product ${short_name}`}
                      />
                    </div>

                    <div className="d-flex flex-column flex-fill px-2">
                      <b>{short_name}</b>
                      <p>{description}</p>
                      <b>{`${price} ${currency} x ${quantity}  = ${formatNmxn(
                        quantity * price
                      )} ${currency}`}</b>
                    </div>
                  </ListGroupItem>
                )
              )}
            </ListGroup>
          </CardBody>
          <CardFooter className="d-flex flex-column">
            <div>
              <hr />
              <h5 className="d-flex justify-content-between">
                <p>Costo de envio</p>
                <p>{`${formatNmxn(shippingCost)} ${currency}`}</p>
              </h5>
              <hr />
            </div>
            <Row>
              <Col md={3}>
                <Input
                  type="text"
                  placeholder="Cupon de descuento"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-between align-items-center py-2">
              {' '}
              <h3 className="text_total">
                <b>{LABELS_ORDER.TOTAL[lang]}</b>&nbsp;
                <b>{`${formatNmxn(total)} ${currency}`}</b>
              </h3>{' '}
              <Button
                disabled={!orderCheckout}
                onClick={handleStartPayment}
                color="primary"
              >
                {LABELS_ORDER.PAY[lang]}
              </Button>
            </div>
            <div>
              <StripeBadge width="150px" />
            </div>
          </CardFooter>
        </>
      )}
    </Card>
  );
}

export default OrderCheckout;
