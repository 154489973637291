import { connect } from 'react-redux';
import { Checkout } from '../../components/forms';
import { start_add_address_user } from '../../redux/actions/user-actions';
import {
  start_checkout,
  cancel_checkout
} from '../../redux/actions/checkout-actions';
import { reset_cart,set_total_cart } from '../../redux/actions/cart-actions';
import { set_slide_content } from '../../redux/actions/platform-actions';
import { check_balance_user } from '../../redux/actions/user-actions';
import { local } from '../../services';
import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';

function CheckoutCont({
  set_slide_content,
  set_total_cart,
  start_add_address_user,
  cancel_checkout,
  start_checkout,
  // createdOrder,
  reset_cart,
  check_balance_user,
  user,
  ...rest
}) {
  const cancelCheckout = () => {
    cancel_checkout();
    set_slide_content(SLIDE_MENU_NAMES_CONTENT.CREATED_ORDER);
    reset_cart();
    local.saveState({});
  };
  const backToCart = () => {
    set_slide_content(SLIDE_MENU_NAMES_CONTENT.CART);
  };
  return (
    <Checkout
    set_total_cart={set_total_cart}
      startCheckout={start_checkout}
      cancelCheckout={cancelCheckout}
      startAddAddress={start_add_address_user}
      backToCart={backToCart}
      user={user}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  globalReducer: { lang },
  loginReducer: { user },
  userReducer: { isFetching: isFetchingAddress },
  checkoutReducer,
  cartReducer
}) => ({
  ...checkoutReducer,
  ...cartReducer,
  user,
  lang,
  isFetchingAddress
});

const mapActionsToProps = {
  set_total_cart,
  set_slide_content,
  start_add_address_user,
  cancel_checkout,
  start_checkout,
  reset_cart,
  check_balance_user
};

export default connect(mapStateToProps, mapActionsToProps)(CheckoutCont);
