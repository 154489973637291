import React, { Component, Fragment } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { AnimSend } from '../elements';
import '../../style/form.css';
import { LABELS_FORM_USER } from '../../constants/platform';
import { inputToDate } from '../../utils';
import data from '../../assets/bodym/sending.json';

export default class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progr: 0,
      localMessage: '',
      birthDate: '',
      showBirthday: false,
      firstname: '',
      lastname: '',
      telephone: '',
      email: '',
      imgForCrop: [],
      cropped_img: []
    };
  }
  componentDidUpdate = (prevProps) => {
    const { changedUser } = this.props;
    if (changedUser && prevProps.changedUser !== changedUser) {
      this.props.toggleChangedUser(changedUser);

      this.setState({
        progr: 100
      });
      setTimeout(() => {
        this.props.toggleFormModal(true);
      }, 700);
    }
  };
  getFiles = ({ target: { files } }) => {
    this.setState({ cropped_img: files });
  };
  loadCropImg = ({ target: { files } }) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ imgForCrop: reader.result });
      });
      reader.readAsDataURL(files[0]);
    }
  };
  getValue = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    this.setState({
      [name]: val
    });
  };
  endAnim = () => {
    this.props.cancelRequest();
  };
  startSend = (e) => {
    e.preventDefault();
    const {
      birthDate,
      firstname,
      lastname,
      telephone,
      showBirthday,
      cropped_img
    } = this.state;
    const data = {
      firstname,
      lastname,
      telephone
    };
    if (showBirthday) data.birthDate = inputToDate(birthDate);
    if (cropped_img.length) {
      let images = new FormData();
      for (let i = 0; i < cropped_img.length; i++) {
        images.append('images', cropped_img[i]);
      }
      this.props.updateUser(data, images);
    } else {
      this.props.updateUser(data);
    }
  };
  render() {
    const { isFetching, lang } = this.props;
    const { firstname, lastname } = this.state;
    return (
      <Form id="profile_form" onSubmit={this.startSend}>
        {isFetching ? (
          <Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <AnimSend data={data} endAnim={this.endAnim} />
            </Col>
          </Row>
        ) : (
          <Fragment>
            <FormGroup className="animated fadeInUp" row>
              <Label for="firstname" sm={2}>
                {LABELS_FORM_USER.FIRST_NAME[lang]}
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={firstname}
                  type="firstname"
                  name="firstname"
                  required
                  id="firstname"
                  placeholder={'Nombre(s)'}
                />
              </Col>
            </FormGroup>
            <FormGroup className="animated fadeInUp" row>
              <Label for="lastname" sm={2}>
                {LABELS_FORM_USER.LAST_NAME[lang]}
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={lastname}
                  type="lastname"
                  name="lastname"
                  id="lastname"
                  required
                  placeholder={'Apellidos'}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                block
                color="primary"
                disabled={isFetching}
                form="profile_form"
                type="submit"
              >
                Actualizar
              </Button>
            </FormGroup>
          </Fragment>
        )}
      </Form>
    );
  }
}
