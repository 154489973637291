import React from 'react';
import { Services } from '../landing/sections/services_page';
import { Footer } from '../../components/sections';

function ServicesPage() {
  return (
    <>
      <Services />
      <Footer />
    </>
  );
}

export default ServicesPage;
