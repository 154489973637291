import React from "react";
import { connect } from "react-redux";
import { ForgPassForm } from "../../components/forms";
import {
  start_forg_pass,
  cancel_login
} from "../../redux/actions/login-actions";

function ForgPassFormCont({ start_forg_pass, cancel_login, ...rest }) {
  return (
    <ForgPassForm
      startForgPass={start_forg_pass}
      cancelLogin={cancel_login}
      {...rest}
    />
  );
}

const mapStateToProps = ({ loginReducer, globalReducer: { lang } }) => ({
  ...loginReducer,
  lang
});

const mapActionsToProps = {
  start_forg_pass,
  cancel_login
};

export default connect(mapStateToProps, mapActionsToProps)(ForgPassFormCont);
