import { useMemo, useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  // Collapse,
  Table,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddressForm } from './';
import { RadioListItems } from './questTypes';
import { AnimSend } from '../elements';
import {
  LABELS_CHECKOUT
  // LABELS_PLATFORM,
  // MAX_USER_ADDRESSES
} from '../../constants/platform';
import { formatNmxn } from '../../utils';

import data from '../../assets/bodym/sending.json';

import '../../style/checkout.css';

const initialFormAddress = {
  city: '',
  line_1: '',
  line_2: '',
  postal_code: '',
  state: ''
};

function Checkout({
  set_total_cart,
  className,
  startCheckout,
  cancelCheckout,
  backToCart,
  lang,
  total,
  cartProducts,
  isFetching,
  startAddAddress,
  isFetchingAddress,
  user: { addresses = [], _id }
}) {
  const [selAddress, setSelAddress] = useState(null);
  const [formVals, setFormVals] = useState(initialFormAddress);
  const currency = useMemo(() => {
    if (!cartProducts.length) {
      return null;
    }
    const currencies = new Map();
    cartProducts.forEach((prod) => currencies.set(prod.currency));
    return [...currencies.keys()][0];
  }, [cartProducts]);
  useEffect(() => {
    if (addresses.length && selAddress === null) {
      setSelAddress(addresses[0]._id);
    }
  }, [addresses, selAddress]);

  const getValue = ({ target: { value, name } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  const handleSetAddress = ({ target: { value } }) => {
    setSelAddress(value);
  };
  const handleStartCheckout = () => {
    const products = cartProducts.reduce(
      (acc, curr) => [...acc, { product: curr._id, quantity: curr.quantity }],
      []
    );
    const newOrder = {
      products,
      address: selAddress
    };
    startCheckout(newOrder);
  };

  const endAnim = () => {
    cancelCheckout();
  };
  const onSubmitAddress = (e) => {
    e.preventDefault();
    startAddAddress(_id, {
      ...formVals
    });
  };
  let rolls = 0;
  for (let i = 0; i < cartProducts?.length; i++) {
    rolls += cartProducts[i].quantity;
  }

  const shippingCost = rolls <= 5 ? 500 : rolls <= 10 ? 200 : 0;

  const preTotal = () => {
    const prevT = total - shippingCost;
    set_total_cart(prevT);
    backToCart();
  };
  const addressOptions = addresses.map(
    ({ _id, line_1, state, city, postal_code }) => ({
      label: `${line_1}, ${state}, ${city}, ${postal_code}`,
      name: 'selAddress',
      value: _id
    })
  );
  const hasAddress = addresses.length > 0;
  //const contentShippable = cartProducts.some((p) => p.shippable);
  // const contentNotShippable = cartProducts.some((p) => !p.shippable);
  const cannotContinue = () => {
    if (selAddress) {
      return false;
    }
    return true;
  };
  return (
    <>
      {isFetching ? (
        <Container>
          <Row className="justify-content-center mt-5">
            <Col xs={12} sm={6}>
              <AnimSend data={data} endAnim={endAnim} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className={`${className} animated fadeInRight mt-3`}>
          <Row>
            <Col>
              <Table responsive striped>
                <tbody>
                  {cartProducts.map(
                    ({
                      shortname, //longname,
                      _id,
                      quantity,
                      price,
                      images = [],
                      currency
                    }) => (
                      <tr key={_id}>
                        <td className="align-middle">
                          <img
                            className="img-fluid checkout_img"
                            src={
                              images[1] === undefined ? images[0] : images[1]
                            }
                            alt={`product ${shortname}`}
                          />
                        </td>
                        <td className="align-middle">{shortname}</td>
                        <td className="align-middle">{`${price} x ${quantity}`}</td>
                        <td className="align-middle">
                          <b>{`${price * quantity} ${currency}`}</b>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {total > 0 && (
            <>
              <hr />
              <Row className="text-right">
                <Col>
                  <h5 className="d-flex justify-content-between">
                    <p> {LABELS_CHECKOUT.SHIPPING_COST[lang]}</p>
                    <p>{`${formatNmxn(shippingCost)} ${currency}`}</p>
                  </h5>
                </Col>
              </Row>
              <hr />
              <Row className="text-right">
                <Col>
                  <h3 className="d-flex justify-content-between">
                    <b>Total</b>
                    <b>{`${formatNmxn(total)} ${currency}`}</b>
                  </h3>
                </Col>
              </Row>
              <hr />
            </>
          )}
          <Row className="mt-2">
            <Col>
              <Button onClick={handleStartCheckout} color="primary" block>
                {`${LABELS_CHECKOUT.CREATE_ORDER[lang]}  `}
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Alert color="info">
                <FontAwesomeIcon icon="info" /> &nbsp;
                {LABELS_CHECKOUT.DISCLAIMER_ORDER[lang]}
              </Alert>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button onClick={preTotal} color="primary" outline block>
                {LABELS_CHECKOUT.EDIT_CART[lang]}
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Checkout;
