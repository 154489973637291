import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/category-actions';
import { add_toast } from '../actions/toasts-actions';
import { getStateLang, getLoginUser } from './utils';
import { api_public, api_private } from '../../services';

function* postCategory({ data }) {
  const user = yield getLoginUser();
  const lang = yield getStateLang();
  try {
    const created = yield call(api_private.createCategory, data);
    if (created.category) {
      yield put(
        add_toast({
          error: false,
          text: created.message[lang]
        })
      );
    } else if (created.error) {
      yield put(actions.error_category(created.error));
      yield put(
        add_toast({
          error: true,
          text: created.error[lang]
        })
      );
      yield put(actions.cancel_category());
    }
  } catch (error) {
    yield put(actions.error_category(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_category());
  }
}
function* getAllCategories({ categoryURL }) {
  yield put(actions.reset_category());
  yield put(actions.request_category());
  const lang = yield getStateLang();
  try {
    const response = yield call(api_public.getAllCategories, categoryURL || '');
    if (response.error) {
      yield put(actions.error_category(response.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: response.error[lang]
        })
      );
      yield put(actions.cancel_category());
    } else {
      yield put(actions.success_category(response.message[lang]));
      yield put(actions.set_all_categories(response.categories));
      yield put(actions.cancel_category());
    }
  } catch (error) {
    yield put(actions.error_category(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_category());
  }
}

export function* watchCategory() {
  yield takeLatest(actions.LIST_CATEGORIES, getAllCategories);
  yield takeLatest(actions.CREATE_CATEGORY, postCategory);
}
