import { all } from 'redux-saga/effects';
import { watchSignup } from './signup-sagas';
import { watchLogin } from './login-sagas';
import { watchUser } from './user-sagas';
import { watchProduct } from './product-sagas';
import { watchCheckout } from './checkout-sagas';
import { watchOrder } from './order-sagas';
import { watchAddress } from './address-sagas';
import { watchBrand } from './brand-sagas';
import { watchContact } from './contact-sagas';
import { watchCategory } from './category-sagas';

export function* greetingsSaga() {
  //    yield console.log('Hi there!');
  yield 1;
  // console.log('hi')
}

export default function* rootSaga() {
  console.log('redux saga setup ok');
  yield all([
    //add your sagas here:
    greetingsSaga(),
    watchSignup(),
    watchLogin(),
    watchUser(),
    watchProduct(),
    watchCheckout(),
    watchOrder(),
    watchAddress(),
    watchBrand(),
    watchContact(),
    watchCategory()
  ]);
}
