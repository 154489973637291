import React from "react";
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import // ProfileFormCont,
// BeCardFormCont,
// CompanyFormCont
"../../containers";
import { LABELS_PLATFORM } from "../../constants/platform";

const FormModal = ({
  lang,
  className,
  toggle,
  info = {},
  sel_form,
  children,
  form_modal
}) => {
  const { title = {}, notice } = info;
  return (
    <div>
      <Modal
        isOpen={form_modal}
        toggle={toggle}
        size="lg"
        backdrop="static"
        className={className}>
        <ModalHeader toggle={toggle}>{title[lang]}</ModalHeader>
        <ModalBody>
          {notice ? (
            <Alert color="warning" className="multiline">
              {notice[lang]}
            </Alert>
          ) : null}
          {children}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {LABELS_PLATFORM.CLOSE[lang]}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FormModal;
