import { connect } from 'react-redux';
import { ShippingAddress } from '../../components/forms';
import { start_add_address_user } from '../../redux/actions/user-actions';
import { set_slide_content } from '../../redux/actions/platform-actions';
import {
  start_update_order,
  set_select_order
} from '../../redux/actions/order-actions';

function ShippingAddressCont({
  start_update_order,
  set_slide_content,
  start_add_address_user,
  user,
  ...rest
}) {
  return (
    <ShippingAddress
      set_slide_content={set_slide_content}
      set_select_order={set_select_order}
      startUpdateOrder={start_update_order}
      startAddAddress={start_add_address_user}
      user={user}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  orderReducer: { selectOrder },
  globalReducer: { lang },
  loginReducer: { user },
  userReducer: { isFetching: isFetchingAddress }
}) => ({
  user,
  lang,
  isFetchingAddress,
  selectOrder
});

const mapActionsToProps = {
  set_select_order,
  start_update_order,
  set_slide_content,
  start_add_address_user
};

export default connect(mapStateToProps, mapActionsToProps)(ShippingAddressCont);
