import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Tooltip,Collapse,CardBody,Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoriesCloudCont } from '../../containers';

import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';

import espacio1 from '../../images/configuradorR/espacio/espacio1.png';
import espacio2 from '../../images/configuradorR/espacio/espacio2.png';
import espacio3 from '../../images/configuradorR/espacio/espacio3.png';

import '../../style/configuratorR.css';

const ADJUSTMENT_FACTOR = 200;

function ConfiguratorR({
  addToCart,
  allProducts,
  setSelectedProduct,
  setSlideContent,
  isFetching
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [currentSpace, setCurrentSpace] = useState(espacio1);
  const [currentOption, setCurrentOption] = useState(null);
  const [active, setActive] = useState(null);

  const handleActive = (i) => {
    setActive(i);
  };
  const handleAddToCart = () => {
    if (allProducts.length > 0) {
      addToCart(currentOption);
      setSlideContent(SLIDE_MENU_NAMES_CONTENT.CART);
    }
  };

  const handleShowCalc = () => {
    setSelectedProduct(currentOption);
    setSlideContent(SLIDE_MENU_NAMES_CONTENT.UNITS_CALCULATOR);
  };
  if (currentOption) {
    console.log(currentOption?.width.value);
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  return (
    <Container className="d-flex size_container pl-md-4 pl-0" fluid>
      <Row className="mt-3 p-0">
        <Col md={3} className="bg_cfg_aside mt-5 pt-5 pt-sm-2">
          <Col sm={12} className="my-3 ">
            <h1 className="text-center text-sm-left ">Configurador</h1>
            <p className="d-none d-sm-inline">Ordenar por colección</p>
            <CategoriesCloudCont className="mx-auto"/>
          </Col>
          <Col sm={12} className="d-flex flex-column justify-content-center">
            <div className="d-flex d-md-none justify-content-center mb-3">
              <Button onClick={toggling} className="bg-white text-dark" style={{ marginBottom: '0rem', }} >
                <p>
                  Colores <span className="text_orange">Selecciona un estilo</span><FontAwesomeIcon  className="ml-3" icon="angle-down" />
                </p>
              </Button>
            </div>
            <p className="d-md-flex d-none">
              Colores <span className="text_orange">Selecciona un estilo</span>
            </p>
            <div className="d-none d-md-flex optionsCont">
              <Row className="pb-5">
                {isFetching ? (
                  <Col className="text-center">
                    <FontAwesomeIcon spin icon="spinner" />
                  </Col>
                ) : (
                  allProducts?.map((el, i) => (
                    <Col key={i} xs={4}>
                      <img
                        alt=""
                        className={`img-fluid option mb-1 animate__animated animate__fadeIn ${
                          active === i ? 'active_item' : ''
                        }`}
                        title={el.short_name}
                        src={
                          el?.images[1] === undefined
                            ? el?.images[0]
                            : el?.images[1]
                        }
                        onClick={() => {
                          handleActive(i);
                          setCurrentOption(el);
                        }}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </div>
            <div className="d-md-none d-flex ">
              <Collapse isOpen={isOpen}>
                <Card>
                  <CardBody>
                    <div className="optionsCont">
                      <Row className="pb-5">
                        {isFetching ? (
                          <Col className="text-center">
                            <FontAwesomeIcon spin icon="spinner" />
                          </Col>
                        ) : (
                          allProducts?.map((el, i) => (
                            <Col key={i} xs={4}>
                              <img
                                alt=""
                                className={`img-fluid option mb-1 animate__animated animate__fadeIn ${
                                  active === i ? 'active_item' : ''
                                }`}
                                title={el.short_name}
                                src={
                                  el?.images[1] === undefined
                                    ? el?.images[0]
                                    : el?.images[1]
                                }
                                onClick={() => {
                                  handleActive(i);
                                  setCurrentOption(el);
                                }}
                              />
                            </Col>
                          ))
                        )}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          </Col>
          <Col sm={12} className="my-3 d-none d-sm-inline ">
            <p className="mx-3 cstm-txt">
              Cambiar escenario{' '}
              <span className="text_orange">Cambia tu estilo</span>{' '}
            </p>
            <Row className="d-flex flex-row justify-content-center px-4">
              <Col className="px-2">
                {' '}
                <img
                  alt="espacio configurador 1 sala con muebles y repisa"
                  src={espacio1}
                  onClick={() => {
                    setCurrentSpace(espacio1);
                  }}
                  className="img-fluid "
                />
              </Col>
              <Col className="px-2">
                {' '}
                <img
                  alt="espacio configurador 2 sala con pared central"
                  src={espacio2}
                  onClick={() => {
                    setCurrentSpace(espacio2);
                  }}
                  className="img-fluid "
                />
              </Col>
              <Col className="px-2">
                {' '}
                <img
                  alt="espacio configurador 3 recamara con cama"
                  src={espacio3}
                  onClick={() => {
                    setCurrentSpace(espacio3);
                  }}
                  className="img-fluid "
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="d-none d-md-flex flex-column">
            <Row className="pl-3 pb-3">¿No sabes cuántos rollos comprar?</Row>
            <Row className="justify-content-around" noGutters>
              <Col className="px-1">
                <Button
                  className="rounded-pill btn_black"
                  onClick={handleShowCalc}
                  block
                  disabled={!currentOption}
                >
                  Calcular rollos
                </Button>
              </Col>

              <Col className="">
                {' '}
                <span id={!currentOption ? 'Tooltip' : ''}>
                  <Button
                    onClick={handleAddToCart}
                    color="primary"
                    disabled={!currentOption}
                    block
                  >
                    Añadir al carrito
                  </Button>
                </span>
                {!currentOption ? (
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="Tooltip"
                    toggle={toggle}
                  >
                    Selecciona un tapiz.
                  </Tooltip>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Col>

        <Col sm={9} className="p-0 aux-1024 " style={{minHeight:" 50vh"}}>
          <img src={currentSpace} alt="" className="p-0 size_space index-5" />
          {currentOption && (
            <div
              alt=""
              className="index-3 size_option"
              style={{
                backgroundImage: `url(${
                  currentOption?.images[1] === undefined
                    ? currentOption?.images[0]
                    : currentOption?.images[1]
                })`,
                backgroundRepeat: 'repeat',
                backgroundSize: `${
                  currentOption?.width.value * ADJUSTMENT_FACTOR
                }px ${currentOption?.width.value * ADJUSTMENT_FACTOR}px`
              }}
            />
          )}
        </Col>
          <div className='relative mx-auto'>  
            <p> * La imagen del configurador es únicamente ilustrativa.</p>
          </div>

        <Col sm={12} className="my-3 d-sm-none">
          <Col sm={12} className="text-center">
            <p>
              Cambiar escenario
              <br />
              <span className="text_orange">Cambia tu estilo</span>
            </p>
            <Row className="d-flex flex-row justify-content-center">
              <Col>
                <img
                  alt="espacio configurador 1 sala con muebles y repisa"
                  src={espacio1}
                  onClick={() => {
                    setCurrentSpace(espacio1);
                  }}
                  className="img-fluid "
                />
              </Col>
              <Col>
                <img
                  alt="espacio configurador 2 sala con pared central"
                  src={espacio2}
                  onClick={() => {
                    setCurrentSpace(espacio2);
                  }}
                  className="img-fluid "
                />
              </Col>
              <Col>
                <img
                  alt="espacio configurador 3 recamara con cama"
                  src={espacio3}
                  onClick={() => {
                    setCurrentSpace(espacio3);
                  }}
                  className="img-fluid "
                />
              </Col>
            </Row>
          </Col>
        </Col>
        <Col className="d-flex d-md-none justify-content-center ">
            <Row noGutters>
              <Col sm={6}>
                <Button
                  className="rounded-pill btn_black mb-3"
                  onClick={handleShowCalc}
                  block
                  disabled={!currentOption}
                >
                  Calcular rollos
                </Button>
              </Col>

              <Col sm={6} className="">
                {' '}
                <span id={!currentOption ? 'Tooltip' : ''}>
                  <Button
                    onClick={handleAddToCart}
                    color="primary"
                    disabled={!currentOption}
                    block
                  >
                    Añadir al carrito
                  </Button>
                </span>
                {!currentOption ? (
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="Tooltip"
                    toggle={toggle}
                  >
                    Selecciona un tapiz.
                  </Tooltip>
                ) : null}
              </Col>
            </Row>
          </Col>
      </Row>
    </Container>
  );
}

export default ConfiguratorR;
