import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tapiz from '../../images/tapizC.png';

function Calculator() {
  return (
    <>
      <Container
        className="d-flex bg-light flex-column justify-content-center pt-5 px-5"
        fluid>
        <Row className="mt-5 py-3">
          <Link to="/" className="text-dark">
            <FontAwesomeIcon
              size="lg"
              className="text-dark mr-1"
              icon={['fas', 'chevron-left']}
            />
            Regresar
          </Link>
        </Row>
        <Row>
          <Col md={1}>
            <Row>1</Row>
            <Row>2</Row>
            <Row>3</Row>
          </Col>
          <Col md={11} className="bg-white">
            <Row>
              <Col md={6}>
                <img src={tapiz} alt="" className="img-fluid" />
              </Col>
              <Col md={6} className="pt-5">
                <Row>
                  <Col>
                    <h4 className="text_golden font-weight-bold">
                      {' '}
                      Winter vol.3
                    </h4>{' '}
                    <br />
                    <h2 className="font-weight-bold">$2,500.00</h2> <br />
                  </Col>
                  <Col>
                    <Button className="rounded-pill  mb-1 px-3 btn-dark ">
                      Agregar al carrito
                    </Button>
                    <Button className="rounded-pill btn-primary px-5">
                      Comprar
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>
                      Familia: <span className="font-weight-light">Deco</span>
                    </b>{' '}
                    <br />
                    <b>
                      Repetición:{' '}
                      <span className="font-weight-light">SIN REPETICION</span>
                    </b>{' '}
                    <br />
                    <b>
                      Diseñador:{' '}
                      <span className="font-weight-light">
                        Yorrk Wallcovering
                      </span>
                    </b>{' '}
                    <br />
                    <b>
                      Entrega:{' '}
                      <span className="font-weight-light">3 A 4 SEMANAS</span>
                    </b>{' '}
                    <br />
                    <b>
                      Color: <span className="font-weight-light">Gris</span>
                    </b>{' '}
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Col md={2}> Cantidad:</Col>
                  <Col md={2}>
                    {' '}
                    <Input type="number" name="number" id="number" />
                  </Col>
                  <Col md={3}>52 CM X 10 M</Col>
                </Row>
                <Row>
                  <Col>Formato: repetición cada 2 metros </Col>
                  <Col>
                    <Row>
                      {' '}
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" id="checkbox2" /> Horizontal
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      {' '}
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" id="checkbox2" /> Vertical
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="bg_main_blue" md={8}>
                <Row className="pt-3">
                  <Col className="pt-2">
                    <h1 className="font-weight-bold">Calculadora</h1> <br />
                    <h5 className="text-white">
                      ¿Cuántos rollos necesito?
                    </h5>{' '}
                    <br />
                    <p className="text-white">
                      Ingresa la altura y el ancho de tu muro
                    </p>
                  </Col>
                  <Col>
                    <Row>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" id="checkbox2" />
                          <p className="text-white">
                            {' '}
                            Repetición de papel tapiz, formato de metro
                          </p>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="number" name="number" id="number" />
                          <Label>Ancho /mts</Label>
                        </FormGroup>
                      </Col>
                      <Col className="text-center" md={1}>
                        X
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Input type="number" name="number" id="number" />
                          <Label>Altura/mts</Label>
                        </FormGroup>
                      </Col>
                      <Col md={1}>=</Col>
                      <Col className="text-white" md={2}>
                        2
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="bg_light_pink text-center pt-3">
                <h1 className="font-weight-bold text-white">
                  Prueba nuestro configurador
                </h1>{' '}
                <br />
                <Button className="rounded-pill  mb-1 px-3 btn-dark ">
                  Configurador
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Calculator;
