import React from "react";
import { Card, CardText, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Verify = ({ isFetching, message, error }) => {
  return (
    <Card className="text-center" body>
      {isFetching ? (
        <CardBody>
          <CardText>
            <FontAwesomeIcon spin icon="cog" />
          </CardText>
        </CardBody>
      ) : (
        <CardText>
          {message || error ? (
            <>
              {message && (
                <>
                  <FontAwesomeIcon spin icon="cog" />
                  &nbsp; Espera mientras te redirecciono
                </>
              )}
              {error && <p>{error}</p>}
            </>
          ) : null}
        </CardText>
      )}
    </Card>
  );
};

export default Verify;
