import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Col,
  Input,
  Label,
  Card,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce } from "../../../hooks";
import { DEBOUNCE_TIME, PRODUCT_KIND } from "../../../constants";
import { LABELS_PLATFORM, LABELS_FILTER } from "../../../constants/platform";

const setQuerySearch = (queryURL) => {
  const s = queryURL.get("search");
  if (!s) {
    queryURL.append("search", true);
  }
  return s;
};

function ProductsFilter({
  toggleFilter,
  startSearch,
  lang,
  isFetching,
  filtered
}) {
  const [search, setSearch] = useState("");
  const [formVals, setFormVals] = useState({
    by_delivery: "",
    by_value: "",
    by_product: ""
  });
  const searchDebounce = useDebounce(search, DEBOUNCE_TIME.SEARCH);
  const { by_value, by_delivery, by_product } = formVals;
  useEffect(() => {
    if (
      searchDebounce !== "" ||
      by_value !== "" ||
      by_delivery !== "" ||
      by_product !== ""
    ) {
      if (!filtered) toggleFilter();
    } else {
      if (filtered) toggleFilter();
    }
  }, [
    by_delivery,
    by_product,
    by_value,
    filtered,
    searchDebounce,
    toggleFilter
  ]);
  useEffect(() => {
    if (searchDebounce !== "") {
      let queryURL = new URLSearchParams();
      // En caso de que queramos que aparezcan Regalos sin stock
      // queryURL.append("all_products", true);
      queryURL.append("search", true);
      queryURL.append("search_text", searchDebounce);
      startSearch(`?${queryURL.toString()}`);
    }
  }, [searchDebounce, startSearch]);
  useEffect(() => {
    if (by_value !== "" || by_product !== "" || by_delivery !== "") {
      let queryURL = new URLSearchParams();
      if (by_value !== "") {
        setQuerySearch(queryURL);
        queryURL.append("price_ordered", by_value);
      }
      if (by_product !== "") {
        setQuerySearch(queryURL);
        queryURL.append("by_product", by_product);
      }
      if (by_delivery !== "") {
        setQuerySearch(queryURL);
        queryURL.append("shippable", by_delivery);
      }
      startSearch(`?${queryURL.toString()}`);
    }
  }, [by_delivery, by_product, by_value, startSearch]);
  const getSearch = ({ target: { value } }) => {
    setSearch(value);
  };
  const getValue = ({ target: { value, name } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  return (
    <Container>
      <Row>
        <Col>
          <Card body className="mb-4">
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup className="m-1 m-lg-auto" row>
                <Col xs={12} lg={4} className="mb-1">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        {isFetching ? (
                          <FontAwesomeIcon className="" spin icon={"spinner"} />
                        ) : (
                          <FontAwesomeIcon
                            className="animated fadeInUp"
                            icon={"search"}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={getSearch}
                      value={search}
                      placeholder="Buscar"
                    />
                  </InputGroup>
                </Col>
              </FormGroup>

              <FormGroup className="m-1 m-lg-auto" row>
                <Col className="d-none d-lg-block">
                  <Label>{LABELS_FILTER.PRODUCT_TYPE[lang]}</Label>
                </Col>
                <Col className="d-none d-lg-block">
                  <Label>{LABELS_FILTER.PRICE[lang]}</Label>
                </Col>
                <Col className="d-none d-lg-block">
                  <Label>{LABELS_FILTER.SHIPPING[lang]}</Label>
                </Col>
              </FormGroup>
              <FormGroup className="m-1 m-lg-auto" row>
                <Col xs={12} lg={4} className="mb-1">
                  <Input
                    name="by_product"
                    value={by_product}
                    onChange={getValue}
                    type="select">
                    <option value="">
                      {LABELS_PLATFORM.EMPTY_OPTIONS[lang]}
                    </option>
                    {PRODUCT_KIND.map(({ value, label }, i) => (
                      <option key={i} value={value}>
                        {label[lang]}
                      </option>
                    ))}
                  </Input>
                </Col>

                <Col xs={12} lg={4} className="mb-1">
                  <Input
                    name="by_value"
                    value={by_value}
                    onChange={getValue}
                    type="select">
                    <option value="">
                      {LABELS_PLATFORM.EMPTY_OPTIONS[lang]}
                    </option>
                    <option value={1}>{LABELS_PLATFORM.ASCENDENT[lang]}</option>
                    <option value={-1}>
                      {LABELS_PLATFORM.DESCENDENT[lang]}
                    </option>
                  </Input>
                </Col>

                <Col xs={12} lg={4} className="mb-1">
                  <Input
                    name="by_delivery"
                    value={by_delivery}
                    onChange={getValue}
                    type="select">
                    <option value="">
                      {LABELS_PLATFORM.EMPTY_OPTIONS[lang]}
                    </option>
                    <option value={true}>
                      {LABELS_PLATFORM.SHIPPABLE[lang]}
                    </option>
                    <option value={false}>
                      {LABELS_PLATFORM.NOT_SHIPPABLE[lang]}
                    </option>
                  </Input>
                </Col>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductsFilter;
