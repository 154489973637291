import {
  REQUEST_PRODUCT,
  REQUEST_ONE_PRODUCT,
  CANCEL_PRODUCT,
  CANCEL_ONE_PRODUCT,
  SUCCESS_PRODUCT,
  ERROR_PRODUCT,
  RESET_PRODUCT,
  SET_ALL_PRODUCTS,
  SET_FILTERED_PRODUCTS,
  SET_SELECTED_PRODUCT,
  // GET_PRODUCT_DELETE,
  // PRODUCT_DELETE_ERROR,
  // PRODUCT_DELETE_SUCCESS,
} from '../actions/product-actions';

export const productReducer = (
  state = {
    isFetching: false,
    isFetchingOne: false,
    allProducts: null,
    filteredProducts: [],
    message: null,
    error: null, 
    selectedProduct:null
    //  delete: null,
  },
  action
) => {
  const {
    type,
    isFetching,
    isFetchingOne,
    allProducts,
    //_id,
    message,
    error,
    filteredProducts, 
    selectedProduct
  } = action;

  switch (type) {
    case REQUEST_PRODUCT:
      return { ...state, isFetching };
    case REQUEST_ONE_PRODUCT:
      return { ...state, isFetchingOne };
    case CANCEL_ONE_PRODUCT:
      return { ...state, isFetchingOne };
    case CANCEL_PRODUCT:
      return { ...state, isFetching };
    case SUCCESS_PRODUCT:
      return { ...state, message };
    case ERROR_PRODUCT:
      return { ...state, error };
    case SET_ALL_PRODUCTS:
      return { ...state, allProducts };
    case SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct };
    case SET_FILTERED_PRODUCTS:
      return { ...state, filteredProducts };
    case RESET_PRODUCT:
      return {
        ...state,
        message,
        error,
        filteredProducts,
        isFetching,
        isFetchingOne
      };
    // case GET_PRODUCT_DELETE:
    //   return { ...state, isFetchingOne, _id };
    // case PRODUCT_DELETE_SUCCESS:
    //   return { ...state, message };
    // case PRODUCT_DELETE_ERROR:
    //   return { ...state, error };
    default:
      return { ...state };
  }
};
