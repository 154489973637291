import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Cart } from '../../components/elements';
import {
  rem_from_cart,
  set_total_cart,
  inc_product_quantity_cart,
  dec_product_quantity_cart,
  set_products_cart
} from '../../redux/actions/cart-actions';
import {
  list_products,
  set_selected_product
} from '../../redux/actions/product-actions';

import { set_slide_content } from '../../redux/actions/platform-actions';
import { local } from '../../services';
import { LOCAL_MESSAGES } from '../../constants';
import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';

function CartCont({
  rem_from_cart,
  set_total_cart,
  set_slide_content,
  inc_product_quantity_cart,
  dec_product_quantity_cart,
  set_products_cart,
  set_selected_product,
  cartProducts,
  total,
  lang,
  user,
  ...rest
}) {
  useEffect(() => {
    local.saveState({ cartProducts, total });
  }, [cartProducts, total]);
  const incProductQuantity = (id) => {
    const { price, stock, quantity } = cartProducts.find((g) => g._id === id);
    const nextTotal = total + price;
    const nextStock = stock - (quantity + 1);

    inc_product_quantity_cart(id);
    set_total_cart(nextTotal);
  };
  const setCheckout = () => {
    if (!user) {
      set_slide_content(SLIDE_MENU_NAMES_CONTENT.SIGNUP);
    } else {
      set_slide_content(SLIDE_MENU_NAMES_CONTENT.CHECKOUT);

      let rolls = 0;
      for (let i = 0; i < cartProducts?.length; i++) {
        rolls += cartProducts[i].quantity;
      }

      const shippingCost = rolls <= 5 ? 500 : rolls <= 10 ? 200 : 0;
      const finishTotal = total + shippingCost;
      set_total_cart(finishTotal);
    }
  };
  const setContinueBuying = () => {
    set_slide_content('');
  };
  return (
    <Cart
      remProductFromCart={rem_from_cart}
      setSlideContent={set_slide_content}
      incProductQuantity={incProductQuantity}
      decProductQuantity={dec_product_quantity_cart}
      setCheckout={setCheckout}
      setContinueBuying={setContinueBuying}
      cartProducts={cartProducts}
      setSelectedProduct={set_selected_product}
      lang={lang}
      total={total}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  cartReducer,
  globalReducer: { lang },
  loginReducer: { user }
}) => ({
  ...cartReducer,
  user,
  lang
});

const mapActionsToProps = {
  set_slide_content,
  rem_from_cart,
  inc_product_quantity_cart,
  dec_product_quantity_cart,
  set_total_cart,
  set_products_cart,
  set_selected_product,
  set_slide_content
};

export default connect(mapStateToProps, mapActionsToProps)(CartCont);
