import {
  REQUEST_USER,
  CANCEL_USER,
  SUCCESS_USER,
  ERROR_USER,
  RESET_USER,
  SET_BALANCE_USER,
  SET_ALL_USERS,
  RESET_ONE_USER,
  SET_SUGGESTED_USERS,
  SET_STATUS_USER
} from '../actions/user-actions';

export const userReducer = (
  state = {
    statusUser: '',
    isFetching: false,
    isFetchingOne: false,
    message: false,
    error: false,
    sel_user: {},
    userBalance: false,
    allUsers: [],
    suggestedUsers: []
  },
  action
) => {
  const {
    type,
    statusUser,
    isFetching,
    error,
    message,
    userBalance,
    allUsers,
    isFetchingOne,
    suggestedUsers
  } = action;
  switch (type) {
    case REQUEST_USER:
      return { ...state, isFetching };
    case CANCEL_USER:
      return { ...state, isFetching };
    case SUCCESS_USER:
      return { ...state, message };
    case ERROR_USER:
      return { ...state, error };
    case RESET_USER:
      return { ...state, message, error, isFetching };
    case RESET_ONE_USER:
      return { ...state, message, error, isFetchingOne };
    case SET_BALANCE_USER:
      return { ...state, userBalance };
    case SET_SUGGESTED_USERS:
      return { ...state, suggestedUsers };

    case SET_ALL_USERS:
      return { ...state, allUsers };
    case SET_STATUS_USER:
      return { ...state, statusUser };
    default:
      return { ...state };
  }
};
