import React from "react";
import { connect } from "react-redux";
import { ContactFormLanding } from "../../components/forms";
import {start_post_contact} from '../../redux/actions/contact-actions'; 

function ContactFormLandingCont({ start_post_contact, ...rest }) {
  return <ContactFormLanding startPostContact = { start_post_contact } {...rest} />;
}

const mapStateToProps = ({ globalReducer: { lang }, contactReducer }) => ({
  lang,
  ...contactReducer
});

const mapActionsToProps={start_post_contact}

export default connect(mapStateToProps,mapActionsToProps)(ContactFormLandingCont);
