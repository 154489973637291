export const START_LOGIN = "START_LOGIN";
export const START_FORG_PASS = "START_FORG_PASS";
export const START_VERIFY_TOKEN = "START_VERIFY_TOKEN";
export const START_RESET_PASS = "START_RESET_PASS";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const CANCEL_LOGIN = "CANCEL_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const SET_TOKEN_LOGIN = "SET_TOKEN_LOGIN";
export const RENEW_TOKEN = "RENEW_TOKEN";
export const RESET_LOGIN = "RESET_LOGIN";
export const LOG_OUT = "LOG_OUT";

export const start_login = (dataLogin) => ({
  type: START_LOGIN,
  dataLogin
});

export const start_forg_pass = (dataForgP) => ({
  type: START_FORG_PASS,
  dataForgP
});

export const start_reset_pass = (dataResetP) => ({
  type: START_RESET_PASS,
  dataResetP
});

export const start_verify_token = (reset_pass_token) => ({
  type: START_VERIFY_TOKEN,
  reset_pass_token
});

export const request_login = () => ({
  type: REQUEST_LOGIN,
  isFetching: true
});

export const cancel_login = () => ({
  type: CANCEL_LOGIN,
  isFetching: false
});

export const success_login = (message) => ({
  type: SUCCESS_LOGIN,
  message
});

export const reset_login = () => ({
  type: RESET_LOGIN,
  message: false,
  error: false
});

export const error_login = (error) => ({
  type: ERROR_LOGIN,
  error
});

export const set_user_login = (user) => ({
  type: SET_USER_LOGIN,
  user
});

export const set_token_login = (token) => ({
  type: SET_TOKEN_LOGIN,
  token
});

export const renew_token = () => ({
  type: RENEW_TOKEN
});

export const log_out = () => ({
  type: LOG_OUT,
  user: false,
  token: false
});
