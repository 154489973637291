import { useState, useEffect, useMemo } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Badge
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToMeters, formatNmxn } from '../../utils';
import { LABELS_FORM_CALCULATOR } from '../../constants/labels';
import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';
import '../../style/units-calculator.css';

const initialForm = {
  width: 0,
  height: 0
};

function UnitsCalculator({
  selectedProduct,
  handleAddProduct,
  setSlideContent,
  lang = 'es'
}) {
  const [formVals, setFormVals] = useState({ ...initialForm });
  const [rolls, setRolls] = useState(null);
  const handleShowCart = () => {
    handleAddProduct(selectedProduct, rolls, totalPriceRolls);
    setSlideContent(SLIDE_MENU_NAMES_CONTENT.CART);
  };
  const setValue = ({ target: { name, value } }) => {
    setFormVals({ ...formVals, [name]: value });
  };
  const { width, height } = formVals;

  useEffect(() => {
    const calcRolls = () => {
      const repFactor = selectedProduct?.repetition_factor[0] || {};
      console.log({ repFactor });
      const stripeLength =
        +height + convertToMeters(repFactor.value, repFactor.unit);
      console.log({ height });
      console.log({ stripeLength });

      const stripesNumber =
        +width /
        convertToMeters(
          selectedProduct?.width?.value,
          selectedProduct?.width?.unit
        );

      console.log({ stripesNumber });
      return (
        (stripesNumber * stripeLength) /
        convertToMeters(
          selectedProduct?.height?.value,
          selectedProduct?.height?.unit
        )
      );
    };
    if (width > 0 && height > 0) {
      const calcR = Math.ceil(calcRolls());
      if (!rolls) {
        setRolls(calcR);
      } else if (rolls !== calcR) {
        setRolls(calcR);
      }
    }
  }, [rolls, width, height, setRolls, selectedProduct]);

  const {
    short_name,
    description,
    repetition_factor,
    height: prodHeight,
    width: prodWidth,
    price,
    currency,
    colors,
    images = []
  } = selectedProduct;

  const totalPriceRolls = useMemo(() => {
    if (!rolls || !price) {
      return null;
    }
    return rolls * price;
  }, [rolls, price]);

  return (
    <Container className="mt-3 calculator-cont d-flex flex-column">
      <Row className="mb-5">
        {selectedProduct ? (
          <>
            <Col md={6} xs={12}>
              <Row>
                <Col>
                  <img
                    className="img-fluid"
                    src={images[0]}
                    alt="prod preview"
                  />
                </Col>
              </Row>

              <br />
              <Row>
                <Col>
                  {colors?.map((el) => (
                    <Badge color="dark" className="mr-1" key={el}>
                      {el}
                    </Badge>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col md={6} xs={12}>
              <h4 className="text_golden bold-title">{short_name}</h4>
              <h3 className="bold-title">{`$${formatNmxn(
                price
              )} ${currency}`}</h3>
              <p className="multiline">
                <b>{LABELS_FORM_CALCULATOR.DESCRIPTION[lang]}:</b> {description}{' '}
                <br />
                <b>{LABELS_FORM_CALCULATOR.WIDTH[lang]}:</b> {prodWidth.value}{' '}
                {prodWidth.unit} <br />
                <b>{LABELS_FORM_CALCULATOR.HEIGHT[lang]}:</b> {prodHeight.value}{' '}
                {prodHeight.unit} <br />
                <b>{LABELS_FORM_CALCULATOR.REP_FACTOR[lang]}:</b>{' '}
                {repetition_factor[0].value} {repetition_factor[0].unit} <br />
              </p>
            </Col>
          </>
        ) : null}
      </Row>
      <Row>
        <Col>
          <Form>
            <FormGroup row>
              <Col xs={3}>
                <Input
                  onChange={setValue}
                  value={width}
                  type="number"
                  min={0}
                  name="width"
                  id="width"
                  placeholder={LABELS_FORM_CALCULATOR.HEIGHT_PLACEHOLDER[lang]}
                />{' '}
                <br />
                <Label for="width">
                  {LABELS_FORM_CALCULATOR.WIDTH[lang]}&nbsp;
                  {LABELS_FORM_CALCULATOR.IN_METERS[lang]}
                </Label>
              </Col>
              <Col className="d-flex justify-content-center" xs={1}>
                <FontAwesomeIcon className="mt-2" size="lg" icon="times" />
              </Col>
              <Col xs={3}>
                <Input
                  onChange={setValue}
                  value={height}
                  type="number"
                  min={0}
                  name="height"
                  id="height"
                  placeholder={LABELS_FORM_CALCULATOR.WIDTH_PLACEHOLDER[lang]}
                />
                <br />
                <Label for="height">
                  {LABELS_FORM_CALCULATOR.HEIGHT[lang]}&nbsp;
                  {LABELS_FORM_CALCULATOR.IN_METERS[lang]}
                </Label>
              </Col>
              <Col className="d-flex justify-content-center" xs={1}>
                <FontAwesomeIcon className="mt-2" size="lg" icon="equals" />
              </Col>
              <Col>
                <h3 className="font-weight-bold">
                  {rolls
                    ? `${rolls} ${LABELS_FORM_CALCULATOR.ROLLS[lang]}`
                    : null}{' '}
                </h3>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-end mt-auto ">
        <Col xs={6}>
          <h3 className>
            {totalPriceRolls
              ? `$${formatNmxn(totalPriceRolls)} ${currency} `
              : '00.00'}
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-end  mt-2">
        <Col>
          <Button
            onClick={handleShowCart}
            disabled={!rolls}
            block
            color="primary"
            className="mb-5"
          >{`${LABELS_FORM_CALCULATOR.ADD_TO_CART[lang]} ${
            rolls ? rolls : ''
          } ${rolls ? `${LABELS_FORM_CALCULATOR.ROLLS[lang]}` : ''}`}</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default UnitsCalculator;
