import React from 'react';
import { connect } from 'react-redux';
import { UserProfile } from '../../components/platform/elements';
import { FORM_NAMES } from '../../constants/platform';
import { post_data_user, cancel_user } from '../../redux/actions/user-actions';
import { set_sel_form } from '../../redux/actions/platform-actions';
import { set_sel_address } from '../../redux/actions/address-actions';

function UserProfileCont({
  set_sel_form,
  set_sel_address,
  post_data_user,
  cancel_user,
  user,
  ...rest
}) {
  const fillProfile = () => {
    set_sel_form(FORM_NAMES.PROFILE_UPDATE);
  };
  const editAddress = (idAddress) => {
    set_sel_form(FORM_NAMES.EDIT_ADDRESS);
    set_sel_address(idAddress);
  };
  return (
    <>
      {user?._id ? (
        <UserProfile
          user={user}
          updateUser={post_data_user}
          fillProfile={fillProfile}
          cancelRequest={cancel_user}
          setSelAddress={editAddress}
          // toggleChangedUser={this.props.toggle_changed_user}
          // cancelRequest={this.props.cancel_request}
          {...rest}
        />
      ) : null}
    </>
  );
}

const mapStateToProps = ({ loginReducer, globalReducer: { lang } }) => ({
  ...loginReducer,
  lang
});

const mapActionsToProps = {
  post_data_user,
  set_sel_form,
  set_sel_address,
  cancel_user
};

export default connect(mapStateToProps, mapActionsToProps)(UserProfileCont);
