import { takeLatest, put, call, select } from 'redux-saga/effects';
import * as actions from '../../redux/actions/user-actions';
import { set_user_login } from '../../redux/actions/login-actions';
import { add_toast } from '../../redux/actions/toasts-actions';
import { set_all_orders } from '../actions/order-actions';
import { api_private, api_public } from '../../services';
import { getStateLang, getLoginUser } from './utils';

function* putUpdateUser({ dataUser, images }) {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const user = yield select(({ loginReducer: { user } }) => user);
  const lang = yield select(({ globalReducer: { lang } }) => lang);
  try {
    if (images) {
      const upload = yield call(api_private.uploadImages, images);
      if (upload.image_urls && upload.image_urls.length) {
        let dataImg = {
          ...dataUser,
          profile_image: upload.image_urls[0]
        };
        const updated = yield call(api_private.updateUser, dataImg, user._id);
        if (updated.user) {
          yield put(
            add_toast({
              error: false,
              text: updated.message[lang]
            })
          );
          yield put(set_user_login(updated.user));
        }
      }
    } else {
      const updated = yield call(api_private.updateUser, dataUser, user._id);
      if (updated.user._id) {
        yield put(
          add_toast({
            error: false,
            text: updated.message[lang]
          })
        );
        yield put(set_user_login(updated.user));
      }
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* postAddAddressUser({ user_id, dataAddress }) {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const lang = yield getStateLang();
  try {
    const updated = yield call(
      api_private.addUserAddress,
      user_id,
      dataAddress
    );
    if (updated.error) {
      yield put(actions.error_user(updated.error));
      yield put(
        add_toast({
          error: true,
          text: updated.error[lang]
        })
      );
      yield put(actions.cancel_user());
    } else {
      yield put(set_user_login(updated.user));
      yield put(
        add_toast({
          error: false,
          text: updated.message[lang]
        })
      );
      yield put(actions.cancel_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* addPointsUser({ user_id, dataPoints }) {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const lang = yield getStateLang();
  try {
    const balance = yield call(api_private.addCreditUser, user_id, dataPoints);
    if (balance.error) {
      yield put(actions.error_user(balance.error));
      yield put(
        add_toast({
          error: true,
          text: balance.error[lang] || balance.error
        })
      );
      yield put(actions.cancel_user());
    } else {
      yield put(
        add_toast({
          error: false,
          text: balance.message[lang]
        })
      );
      // yield put(actions.set_user_balance(balance.balance));
      yield put(actions.cancel_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_user());
  }
}

function* getUserBalance({ user_id }) {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const lang = yield getStateLang();
  try {
    const balance = yield call(api_private.checkUserBalance, user_id);
    if (balance.error) {
      yield put(actions.error_user(balance.error));
      yield put(
        add_toast({
          error: true,
          text: balance.error[lang]
        })
      );
      yield put(actions.cancel_user());
    } else {
      yield put(actions.set_user_balance(balance.balance));
      yield put(actions.cancel_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* getAllUsers() {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const lang = yield getStateLang();
  try {
    const allUsers = yield call(api_private.getAllUsers);
    if (allUsers.error) {
      yield put(actions.error_user(allUsers.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: allUsers.error[lang]
        })
      );
      yield put(actions.cancel_user());
    } else {
      yield put(actions.success_user(allUsers.message[lang]));
      yield put(actions.set_all_users(allUsers.users));
      // yield put(
      //   add_toast({
      //     error: false,
      //     text: allUsers.message[lang]
      //   })
      // );
      yield put(actions.cancel_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* postVerifyUser({ dataVerify }) {
  yield put(actions.reset_one_user());
  yield put(actions.request_one_user());
  const lang = yield getStateLang();
  try {
    const verified = yield call(api_public.postVerify, dataVerify);
    if (verified.error) {
      yield put(actions.error_user(verified.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: verified.error[lang]
        })
      );
      yield put(actions.cancel_one_user());
    } else {
      yield put(actions.success_user(verified.message[lang]));
      yield getAllUsers();
      yield put(
        add_toast({
          error: false,
          text: verified.message[lang]
        })
      );
      yield put(actions.cancel_one_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* postDeactivateUser({ dataDeactivate }) {
  yield put(actions.reset_one_user());
  yield put(actions.request_one_user());
  const lang = yield getStateLang();
  try {
    const verified = yield call(api_private.postDeactivateUser, dataDeactivate);
    if (verified.error) {
      yield put(actions.error_user(verified.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: verified.error[lang]
        })
      );
      yield put(actions.cancel_one_user());
    } else {
      yield put(actions.success_user(verified.message[lang]));
      yield getAllUsers();
      yield put(
        add_toast({
          error: false,
          text: verified.message[lang]
        })
      );
      yield put(actions.cancel_one_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* postSearchUsers({ searchURL }) {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const lang = yield getStateLang();
  try {
    const search = yield call(api_private.getSearchUsers, searchURL);
    if (search.error) {
      yield put(actions.error_user(search.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: search.error[lang]
        })
      );
      yield put(actions.cancel_user());
    } else {
      yield put(actions.error_user(search.message[lang]));
      yield put(actions.set_suggested_users(search.users));
      yield put(actions.cancel_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

export function* getInfoUser() {
  yield put(actions.reset_user());
  yield put(actions.request_user());
  const lang = yield getStateLang();
  const user = yield getLoginUser();
  try {
    const userInfo = yield call(
      api_private.getOneUser,
      user._id,
      '?usage_info=true'
    );
    if (userInfo.error) {
      yield put(actions.error_user(userInfo.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: userInfo.error[lang]
        })
      );
      yield put(actions.cancel_user());
    } else {
      yield put(actions.success_user(userInfo.message[lang]));
      yield put(set_user_login(userInfo.user));
      yield put(
        add_toast({
          error: false,
          text: userInfo.message[lang]
        })
      );
      yield put(actions.set_status_user(userInfo.status));
      yield put(actions.cancel_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_user());
  }
}

function* getUsageUser({ user_id }) {
  yield put(actions.reset_one_user());
  yield put(actions.request_one_user());
  const lang = yield getStateLang();
  try {
    const userInfo = yield call(
      api_private.getOneUser,
      user_id,
      '?usage_info=true'
    );
    if (userInfo.error) {
      yield put(actions.error_user(userInfo.error[lang]));
      yield put(
        add_toast({
          error: true,
          text: userInfo.error[lang]
        })
      );
      yield put(actions.cancel_one_user());
    } else {
      yield put(actions.success_user(userInfo.message[lang]));
      yield put(actions.set_all_users([userInfo.user]));
      const orders = userInfo.user?.orders;
      if (orders && orders.length) {
        yield put(set_all_orders(orders));
      }
      yield put(
        add_toast({
          error: false,
          text: userInfo.message[lang]
        })
      );
      yield put(actions.set_status_user(userInfo.status));
      yield put(actions.cancel_one_user());
    }
  } catch (error) {
    yield put(actions.error_user(error));
    yield put(
      add_toast({
        error: true,
        text: error
      })
    );
    yield put(actions.cancel_one_user());
  }
}

export function* watchUser() {
  yield takeLatest(actions.START_UPDATE_USER, putUpdateUser);
  yield takeLatest(actions.LIST_USERS, getAllUsers);
  yield takeLatest(actions.START_GET_BALANCE_USER, getUserBalance);
  yield takeLatest(actions.START_ADD_ADDRESSS_USER, postAddAddressUser);
  yield takeLatest(actions.START_ACTIVATE_USER, postVerifyUser);
  yield takeLatest(actions.START_DEACTIVATE_USER, postDeactivateUser);
  yield takeLatest(actions.START_SEARCH_USERS, postSearchUsers);
  yield takeLatest(actions.START_ADD_POINTS_USER, addPointsUser);
  yield takeLatest(actions.START_GET_USAGE_USER, getUsageUser);
}
