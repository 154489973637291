import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  // CardHeader,
  // CardTitle,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce } from "../../../hooks";
// import { LABELS_PLATFORM } from "../../../constants/platform";
import {
  DEBOUNCE_TIME,
  PAGINATION_ITEMS,
  PAGINATION_LINKS
} from "../../../constants";

const searchEnabled = ["full_name", "telephone"];

function UsageTable({ allCreditCertificates, lang, admin, manager }) {
  const [search, getSearch] = useState("");
  const handleGetSearch = ({ target: { value } }) => {
    getSearch(value);
  };
  const [pagination, setPagination] = useState(0);
  const [paginIndex, setPagIndex] = useState(0);
  const searchDebounce = useDebounce(search, DEBOUNCE_TIME.SEARCH);
  const pageCount = Math.ceil(allCreditCertificates.length / PAGINATION_ITEMS);
  useEffect(() => {
    setPagIndex(0);
  }, [searchDebounce]);
  const pagSect = Math.ceil(pageCount / PAGINATION_LINKS);

  const decPag = () => {
    const nextIndex = paginIndex - 1;
    if (nextIndex >= 0) setPagIndex(nextIndex);
  };

  const incPag = () => {
    const nextIndex = paginIndex + 1;
    if (nextIndex < pagSect) setPagIndex(nextIndex);
  };
  const sortFunction = (a, b) => {
    const date1 = new Date(b.createdAt);
    const date2 = new Date(a.createdAt);
    console.log({ date1 });
    console.log({ date2 });
    return new Date(b.createdAt) - new Date(a.createdAt);
  };
  const filterFunction = (el) => {
    if (searchDebounce !== "") {
      return Object.entries(el)
        .filter(([key]) => searchEnabled.indexOf(key) > -1)
        .some(([key, val]) => {
          switch (typeof val) {
            case "number":
              return (
                val
                  .toString()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(search.toLowerCase()) !== -1
              );
            case "string":
              return (
                val
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(search.toLowerCase()) !== -1
              );
            default:
              return true;
          }
        });
    } else {
      return true;
    }
  };
  return (
    <Row className="my-3">
      <Col>
        <Card>
          {/* <CardHeader>
            <CardTitle tag="h3">{LABELS_PLATFORM.POINTS_USAGE[lang]}</CardTitle>
          </CardHeader> */}
          <CardBody className="">
            {admin ? (
              <Row>
                <Col xs={12} md={6}>
                  <InputGroup className="">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon="search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={handleGetSearch}
                      value={search}
                      disabled={!allCreditCertificates.length}
                      placeholder="búsqueda"
                    />
                  </InputGroup>
                </Col>
              </Row>
            ) : null}

            <Row className="my-3">
              <Col>
                <Table responsive striped>
                  <thead>
                    {admin ? (
                      <tr>
                        <th>Puntos añadidos</th>
                        <th>Fecha</th>
                        <th>Distribuidor</th>
                        <th>Teléfono</th>
                        <th>Cliente</th>
                      </tr>
                    ) : manager ? (
                      <tr>
                        <th>Puntos añadidos</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Teléfono</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>Puntos añadidos</th>
                        <th>Fecha</th>
                        <th>Distribuidor</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {allCreditCertificates
                      .sort(sortFunction)
                      .filter(filterFunction)
                      .slice(
                        pagination * PAGINATION_ITEMS,
                        (pagination + 1) * PAGINATION_ITEMS
                      )
                      .map(
                        (
                          {
                            balance,
                            createdAt,
                            full_name,
                            telephone,
                            added_by
                          },
                          i
                        ) => (
                          <tr key={i}>
                            {admin ? (
                              <>
                                <td>{balance}</td>
                                <td>
                                  {new Date(createdAt).toLocaleString("es-MX")}
                                </td>
                                <td>
                                  {added_by
                                    ? `${added_by.firstname} ${added_by.lastname}`
                                    : ""}
                                </td>
                                <td>{telephone}</td>
                                <td>{full_name}</td>
                              </>
                            ) : manager ? (
                              <>
                                <td>{balance}</td>
                                <td>
                                  {new Date(createdAt).toLocaleString("es-MX")}
                                </td>
                                <td>{full_name}</td>
                                <td>{telephone}</td>
                              </>
                            ) : (
                              <>
                                <td>{balance}</td>
                                <td>
                                  {new Date(createdAt).toLocaleString("es-MX")}
                                </td>
                                <td>
                                  {added_by
                                    ? `${added_by.firstname} ${added_by.lastname}`
                                    : ""}
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {admin ? (
              <Row className="justify-content-center">
                <Pagination>
                  {pageCount > PAGINATION_LINKS && (
                    <PaginationItem disabled={paginIndex === 0}>
                      <PaginationLink first onClick={decPag} />
                    </PaginationItem>
                  )}
                  {[...Array(pageCount).keys()]
                    .slice(
                      paginIndex * PAGINATION_LINKS,
                      (paginIndex + 1) * PAGINATION_LINKS
                    )
                    .map((el, i) => (
                      <PaginationItem active={el === pagination} key={i}>
                        <PaginationLink
                          onClick={() => setPagination(parseInt(el, 10))}>
                          {parseInt(el, 10) + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  {pageCount > PAGINATION_LINKS && (
                    <PaginationItem disabled={paginIndex === pagSect - 1}>
                      <PaginationLink onClick={incPag} last />
                    </PaginationItem>
                  )}
                </Pagination>
              </Row>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default UsageTable;
