import React from "react";
import { Col } from "reactstrap";
// import {CalendarSmCont} from '../../containers';

function SecondaryBar({ className, children }) {
  return (
    <Col xs={12} md={2} className={`${className}`}>
      {children}
    </Col>
  );
}

export default SecondaryBar;
