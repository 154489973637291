import { useMemo } from 'react';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Table,
  Alert
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LABELS_ORDER, SHORT_ID } from '../../constants/platform';
import { formatNmxn } from '../../utils';
import '../../style/order.css';

function CreatedOrder({ createdOrder, lang }) {
  const { _id, products, total } = createdOrder;
  let rolls = 0;
  for (let i = 0; i < products?.length; i++) {
    rolls += products[i].quantity;
  }

  const shippingCost = rolls <= 5 ? 500 : rolls <= 10 ? 200 : 0;
  console.log(createdOrder);
  const currency = useMemo(() => {
    if (!products?.length) {
      return null;
    }
    const currencies = new Map();
    products.forEach((el) => currencies.set(el?.product.currency));
    return [...currencies.keys()][0];
  }, [products]);
  if (!createdOrder)
    return (
      <Container>
        <Row className="my-3">
          <Col className="text-center">
            <FontAwesomeIcon icon="cog" spin />
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container>
      <Row>
        <Col>
          <Card className="order_card mt-3">
            <CardHeader>
              <Row>
                <Col>
                  <p>{`${LABELS_ORDER.ORDER[lang]} ${_id.slice(-SHORT_ID)}`}</p>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <p>
                    <b>{`${LABELS_ORDER.LATEST_STATUS[lang]}: ${
                      status.slice(-1)[0].status
                    }`}</b>
                  </p>
                </Col>
              </Row> */}
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">{LABELS_ORDER.IMAGE[lang]}</th>
                    <th scope="col">{LABELS_ORDER.PRODUCT[lang]}</th>
                    <th scope="col">{LABELS_ORDER.SUBTOTAL[lang]}</th>
                    <th scope="col">{LABELS_ORDER.TOTAL[lang]}</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map(
                    (
                      { product: { short_name, price, images = [] }, quantity },
                      i
                    ) => (
                      <tr key={i}>
                        <td className="align-middle">
                          <img
                            className="img-fluid checkout_img"
                            src={
                              images[1] === undefined ? images[0] : images[1]
                            }
                            alt={`product ${short_name}`}
                          />
                        </td>
                        <td className="align-middle">{short_name}</td>
                        <td className="align-middle">{`${price} x ${quantity}`}</td>
                        <td className="align-middle">
                          <b>{`${formatNmxn(quantity * price)} ${currency}`}</b>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <hr />
              <Row className="text-right">
                <Col>
                  <h5 className="d-flex justify-content-between">
                    <p>Costo de envio </p>
                    <p>{`${formatNmxn(shippingCost)} ${currency}`}</p>
                  </h5>
                </Col>
              </Row>
              <hr />
              <h3 className="d-flex justify-content-between">
                <b>{LABELS_ORDER.TOTAL[lang]}</b>
                <b>{`${formatNmxn(total)} ${currency}`}</b>
              </h3>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Alert color="warning">{LABELS_ORDER.CREATED_ORDER[lang]}</Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default CreatedOrder;
