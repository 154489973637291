import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const ToolTipLoyalty = ({labelText, content, id}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  function isURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
  
  return (
    <div>
      <p className="m-0"><span style={{textDecoration: "underline", color:"blue"}} href="#" id={`tool-${id}`}>{labelText}</span></p>
      <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target={`tool-${id}`} toggle={toggle}>
        {isURL(content) 
          ? 
            <a
              style={{textDecoration: "underline", color:"white"}}
              href={content}
              target="_blank"
              rel="noopener noreferrer">
              {content}
            </a>
          : content}
      </Tooltip>
    </div>
  );
}

export default ToolTipLoyalty;