import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Progress } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SendButton,
  InputSimple,
  InputOptions,
  WelcomeM,
  //CardChoices,
  SummaryEl
} from './questTypes';
import '../../style/utils.css';
import '../../style/form.css';

const duration = 500;
export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAnswers: false,
      progr: 0,
      show: true,
      currentQ: 0,
      showMessage: false,
      apiMsg: '',
      // Questions

      name: '',
      lastname: '',
      state: '',
      email: '',
      interest: '',
      comments: '',
      company: '',

      // possible Q
      possibleQ: [
        {
          required: false,
          elType: 'WelcomeM',
          message: {
            es: 'Para ponerte en contacto da clic en "Siguiente"',
            en: 'In order to get in touch, please press "Next"'
          },
          small: {
            es: 'Puedes pasar a la siguiente pregunta presionando tab',
            en: 'You can move to the next question by pressing tab'
          },
          className: 'text-center'
        },
        {
          required: true,
          autoFocus: true,
          label: {
            es: 'Nombre',
            en: 'Name'
          },
          type: 'text',
          elType: 'InputSimple',
          forL: 'name',
          id: 'name',
          name: 'name',
          longQ: { es: 'Sólo nombre sin apellidos', en: 'Name only' },
          icon: 'user-astronaut',
          sizeSM: [4, 8]
        },
        {
          required: false,
          autoFocus: true,
          label: { es: 'Apellidos', en: 'Lastname' },
          type: 'text',
          elType: 'InputSimple',
          forL: 'lastname',
          id: 'lastname',
          name: 'lastname',
          longQ: { es: 'Sólo apellidos', en: 'Only lastname' },
          icon: 'user-astronaut',
          sizeSM: [4, 8]
        },
        {
          required: false,
          autoFocus: true,
          label: { es: 'Interés', en: 'Interest' },
          type: 'select',
          elType: 'InputOptions',
          forL: 'interest',
          id: 'interest',
          name: 'interest',
          longQ: { es: 'Interés', en: 'Interest' },
          options: [
            { es: 'General' },
            { es: 'Servicios' },
            { es: 'Pisos laminados e ingeniería' },
            { es: 'Alfombras' },
            { es: 'Persianas cortinas y toldos' },
            { es: 'Pergolados' },
            { es: 'Deck' },
            { es: 'Pisos vinílicos y homogéneos' },
            { es: 'Pasto sintético y muros verdes' },
            { es: 'Papel tapiz' }
          ],
          icon: 'building',
          sizeSM: [4, 8]
        },
        {
          autoFocus: true,
          required: true,
          label: { es: 'Correo ', en: 'Email' },
          type: 'email',
          elType: 'InputSimple',
          forL: 'email',
          id: 'email',
          name: 'email',
          invalidM: {
            es: 'Ingresa un correo válido',
            en: 'Input a valid email'
          },
          //eslint-disable-next-line
          regExp: /@/,
          valid: true,
          invalid: true,
          longQ: { es: 'Correo', en: 'Electronic mail' },
          icon: 'at',
          sizeSM: [4, 8]
        },
        // {
        //   required: false,
        //   autoFocus: true,
        //   label: { es: 'Empresa', en: 'Company' },
        //   type: 'text',
        //   elType: 'InputSimple',
        //   forL: 'company',
        //   id: 'company',
        //   name: 'company',
        //   longQ: { es: 'Si aplica', en: 'If applicable' },
        //   icon: 'building',
        //   sizeSM: [4, 8]
        // },
        // {
        //   autoFocus: true,
        //   label: { es: 'País y estado', en: 'Country and State' },
        //   type: 'text',
        //   elType: 'InputSimple',
        //   forL: 'state',
        //   id: 'state',
        //   name: 'state',
        //   longQ: {
        //     es: 'En el que opera la empresa',
        //     en: 'In which the company operates'
        //   },
        //   icon: 'building',
        //   sizeSM: [4, 8]
        // },
        {
          autoFocus: true,
          label: { es: 'Comentarios', en: 'Comments' },
          type: 'textarea',
          elType: 'InputSimple',
          forL: 'comments',
          id: 'comments',
          name: 'comments',
          longQ: { es: 'Sólo si aplica', en: 'If applicable' },
          icon: 'comment',
          sizeSM: [4, 8]
        }
      ]
    };
  }
  componentDidMount = () => {
    // this.switcher = setInterval(()=>{
    //   this.setState({
    //     show:!this.state.show
    //   })
    // }, 1200)
    if (process.env.REACT_APP_PREFILLED_DATA) {
      this.setState({
        nombre: 'Zinedine',
        apellidos: 'Zidane',
        estado: 'Puebla',
        email: 'connection@n12.mx',
        // interes:'',
        comentarios: 'commmmentaaaaarios'
      });
    }
  };
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevState.currentQ !== this.state.currentQ) {
      this.setState(
        {
          show: true
        },
        this.toggleShow
      );
    }
    // if(prevProps.today_format!==this.props.today_format){
    //   const {possibleQ} = this.state
    //   let rango=[`${this.props.today_format}`,'']
    //   let newPQ = [...possibleQ.slice(0,2),{...possibleQ[2],rango},...possibleQ.slice(3)]
    //   this.setState({
    //     possibleQ:newPQ
    //   })
    // }
  };
  componentWillUnmount = () => {
    // clearTimeout(this.switcher)
  };
  toggleShow = () => {
    setTimeout(() => {
      this.setState({
        show: !this.setState.show
      });
    }, duration);
  };
  getValue = (e, add, min) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (add === true) {
      value = value + 1;
    } else if (add === false) {
      value = value - 1;
      if (min && value <= min - 1) value = min;
    }
    this.setState({
      [name]: value
    });
  };
  setProgress = () => {
    const { currentQ, possibleQ } = this.state;
    let progr = (currentQ * 100) / (possibleQ.length - 1);
    if (currentQ === 0) progr = 0;
    if (currentQ === possibleQ.length) progr = 100;
    this.setState({
      progr
    });
  };
  keyHandler = (e) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      this.changePossibleQ(true, e);
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  };
  changePossibleQ = (d, e) => {
    const { currentQ, possibleQ } = this.state;
    if (e) e.preventDefault();
    let valid = true;
    if (
      possibleQ[currentQ].required &&
      this.state[possibleQ[currentQ].name] === ''
    ) {
      valid = false;
    } else if (!possibleQ[currentQ].required) {
      valid = true;
    }
    let index = currentQ;
    if (d && valid) {
      if (index !== possibleQ.length - 1) {
        index++;
        this.setState(
          {
            currentQ: index,
            show: !this.state.show
          },
          this.setProgress
        );
      }
    } else if (!d) {
      if (index === 0) index = possibleQ.length;
      index--;
      this.setState(
        {
          currentQ: index,
          show: !this.state.show
        },
        this.setProgress
      );
    }
  };
  setPossibleQ = (x) => {
    this.setState({
      currentQ: x,
      show: !this.state.show
    });
  };
  // renderQuestions=()=>{
  //   const {possibleQ,currentQ,show} = this.state,
  //         {longQ} = possibleQ[currentQ]
  //   return(
  //     <CSSTransition classNames="question"
  //                    key={currentQ}
  //                    in={show}
  //                    onExited={this.toggleShow}
  //                    unmountOnExit
  //                    timeout={300}>
  //       <h3 className="mainQ">{longQ}</h3>
  //      </CSSTransition>
  //   )
  // }
  startSend = () => {
    const { name, lastname, email, interest, comments } = this.state;
    const info = {
      name: `${name} ${lastname}`,
      email,
      interest,
      comments
    };
    console.log({ info });
    this.props?.startSend(info);

    this.setState({
      currentQ: 0,
      name: '',
      lastname: '',
      state: '',
      email: '',
      interest: '',
      comments: '',
      company: ''
    });

    // for (let val of formData.entries()){
    // console.log(`ORA!`)
    // console.log(`${val[0]} ${val[1]}`)
    // }
    // console.log(formData.entries())
  };
  renderProgress = () => {
    const { possibleQ, currentQ } = this.state;
    const progrQ = possibleQ.slice(0, currentQ);
    let progrV = 100 / possibleQ.length;
    return (
      <Progress multi>
        {progrQ.map((el, i) => (
          <Progress value={progrV} bar>
            {this.state[el.name]}
          </Progress>
        ))}
      </Progress>
    );
  };
  renderInputs = () => {
    const { lang = 'es' } = this.props;
    const { possibleQ, currentQ, show, choicesDays, ...rest } = this.state;
    const { name, elType, rango } = possibleQ[currentQ];
    return (
      <CSSTransition
        classNames="question"
        key={currentQ}
        in={show}
        onExited={this.toggleShow}
        unmountOnExit
        timeout={duration}
      >
        <div>
          {elType === 'InputSimple' && (
            <InputSimple
              getValue={this.getValue}
              value={this.state[name]}
              currentQ={currentQ}
              rango={rango}
              lang={lang}
              charact={possibleQ[currentQ]}
            />
          )}
          {elType === 'SendButton' && (
            <SendButton
              setSend={this.startSend}
              lang={lang}
              charact={possibleQ[currentQ]}
            />
          )}
          {elType === 'InputOptions' && (
            <InputOptions
              getValue={this.getValue}
              value={this.state[name]}
              currentQ={currentQ}
              rango={rango}
              lang={lang}
              charact={possibleQ[currentQ]}
            />
          )}
          {elType === 'WelcomeM' && (
            <WelcomeM lang={lang} info={possibleQ[currentQ]} />
          )}

          {elType === 'SummaryEl' && (
            <SummaryEl
              setPossibleQ={this.setPossibleQ}
              title="Resumen"
              {...rest}
            />
          )}
        </div>
      </CSSTransition>
    );
  };
  render() {
    const { message, isFetching } = this.props;
    const { progr, possibleQ, currentQ } = this.state;
    return (
      <Container
        onKeyUp={this.keyHandler}
        // eslint-disable-next-line
        autoFocus
        className="d-flex flex-column justify-content-center py-5"
      >
        <Row className="my-4 mainQuestions align-items-center justify-content-center">
          <Col>
            {message === null ? (
              <div>
                {isFetching ? (
                  <Progress animated value={100} />
                ) : (
                  <Form onSubmit={(event) => this.changePossibleQ(true, event)}>
                    {this.renderInputs()}
                  </Form>
                )}
              </div>
            ) : (
              <Row>
                <Col>
                  {message} <br />
                  <small className="form-text text-muted">
                    <br /> Revise también su bandeja de correo no deseado{' '}
                  </small>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="d-flex justify-content-center">
            <Button
              onClick={() => this.changePossibleQ(false)}
              disabled={currentQ === 0}
              outline
              className="animated slideInLeft rounded-pill mr-2 btn-outline-primary"
            >
              <FontAwesomeIcon icon="chevron-left" /> Anterior
            </Button>
            {currentQ === possibleQ.length - 1 ? (
              <Button
                onClick={this.startSend}
                outline
                disabled={isFetching || message !== null}
                className="animated rounded-pill slideInRight btn-primary"
              >
                Enviar &nbsp;
                <FontAwesomeIcon icon="paper-plane" />
              </Button>
            ) : (
              <Button
                onClick={() => this.changePossibleQ(true)}
                disabled={currentQ === possibleQ.length - 1}
                outline
                className="animated slideInRight rounded-pill btn-primary"
              >
                Siguiente&nbsp;
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Progress value={progr} />
          </Col>
        </Row>
      </Container>
    );
  }
}
