import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CartNav } from '../../components/elements';
import { set_slide_content } from '../../redux/actions/platform-actions';
import {
  set_total_cart,
  set_products_cart
} from '../../redux/actions/cart-actions';
import { local } from '../../services';
import { SLIDE_MENU_NAMES_CONTENT } from '../../constants/platform';

function CartNavCont({
  set_total_cart,
  set_products_cart,
  set_slide_content,
  ...rest
}) {
  useEffect(() => {
    const cartState = local.loadState();
    if (cartState?.cartProducts?.length) {
      set_total_cart(cartState.total);
      set_products_cart(cartState.cartProducts);
    }
  }, [set_products_cart, set_total_cart]);
  const showCart = () => {
    set_slide_content(SLIDE_MENU_NAMES_CONTENT.CART);
  };
  return <CartNav showCart={showCart} {...rest} />;
}

const mapStateToProps = ({ cartReducer }) => ({
  ...cartReducer,
  productTotalItems: cartReducer.cartProducts.length
});

const mapActionsToProps = {
  set_slide_content,
  set_total_cart,
  set_products_cart
};

export default connect(mapStateToProps, mapActionsToProps)(CartNavCont);
