import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Form, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../style/contact.css';
function ContactLanding({
  isFetching,
  startPostContact,
  resetContact,
  message: globalMessage
}) {
  const [showMessage, setShowMessage] = useState(false);
  const [formVals, setFormVals] = useState({
    name: '',
    email: '',
    comments: '',
    interest: ''
  });

  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...formVals };
   /*  // console.log({ data })
    const formData = new FormData();
    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    } */
    startPostContact(data);
  };

  const toggleMessage = () => {
    if (showMessage) {
      resetContact();
    } 
    setShowMessage(!showMessage);
  };

  useEffect(() => {
    if (globalMessage) {
      setShowMessage(false);
      setFormVals({
        name: '',
        email: '',
        text: ''
      });
    }
  }, [globalMessage, setShowMessage]);

  const { name, email, comments } = formVals;

  return (
    <>
      <Container className="d-md-flex d-none bg_mid_dark" fluid></Container>
      <Container
        className=" bg_white d-flex flex-row justify-content-center"
        fluid
      >
        <Row className="align-items-center my-md-5 my-0 ">
          <Col md={4} sm={12} className="order-md-1 order-2 d-flex ">
            <div className="">
              <iframe
                className="custom_map shadow-lg"
                title="NeoCarpets"
                frameBorder="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9989516280048!2d-98.2527893838422!3d19.06378370964033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc6ef690c293d%3A0x1d0226f547beeaf8!2sNeo%20Carpets%20-%20Decoracion!5e0!3m2!1ses!2smx!4v1617137446350!5m2!1ses!2smx"
              ></iframe>
            </div>
          </Col>

          <Col
            className="order-md-2 order-1 mr-md-auto custom_box bg_pink mt-md-5 mt-0"
            md={8}
            sm={12}
          >
            <div className=" content_move">
              <p className="text-md-left text-center ml-md-5 ml-0  my-lg-3  text-white pt-lg-3 pt-2">
                Calz. Zavaleta 704 A Col. Santa Cruz <br />
                Buenavista Puebla, Pue C.P 72154 <br />
                Tel. (222) 5828031 <br />
              </p>

              <h1 className="mt-lg-4  text-md-left text-center ml-md-5 ml-0 font-weight-bold text-white">
                Contáctanos
              </h1>
              <h5 className="text-md-left text-center ml-md-5 ml-0 text-white">
                Escríbenos un mensaje
              </h5>
              {isFetching ? (
                <Container>
                  <Row className="justify-content-center text-center">
                    <Col>
                      <FontAwesomeIcon
                        size="3x"
                        className="text-white"
                        spin
                        icon="spinner"
                      />
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Form id="contact" onSubmit={handleSubmit} fluid>
                  <div className="small_mobile mt-4 mx-5">
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      id="name"
                      placeholder="Nombre"
                      className="tablettext text-center text-md-left"
                      onChange={getValue}
                    />

                    <Input
                      type="email"
                      name="email"
                      value={email}
                      id="email"
                      placeholder="Correo electrónico"
                      className="tablettext text-center text-md-left  my-2 my-md-3"
                      onChange={getValue}
                    />

                    <Input
                      type="textarea"
                      name="comments"
                      value={comments}
                      id="Mensaje"
                      placeholder="Mensaje"
                      className="input-element tablettext text-center text-md-left "
                      onChange={getValue}
                    />
                  </div>

                  <div className="d-flex justify-content-md-start justify-content-center my-3 my-md-0">
                    <Button
                      id="contact"
                      className="mt-2 px-5 py-3 text-center mt-lg-3 ml-md-5 rounded-pill"
                      color="primary"
                      type="submit"
                    >
                      <h6 className=" mb-0">Enviar el mensaje</h6>
                    </Button>
                  </div>
                </Form>
              )}
              {globalMessage && (
                <Alert
                  isOpen={showMessage}
                  toggle={toggleMessage}
                  className="mt-1"
                  color="primary"
                >
                  El mensaje ha sido enviado con éxito.
                </Alert>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactLanding;
