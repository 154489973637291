import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ProductsTable } from '../../components/platform/tables';
import { set_sel_form } from '../../redux/actions/platform-actions';

// Aquí voy a importar las acciones de redux
import {
  start_update_product,
  list_products
} from '../../redux/actions/product-actions';

function ProductsTableCont({
  set_sel_form,
  start_update_product,
  list_products,
  formEditAction,
  allProducts,
  error,
  ...rest
}) {
  useEffect(() => {
    if (!error && allProducts === null) {
      list_products('?all_products=true');
    }
  }, [allProducts, error, list_products]);

  const editAction = (id) => {
    set_sel_form(formEditAction, id);
  };

  return (
    <ProductsTable
      startUpdateProduct={start_update_product}
      allProducts={allProducts}
      editAction={editAction}
      {...rest}
    />
  );
}

const mapStateToProps = ({ globalReducer: { lang }, productReducer }) => ({
  lang,
  ...productReducer
});

const mapActionsToProps = {
  set_sel_form,
  start_update_product,
  list_products
};

export default connect(mapStateToProps, mapActionsToProps)(ProductsTableCont);
