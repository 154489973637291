import React from "react";
import { connect } from "react-redux";
import { EditableAddress } from "../../components/forms";
import { update_address } from "../../redux/actions/address-actions";

function EditableAddressCont({ update_address, ...rest }) {
  return <EditableAddress updateAddress={update_address} {...rest} />;
}

const mapStateToProps = ({
  globalReducer: { lang },
  addressReducer,
  addressReducer: { selAddress },
  loginReducer: { user }
}) => ({
  lang,
  ...addressReducer,
  address: user.addresses.find((el) => el._id === selAddress) || {}
});

const mapActionsToProps = {
  update_address
};

export default connect(mapStateToProps, mapActionsToProps)(EditableAddressCont);
