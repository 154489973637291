import { useEffect } from 'react';
import { connect } from 'react-redux';
import { OrderCheckout } from '../../components/elements/';
import { useParams } from 'react-router';
import { start_one_order_checkout } from '../../redux/actions/order-actions';
import { start_pay } from '../../redux/actions/checkout-actions';

function OrderCheckoutCont({
  start_one_order_checkout,
  start_pay,
  checkoutSession,
  orderCheckout,
  ...rest
}) {

  const { orderId } = useParams();
  useEffect(() => {
    if (checkoutSession?.url) {
      window.location.href = checkoutSession.url;
    }
  }, [checkoutSession]);
  useEffect(() => {
    if (!orderId) {
      return;
    }
    if (orderCheckout === null) {
      start_one_order_checkout(orderId);
    }
  }, [orderId, orderCheckout, start_one_order_checkout]);
  return (
    <OrderCheckout
      startPay={start_pay}
      orderCheckout={orderCheckout}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  checkoutReducer,
  orderReducer: { orderCheckout },
  globalReducer: { lang }
}) => ({
  ...checkoutReducer,
  orderCheckout,

  lang
});

const mapActionsToProps = { start_one_order_checkout, start_pay};

export default connect(mapStateToProps, mapActionsToProps)(OrderCheckoutCont);
