import {
  REQUEST_SIGNUP,
  CANCEL_SIGNUP,
  SUCCESS_SIGNUP,
  ERROR_SIGNUP,
  RESET_SIGNUP,
  SET_STOP_FETCH_ON_END,
  SET_PROCEED_TO_VERIFY,
  SET_PROCEED_TO_CHECKOUT
} from '../actions/signup-actions';

export const signupReducer = (
  state = {
    isFetching: false,
    message: false,
    error: false,
    stopFetchOnEnd: false,
    proceedToVerify: false,
    proceedToCheckout: false
  },
  action
) => {
  const {
    type,
    isFetching,
    message,
    error,
    stopFetchOnEnd,
    proceedToVerify,
    proceedToCheckout
  } = action;
  switch (type) {
    case REQUEST_SIGNUP:
      return { ...state, isFetching };
    case CANCEL_SIGNUP:
      return { ...state, isFetching };
    case SUCCESS_SIGNUP:
      return { ...state, message };
    case ERROR_SIGNUP:
      return { ...state, error };
    case SET_STOP_FETCH_ON_END:
      return { ...state, stopFetchOnEnd };
    case SET_PROCEED_TO_VERIFY:
      return { ...state, proceedToVerify };
    case SET_PROCEED_TO_CHECKOUT:
      return { ...state, proceedToCheckout };
    case RESET_SIGNUP:
      return {
        ...state,
        error,
        message,
        stopFetchOnEnd,
        proceedToVerify,
        proceedToCheckout
      };
    default:
      return { ...state };
  }
};
