import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  FormText,
  Input,
  Button
} from 'reactstrap';
import { AnimSend } from '../elements';


import data from '../../assets/bodym/sending.json';

const initialForm = {
  name: ''
};

function CategoryForm({
  createCategory,
  set_sel_form,
  addToast,
  cancelRequest,
  isFetchingOne,
  lang
}) {
  const [formVals, setFormVals] = useState(initialForm);
  useEffect(() => {
    if (process.env.REACT_APP_PREFILL_DATA) {
      setFormVals((form) => ({
        ...form,
        name: 'nombre'
      }));
    }
  }, [setFormVals]);
  const getValue = ({ target: { name, value, type, checked } }) => {
    const val = type === 'checkbox' ? checked : value;
    setFormVals({
      ...formVals,
      [name]: val
    });
  };
  const endAnim = () => {
    cancelRequest();
  };
  const reloadPage = () => {
    window.location.reload(false);
  };

  const startSend = (e) => {
    e.preventDefault();
    const { name } = formVals;
    const data = {
      name: { es: name }
    };
    createCategory(data);
    set_sel_form('');
    reloadPage();

    //console.log(data);
  };
  const { name } = formVals;
  return (
    <Form onSubmit={startSend}>
      {isFetchingOne ? (
        <Row className="justify-content-center">
          <Col xs={12} sm={6}>
            <AnimSend data={data} endAnim={endAnim} />
          </Col>
        </Row>
      ) : (
        <>
          <FormGroup row>
            <Label for="name" sm={3}>
              Nombre de catálogo*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={name}
                addToast={addToast}
                required
                type="text"
                name="name"
                id="name"
                placeholder="Nombre de catálogo"
              />
              <FormText color="muted">Nombre para identificación</FormText>
            </Col>
          </FormGroup>
          <FormGroup>
            <Button
              // onClick={this.startSend}
              block="block"
              color="primary"
              disabled={isFetchingOne}
              type="submit"
            >
              Crear Catálogo
            </Button>
          </FormGroup>
        </>
      )}
    </Form>
  );
}

export default CategoryForm;
