export const LABELS_ANALTYICS = {
  TOTAL_POINTS_ADDED: {
    es: 'Total puntos añadidos',
    en: 'Total points added'
  },
  TOTAL_POINTS_ADDED_CURRENT_MONTH: {
    es: 'Total puntos añadidos este mes',
    en: 'Total points added this month'
  },
  TOTAL_POINTS_ADDED_CURRENT_WEEK: {
    es: 'Total puntos añadidos esta semana',
    en: 'Total points added this week'
  }
};

export const STATUS_PRODUCT_LABEL = {
  CREATED: 'CREADO',
  UPDATED: 'ACTUALIZADO',
  PUBLISHED: 'PUBLICADO',
  DRAFT: 'BORRADOR',
  OUT_OF_STOCK: 'AGOTADO',
  UNAVAILABLE: 'NO DISPONIBLE',
  STOCK_REDUCED: 'UNIDADES REDUCIDAS'
};

export const STATUS_ORDER_LABEL = {
  CREATED: 'CREADA',
  PROCESSED: 'PROCESADA',
  IN_TRANSIT: 'EN TRANSITO',
  CANCELED: 'CANCELADA',
  SENT: 'ENVIADA',
  RECEIVED: 'RECIBIDA',
  UPDATED: 'ACTUALIZADA',
  DELIVERED: 'ENTREGADA',
  AUTHORIZE_PAYMENT: 'AUTORIZAR PAGO',
  PAYED: 'PAGADA'
};

export const STATUS_PRODUCT_ORDER_LABEL = {
  CREATED: 'CREADO',
  PROCESSED: 'PROCESADO',
  IN_TRANSIT: 'EN TRANSITO',
  CANCELED: 'CANCELADO',
  SENT: 'ENVIADO',
  RECEIVED: 'RECIBIDO',
  UPDATED: 'ACTUALIZADO',
  DELIVERED: 'ENTREGADO'
};

export const STATUS_USER_LABEL = {
  ACTIVE: 'ACTIVO',
  INACTIVE: 'INACTIVO'
};

export const LABELS_FORM_PRODUCT = {
  EDIT_ALL: {
    es: 'Editar todo',
    en: 'Edit all fields'
  },
  UPDATE: {
    es: 'Actualizar',
    en: 'Update'
  },
  ADD_IMAGE: {
    es: 'Añadir una imagen al Producto',
    en: 'Add an image to the Product'
  },
  IMAGE_FORM_TITLE: {
    es: 'Imagen',
    en: 'Add an image to the Product'
  },
  TAGS: {
    es: 'Etiquetas',
    en: 'Tags'
  },
  TAGS_EXPL: {
    es: 'Para filtrar la búsqueda y maneras en las que te pueden encontrar',
    en: 'For filtering search'
  },
  COLORS: {
    es: 'Colores',
    en: 'Colors'
  },
  COLORS_EXPL: {
    es: 'Colores del producto en orden de importancia',
    en: 'Product Colors in importance order'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description'
  },
  CATEGORY: {
    es: 'Catálogo',
    en: 'Category'
  },
  DESCRIPTION_EXPL: {
    es: 'Descripción del producto',
    en: 'Description of the product'
  },
  LONGNAME: {
    es: 'Nombre largo',
    en: 'Long name'
  },
  LONGNAME_EXPL: {
    es: 'Para identificación más completa del producto',
    en: 'For a more complete product identification'
  },
  REPETITION_FACTOR: {
    es: 'Factor de repetición',
    en: 'Repetition factor',
    PLACEHOLDER: {
      es: '3.42',
      en: ''
    }
  },
  REPETITION_FACTOR_EXPL: {
    es: 'Explicación del factor de repetición',
    en: 'For a more complete product identification'
  },
  STOCK: {
    es: 'Unidades disponibles',
    en: 'Available units'
  },
  PRICE: {
    es: 'Costo ',
    en: 'Cost',
    PLACEHOLDER: {
      es: 'Costo',
      en: ''
    }
  },
  PRICE_EXPL: {
    es: 'Costo en moneda seleccionada',
    en: ''
  },
  VALUE: {
    es: 'Valor del producto',
    en: 'Product value'
  },
  SKU: {
    es: 'SKU',
    en: 'SKU'
  },
  SKU_EXPL: {
    es: 'Stock Keeping Unit',
    en: 'Stock Keeping Unit'
  },
  BRAND: {
    es: 'Marca',
    en: 'Brand'
  },
  ACTIVE: {
    es: 'Active',
    en: 'Activo'
  },
  LIVEMODE: {
    es: 'Publicar',
    en: 'Publish'
  },
  SHIPPABLE: {
    es: '¿Necesita envio?',
    en: '¿Needs handling?'
  },
  PACKAGE_DIMENSIONS: {
    es: 'Dimensiones del paquete',
    en: 'Package dimensions'
  },
  PACKAGE_DIMENSIONS_EXPL: {
    es: 'Dimensiones para envío, considere tipo de empaque',
    en: 'Package dimensiones, take kind of packaging into account'
  },
  WIDTH: {
    es: 'Ancho del producto',
    en: 'Products width'
  },
  HEIGHT: {
    es: 'Alto del producto',
    en: 'Products height'
  },
  PHONE: {
    es: 'Teléfono',
    en: 'Telephone'
  },
  PHONE_EXPL: {
    es: '10 dígitos p.ej 1234567890',
    en: '10 digits format'
  },
  PHONE_VALID: {
    es: 'Súper',
    en: 'Super'
  },
  PHONE_INVALID: {
    es: 'Ingresa 10 dígitos',
    en: 'Please use 10 digits'
  },
  PHONE_EXT: {
    es: 'Extensión',
    en: 'Extension'
  },
  HANDLE: {
    es: 'Handle único',
    en: 'Unique handle'
  },
  SHORTNAME: {
    es: 'Nombre corto',
    en: 'Shortname'
  },
  SHORTNAME_EXPL: {
    es: 'Nombre corto para identificación rápida',
    en: 'Short name for rapid identification'
  },
  PRODUCT_TYPE: {
    es: 'Tipo de producto',
    en: 'Product Types'
  },
  TITLE: {
    es: 'Ocupación/Puesto',
    en: 'Job Title'
  },

  FIRST_NAME: {
    es: 'Nombre(s)',
    en: 'Name'
  },

  LAST_NAME: {
    es: 'Apellido(s)',
    en: 'Last name'
  },
  SHOW_BIRTHDATE: {
    es: 'Mostrar cumpleaños',
    en: 'Show BirthDate'
  },
  FILE_UPLOAD: {
    es: 'Apellido(s)',
    en: 'Last name'
  },
  FILE_UPLOAD_EXPL: {
    es: 'Apellido(s)',
    en: 'Last name'
  },
  PROFILE_UPLOAD: {
    es: 'Foto perfil',
    en: 'Profile Picture'
  },
  PROFILE_UPLOAD_EXPL: {
    es: 'Una foto para personalizar tu perfil, debe ser de al menos 60px por 60px',
    en: 'A profile picture to personalize your profile, it should be at least 60px square'
  },
  SUBMIT: {
    es: 'Crear Producto',
    en: 'Create Product'
  }
};

export const LABELS_FORM_VERIFY = {
  VERIFY_CODE: {
    es: 'Por favor proporciona tu código de verificación',
    en: 'Plase provide your verification code'
  },
  SUBMIT: {
    es: 'Verificar ahora',
    en: 'Verify account'
  },
  CHECK_LATER: {
    es: 'Verificar después',
    en: 'Check later'
  }
};

export const LABELS_VERIFY_PAYMENT = {
  THANK_YOU: {
    es: 'Gracias por su pago',
    en: 'Thank you for your payment'
  },
  CANCEL: {
    es: 'La transacción ha sido cancelada',
    en: 'Transaction has been cancelled'
  },
  BEING_REDIRECTED: {
    es: 'Está siendo redireccionado...',
    en: `You're being redirected...`
  }
};

export const LABELS_FORM_CALCULATOR = {
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description'
  },
  IN_METERS: {
    es: '(En metros)',
    en: '(In meters)'
  },
  REP_FACTOR: { es: 'Factor Rep.', en: 'Rep. Factor' },
  WIDTH: {
    es: 'Ancho',
    en: 'Width'
  },
  WIDTH_PLACEHOLDER: {
    es: 'Introduzca el ancho',
    en: 'Width'
  },
  HEIGHT: {
    es: 'Alto',
    en: 'Height'
  },
  HEIGHT_PLACEHOLDER: {
    es: 'Introduzca el alto',
    en: 'Height'
  },
  ADD_TO_CART: {
    es: 'Añadir al carrito',
    en: 'Add to Cart'
  },
  ROLLS: {
    es: 'rollos',
    en: 'rolls'
  }
};

export const LABELS_CATEGORIES_DROPDOWN = {
  SELECT_CATEGORIES: {
    es: 'Seleccionar colección',
    en: 'Select Collection'
  }
};
