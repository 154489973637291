import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SlideMenu, Overlay } from '../../components/elements';
import { set_slide_content } from '../../redux/actions/platform-actions';

function SlideMenuCont({ set_slide_content, ...rest }) {
  const { pathname } = useLocation();
  useEffect(() => {
    set_slide_content('');
  }, [pathname, set_slide_content]);
  return (
    <>
      <Overlay {...rest} />
      <SlideMenu toggleSlideMenu={() => set_slide_content('')} {...rest} />
    </>
  );
}

const mapStateToProps = ({
  globalReducer: { lang },
  platformReducer: { slide_content }
}) => ({
  slide_menu: slide_content !== '' ? true : false,
  slide_content,
  lang
});

const mapActionsToProps = {
  set_slide_content
};

export default connect(mapStateToProps, mapActionsToProps)(SlideMenuCont);
