import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/contact-actions';
import { getStateLang } from './utils';
import { api_public } from '../../services';

function* startPostContact(data) {
  yield put(actions.reset_contact());
/*   console.log(data.values(),"ghola") */
  yield put(actions.request_contact());
  const lang = yield getStateLang();
  try {
    const respCont = yield call(api_public.postContact, data);
    console.log({ respCont, data });
    if (respCont.error) {
      yield put(actions.error_contact(respCont.error));
      yield put(actions.cancel_contact());
    } else {
      yield put(actions.success_contact(respCont.message[lang] || ''));
      yield put(actions.cancel_contact());
    }
  } catch (error) {
    yield put(actions.error_contact(error));
    yield put(actions.cancel_contact());
  }
}

export function* watchContact() {
  yield takeLatest(actions.START_POST_CONTACT, startPostContact);
}
