import React, { useState } from "react";
import { UploadFiles } from "./";
import { EditableImg } from "../editableEls";

function CropUploadInline({
  charact: {
    formText,
    title,
    initialCrop,
    name,
    icon,
    forL,
    id,
    label,
    hidden
  },
  getFiles,
  required
}) {
  const [imgForCrop, setImgForCrop] = useState(false);
  const loadCropImg = ({ target: { files } }) => {
    if (files && files.length > 0) {
      getFiles({ target: { files, name } });
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgForCrop(reader.result);
      });
      reader.readAsDataURL(files[0]);
    }
  };
  return (
    <>
      <UploadFiles
        isRow={true}
        inputCol={11}
        formText={formText}
        title={title}
        accept="image/jpeg,image/png"
        classLbl=""
        hidden={hidden}
        required={required}
        className="justify-content-center mt-2"
        getFiles={loadCropImg}
        gotFiles={imgForCrop.length}
        name="cropped_img"
      />

      <EditableImg
        initialCrop={initialCrop}
        getFiles={getFiles}
        nameOfFile={name}
        src={imgForCrop}
      />
    </>
  );
}

export default CropUploadInline;
