import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import profile from '../../../assets/img/utils/profile_1.jpg';

function ProfileNav({ className, fillProfile, isFetching, user }) {
  const { firstname, lastname, profile_image } = user || {};
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${className}`}
    >
      {isFetching ? (
        <FontAwesomeIcon className="text-light" spin icon="cog" />
      ) : (
        <>
          <p className="my-auto">
            {firstname || lastname ? null : (
              <Button onClick={fillProfile} className="text-dark" color="link">
                Completa tu perfil
              </Button>
            )}
            &nbsp;
            {firstname && firstname}&nbsp;
            {lastname && lastname}
          </p>
          <img
            width="40px"
            className="img-fluid rounded-circle mx-2"
            src={profile_image ? profile_image : profile}
            alt=""
          />
        </>
      )}
    </div>
  );
}

export default ProfileNav;
