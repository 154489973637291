import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LABELS_PLATFORM } from "../../constants/platform";

function CompanySubemenu({ createCompany, lang }) {
  return (
    <>
      <Button onClick={createCompany} color="primary" block>
        {LABELS_PLATFORM.CREATE_COMPANY[lang]}
      </Button>
      <Button
        block
        className="mt-2"
        color="primary"
        tag={Link}
        to="/cards"
        target="_blank"
        rel="noopener noreferrer">
        {LABELS_PLATFORM.SEARCH[lang]}&nbsp;
        <FontAwesomeIcon icon="id-badge" />
      </Button>
    </>
  );
}

export default CompanySubemenu;
