import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import { Footer } from '../../components/sections';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import papeltapiz from '../../images/products/tapiz_3.png';
import pisoslaminados from '../../images/products/laminados_3.png';
import alfombras from '../../images/products/alfombras_3.png';
import persiana from '../../images/products/persianas_3.png';
import mueblessobrediseno from '../../images/products/muebles_3.png';
import decking from '../../images/products/decking_3.png';
import pisosvinilicos from '../../images/products/vinilicos_3.png';
import pastosintetico from '../../images/products/sinteticos_3.png';

const products = [
  {
    id: 25,
    title: 'Papel Tapiz',
    bg: papeltapiz,
    to: '/wallpaper',
    overlay: 'overlay_orange',
    bg_page: 'bg_blinds',
    paragraph1: `
      En neocarpets.com.mx encontrará una gran variedad de papel tapiz
      y fotomurales con más de mil diseños en línea de las mejores marcas. 
      `,
    paragraph2: `
      Somos uno de los principales distribuidores de papel
      tapiz en México, en nuestra empresa, las necesidades y requerimientos de nuestros
      clientes se valoran muy alto. Por lo tanto, nuestro equipo está
      completamente dedicado a ofrecer el mejor servicio a nuestros clientes.`
  },
  {
    title: 'Pisos Laminados',
    bg: pisoslaminados,
    to: '/laminateFloor',
    overlay: 'overlay_green',
    bg_page: 'bg_laminate',
    paragraph1: `
      Los pisos laminados realzan la decoración de cualquier estancia.
      Sus características únicas facilitan la limpieza, garantizan
      durabilidad y brindan calidez al hogar.`,
    paragraph2: `
      Con nuestros pisos de madera, haz que tus espacios se transformen
      en un ambiente amplio, cálido y natural. Con distintos acabados y
      matices, nuestros pisos de madera te permiten decorar creando
      estilos únicos.`
  },
  {
    title: 'Alfombras',
    bg: alfombras,
    to: '/rug',
    overlay: 'overlay_blue',
    bg_page: 'bg_rug',
    paragraph1: `
      Contamos con una gran variedad de colores y diseños, ya sean
      alfombras de tráfico pesado, modulares o lisas, para ambientes
      residenciales o comerciales; con nosotros encontrarás la alfombra
      ideal para crear el ambiente que más se adapte a tu estilo de vida.`
  },
  {
    title: 'Persianas y Cortinas',
    bg: persiana,
    to: '/blinds',
    overlay: 'overlay_pink',
    bg_page: 'bg_blinds',
    paragraph1: `
      Nuestros estilos de persianas son versátiles y modernos,
      le ofrecen una forma práctica de lograr privacidad y reducir
      la iluminación natural mientras agregan colores y texturas
      mediante la amplia variedad de tejidos que le ofrecemos.`,
    paragraph2: `
      Las cortinas son un elemento clásico, elegante y siempre de
      moda sin duda son una elección ideal para convertir espacios
      fríos en lugares acogedores y cómodos.`
  },
  {
    title: 'Muebles Sobre Diseño',
    bg: mueblessobrediseno,
    to: '/furniture',
    overlay: 'overlay_green',
    bg_page: 'bg_furniture',
    paragraph1: `
      Encuentra los muebles para resaltar tu espacio, adapta tu
      lugar y consigue un lugar armonioso. Aprovecha al máximo los
      espacios, generando un entorno innovador y vanguardista. `,
    paragraph2: `
      Los diseños de nuestros muebles se adaptan a tu arquitectura,
      siempre buscando el confort, la funcionalidad y la elegancia.`
  },
  {
    title: 'Deck',
    bg: decking,
    to: '/deckingProd',
    overlay: 'overlay_blue',
    bg_page: 'bg_decking',
    paragraph1: `
      La resistencia a la humedad y el calor son una de las
      características más sobresalientes del piso deck, por lo que es
      perfecto para complementar la decoración de terrazas, jardines,
      albercas y áreas expuestas al exterior e intemperie.`
  },
  {
    title: 'Pisos Vinílicos',
    bg: pisosvinilicos,
    to: '/vinyl',
    overlay: 'overlay_orange',
    bg_page: 'bg_vinyl',
    paragraph1: `
      El piso vinílico es un recubrimiento que viene a revolucionar
      el piso interior de muchos hogares y oficinas, además tiene una
      gran ventaja por ser resistente a la humedad y al alto tráfico,
      brinda una apariencia y textura muy similar a la que nos
      proporciona un piso de madera, entre estos existe una gran
      variedad para uso comercial y residencial.
    `
  },
  {
    title: 'Pasto Sintético y Muros Verdes',
    bg: pastosintetico,
    to: '/synthetic',
    overlay: 'overlay_pink',
    bg_page: 'bg_synthetic',
    paragraph1: `
      El pasto sintético te ofrece confort, durabilidad y una gran
      similitud al pasto natural, ayuda a crear paisajismo y darle
      un toque de naturaleza a tus espacios, con el beneficio de
      poco mantenimiento, es ideal para canchas deportivas, jardines
      y área verdes. `
  }
];

function ProductsPage(props) {
  const { id } = useParams();
  console.log(id);
  return (
    <>
      <Container fluid className="bg-light">
        <Row className=" d-flex  align-items-center justify-content-center pl-md-5">
          {' '}
          <Col
            xs={12}
            md={6}
            xl={4}
            className="pl-md-2 pr-md-5 pl-3 pr-3 mt-5 pt-5 "
          >
            <Row>
              {' '}
              <Link to="/products" className="text-dark px-3 pl-md-0 pb-md-2">
                <FontAwesomeIcon
                  size="lg"
                  className="text-dark mr-1"
                  icon={['fas', 'chevron-left']}
                />
                Regresar
              </Link>
            </Row>
            <Row className="justify-content-center justify-content-md-start">
              <h1 className="font-weight-bold text-center text-md-left title_product_description">
                {' '}
                {products[id].title}
              </h1>{' '}
            </Row>{' '}
            {id > 0 ? (
              <Row className="justify-content-center justify-content-md-start">
                <h5 className="text_orange font-weight-bold subtitle_product_description ">
                  {' '}
                  Próximamente en el visualizador
                </h5>{' '}
              </Row>
            ) : (
              <Row className="justify-content-center justify-content-md-start">
                <Link to="/configurator">
                  <h5 className="text_orange subtitle_product_description font-weight-bold ">
                    {' '}
                    Visualiza tu espacio
                  </h5>
                </Link>
              </Row>
            )}
            <br />
            <Row className="justify-content-center justify-content-md-start">
              <h5 className="text-justify text-md-left mx-4 mx-md-0 text_description ">
                {products[id].paragraph1}
              </h5>
              <h5 className="text-justify text-md-left mx-4 mx-md-0 text_description">
                {products[id].paragraph2}
              </h5>
            </Row>
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <Row className="d-none d-md-flex">
              <h5 className="text_pink font-weight-bold subtitle_product_description">
                Nuestros productos cuentan con la más alta calidad, contáctanos
                y resolveremos tus dudas.
              </h5>
            </Row>
            <br />
            <Row className="d-none d-md-flex">
              <Link to="/contact">
                {' '}
                <Button
                  color="primary"
                  className="rounded-pill  px-4 px-lg-5 py-3"
                >
                  <h6 className="mb-0"> Contáctanos</h6>
                </Button>
              </Link>
              {id <= 0 ? (
                <Link to="/configurator">
                  {' '}
                  <Button
                    color="primary"
                    className="rounded-pill  ml-3 px-4 px-lg-5 py-3"
                  >
                    <h6 className=" mb-0">Diseña tu espacio</h6>
                  </Button>
                </Link>
              ) : (
                ''
              )}
            </Row>
          </Col>
          <Col
            xs={12}
            md={6}
            xl={8}
            className="height_img_product d-none d-md-flex "
            style={{
              backgroundImage: ` url(${products[id].bg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center'
            }}
          ></Col>
        </Row>
      </Container>
      <Container
        fluid
        className="d-block d-md-none"
        style={{
          backgroundImage: ` url(${products[id].bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '35vh'
        }}
      ></Container>
      <Container fluid className="d-block d-md-none pt-3">
        {' '}
        <Row className="d-flex d-md-none justify-content-center">
          <Link to="/contact">
            {' '}
            <Button color="primary" className="rounded-pill  px-3 py-3">
              {' '}
              <h6 className="my-md-1 mb-0"> Contáctanos</h6>
            </Button>
          </Link>
          {id <= 0 ? (
            <Link to="/configurator">
              {' '}
              <Button color="primary" className="rounded-pill  ml-3 px-3 py-3">
                <h6 className="my-md-1 mb-0">Diseña tu espacio</h6>
              </Button>
            </Link>
          ) : (
            ''
          )}
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default ProductsPage;
