import { useState, useEffect, useRef } from 'react';
import { debounce } from '../utils';
import { DEBOUNCE_TIME, SMALL_BREAKPOINT } from '../constants';
import { useLocation } from 'react-router-dom';

export function useVisible(theRef, defVal) {
  const [visible, setVisible] = useState(defVal ? defVal : false);
  const checkInView = debounce(() => {
    const place = theRef.current.getBoundingClientRect();
    if (place) {
      if (place.top >= 0 && place.bottom <= window.innerHeight) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, DEBOUNCE_TIME);
  useEffect(() => {
    window.addEventListener('scroll', checkInView);
    return () => window.removeEventListener('scroll', checkInView);
    //eslint-disable-next-line
  }, []);
  return visible;
}

export function useIsSmall(defVal) {
  const [isSmall, setIsSmall] = useState(true);
  const checkValue = defVal ? defVal : SMALL_BREAKPOINT;
  const checkIsSmall = () => {
    if (window.innerWidth < checkValue) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  };
  useEffect(() => {
    checkIsSmall();
    window.addEventListener('resize', checkIsSmall);
    return () => window.removeEventListener('resize', checkIsSmall);
    // eslint-disable-next-line
  }, []);
  return isSmall;
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [delay, value]
  );

  return debouncedValue;
}

export function useFocus() {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
}

export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useQuery(search) {
  return new URLSearchParams(search);
}
