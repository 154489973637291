export const SET_LANG = 'SET_LANG';
export const SET_CONTACT_FORM = 'SET_CONTACT_FORM';

export const set_lang = (lang) => ({
  type: SET_LANG,
  lang
});

export const set_contact_form = (form_name) => ({
  type: SET_CONTACT_FORM,
  contactForm: form_name
});
