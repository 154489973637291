import { takeLatest, put, call } from "redux-saga/effects";
import * as actions from "../actions/brand-actions";
import { add_toast } from "../actions/toasts-actions";
import { getStateLang } from "./utils";
import { api_private } from "../../services";

function* getAllBrands({ searchURL }) {
  yield put(actions.reset_brand());
  yield put(actions.request_brand());
  const lang = yield getStateLang();
  try {
    const response = yield call(api_private.getAllBrands, searchURL);
    if (response.error) {
      yield put(actions.error_brand(response.error));
      yield put(
        add_toast(add_toast({ error: true, text: response.error[lang] }))
      );
      yield put(actions.cancel_brand());
    } else {
      yield put(actions.success_brand(response.message[lang]));
      yield put(actions.set_all_brands(response.brands));
      yield put(actions.cancel_brand());
    }
  } catch (error) {
    yield put(actions.error_brand(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString()
      })
    );
    yield put(actions.cancel_brand());
  }
}

export function* watchBrand() {
  yield takeLatest(actions.LIST_BRANDS, getAllBrands);
}
