import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  FormText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce } from "../../hooks";
import { DEBOUNCE_TIME } from "../../constants";
import {
  rangeProportional,
  prevRangeCumulative
  // prevRangeProportional
} from "../../utils";
import {
  LABELS_FORM_POINTS,
  LABELS_PLATFORM,
  CREDIT_RULES
} from "../../constants/platform";

function PointsForm({
  addPointsUser,
  isFetching,
  startSearchUsers,
  suggestedUsers,
  className,
  lang,
  allBrands
}) {
  const [formVals, setFormVals] = useState({
    search: "",
    brand_id: "",
    reference: "",
    bought_kg: 0
  });
  const { search, brand_id, reference, bought_kg } = formVals;
  const [user, setUser] = useState(false);
  const [open, toggle] = useState(false);
  const [pointsToAdd, setPointsToAdd] = useState(0);
  const searchDebounce = useDebounce(search, DEBOUNCE_TIME.SEARCH);
  useEffect(() => {
    if (searchDebounce !== "") {
      toggle(true);
      let queryURL = new URLSearchParams();
      queryURL.append("search", true);
      queryURL.append("search_text", searchDebounce);
      startSearchUsers(`?${queryURL.toString()}`);
    }
  }, [searchDebounce, startSearchUsers]);
  useEffect(() => {
    if (bought_kg > 0 && brand_id !== "") {
      const brandRange = allBrands.find((el) => el._id === brand_id);
      if (brandRange) {
        const { max_points, range, rule } =
          brandRange.credit_rules.find(
            ({ range: [floor, ceiling] }) =>
              bought_kg >= floor && bought_kg <= ceiling
          ) || {};
        if (max_points && rule) {
          const total =
            rule === CREDIT_RULES.range_cumulative
              ? prevRangeCumulative(bought_kg, range[0], max_points)
              : rangeProportional(bought_kg, max_points);
          setPointsToAdd(total);
        }
      }
    }
  }, [allBrands, bought_kg, brand_id]);
  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  const handleSetUser = (id) => {
    toggle(false);
    setUser(id);
  };

  const cleanUser = () => {
    setUser(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (pointsToAdd > 0) {
      addPointsUser(user._id, {
        balance: pointsToAdd,
        ticket_ref: { relation: "ticket", reference: reference }
      });
      setFormVals({
        search: "",
        animal: "",
        reference: "",
        bought_kg: 0
      });
      setPointsToAdd(0);
    }
    // setUser(false);
  };

  return (
    <Form onSubmit={handleSubmit} className={`${className}`}>
      <FormGroup>
        <Label>{LABELS_FORM_POINTS.SEARCH_USER[lang]}</Label>
        <InputGroup>
          <InputGroupButtonDropdown
            addonType="prepend"
            isOpen={open}
            toggle={() => toggle(!open)}>
            <DropdownToggle outline caret>
              {isFetching ? (
                <FontAwesomeIcon spin icon="spinner" />
              ) : (
                <FontAwesomeIcon icon="search" />
              )}
            </DropdownToggle>
            <DropdownMenu>
              {suggestedUsers.length ? (
                <DropdownItem header>Usuarios encontrados</DropdownItem>
              ) : (
                <DropdownItem header>Sin coincidencias</DropdownItem>
              )}
              {suggestedUsers.map((el, i) => (
                <DropdownItem
                  onClick={() => handleSetUser(el)}
                  key={
                    i
                  }>{`${el.firstname} ${el.lastname} | ${el.telephone} | ${el.email}`}</DropdownItem>
              ))}
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <Input
            onChange={getValue}
            name="search"
            value={search}
            placeholder="Ingrese su búsqueda"
          />
        </InputGroup>
      </FormGroup>
      {user ? (
        <>
          <FormGroup>
            <legend>
              {`${user.firstname} ${user.lastname} | ${user.telephone} | ${user.email}`}{" "}
              <Button onClick={cleanUser} close />
            </legend>
          </FormGroup>
          <FormGroup row>
            <Label for="bought_kg" sm={3}>
              {LABELS_FORM_POINTS.BOUGHT_KG[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={bought_kg}
                type="number"
                name="bought_kg"
                id="bought_kg"
                max="100000"
                placeholder={LABELS_FORM_POINTS.BOUGHT_KG[lang]}
                required
              />
              <FormText>{LABELS_FORM_POINTS.BOUGHT_KG[lang]}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="animal" sm={3}>
              {LABELS_FORM_POINTS.ANIMAL_SELECTED[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                // value={animal.label[lang]}
                type="select"
                name="brand_id"
                id="brand_id"
                placeholder={LABELS_FORM_POINTS.ANIMAL_SELECTED[lang]}
                required>
                <option value={""}>
                  {LABELS_FORM_POINTS.SELECT_VALUE[lang]}
                </option>
                {allBrands.map(({ brand_name, _id }, i) => (
                  <option key={i} value={_id}>
                    {brand_name}
                  </option>
                ))}
                {/* {POINTS_BY_ANIMAL.map(({ label, factor }, i) => (
                  <option key={i} value={factor}>
                    {label[lang]}
                  </option>
                ))} */}
              </Input>
              <FormText>{LABELS_FORM_POINTS.ANIMAL_SELECTED[lang]}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bought_kg" sm={3}>
              {LABELS_FORM_POINTS.TICKET_REF[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={reference}
                type="string"
                name="reference"
                id="reference"
                placeholder={LABELS_FORM_POINTS.TICKET_REF[lang]}
                required
              />
              <FormText>{LABELS_FORM_POINTS.TICKET_REF[lang]}</FormText>
            </Col>
          </FormGroup>
          <FormGroup>
            <Button disabled={!(user._id && pointsToAdd > 0)} block>
              {LABELS_FORM_POINTS.ADD_POINTS[lang]}&nbsp;{pointsToAdd}&nbsp;
              {LABELS_PLATFORM.USER_POINTS[lang]}
            </Button>
          </FormGroup>
        </>
      ) : null}
    </Form>
  );
}

export default PointsForm;
