import {
  SET_PRODUCTS_CART,
  ADD_TO_CART,
  REM_FROM_CART,
  INC_PRODUCT_QUANTITY_CART,
  DEC_PRODUCT_QUANTITY_CART,
  // UPDATE_PRODUCT_CART,
  SET_PRODUCT_QUANTITY,
  SET_TOTAL_CART,
  RESET_CART
} from '../actions/cart-actions';

export const cartReducer = (
  state = {
    cartProducts: [],
    total: 0,
    prod_id: '',
    quantity:null
  },
  action
) => {
  const { type, prod, prod_id, total, cartProducts,quantity } = action;
  switch (type) {
    case SET_PRODUCTS_CART:
      return { ...state, cartProducts };
    case SET_TOTAL_CART:
      return { ...state, total };
    case ADD_TO_CART:
      const found = state.cartProducts.find((el) => el._id === prod._id);
      if(found) {
        return state
      }
      const nextItemsA = [
        ...state.cartProducts,
        {
          ...prod,
          quantity: 1
        }
      ];
      const nextTotalA = nextItemsA.reduce(
        (acc, curr) => acc + curr.price * curr.quantity,
        0
      );
      return { ...state, cartProducts: nextItemsA, total: nextTotalA };
    case REM_FROM_CART:
      const nextItems = [
        ...state.cartProducts.slice(0, prod_id),
        ...state.cartProducts.slice(prod_id + 1)
      ];
      const nextTotal = nextItems.reduce(
        (acc, curr) => acc + curr.price * curr.quantity,
        0
      );
      return {
        ...state,
        cartProducts: nextItems,
        total: nextTotal
      };
    case INC_PRODUCT_QUANTITY_CART:
      const foundQ = state.cartProducts.find((el) => el._id === prod_id);
      const pos = state.cartProducts.indexOf(foundQ);
      const nextPlus = foundQ.quantity + 1;
      return {
        ...state,
        cartProducts: [
          ...state.cartProducts.slice(0, pos),
          {
            ...foundQ,
            quantity: nextPlus
          },
          ...state.cartProducts.slice(pos + 1)
        ]
      };
    case DEC_PRODUCT_QUANTITY_CART:
      const foundQL = state.cartProducts.find((el) => el._id === prod_id);
      const posQL = state.cartProducts.indexOf(foundQL);
      const nextLess = foundQL.quantity - 1 <= 0 ? 1 : foundQL.quantity - 1;
      const nextItemsL = [
        ...state.cartProducts.slice(0, posQL),
        {
          ...foundQL,
          quantity: nextLess
        },
        ...state.cartProducts.slice(posQL + 1)
      ];
      const nextTotalL = nextItemsL.reduce(
        (acc, curr) => acc + curr.price * curr.quantity,
        0
      );
      return {
        ...state,
        cartProducts: nextItemsL,
        total: nextTotalL
      };
    case SET_PRODUCT_QUANTITY: 
      const foundPQ = state.cartProducts.find(el=>el._id===prod_id)
      if(!foundPQ){
        return state
      } 
      const posPQ = state.cartProducts.indexOf(foundPQ)
     
      const nextItemsPQ = [
        ...state.cartProducts.slice(0,posPQ), 
        {
          ...foundPQ,
          quantity
        }, 
        ...state.cartProducts.slice(posPQ+1)
      ]
      const nextTotalPQ = nextItemsPQ.reduce((acc,curr)=>acc+curr.price*curr.quantity,0)
      return {
        ...state,
        cartProducts:nextItemsPQ, 
        total:nextTotalPQ
      }
    case RESET_CART:
      return {
        ...state,
        cartProducts: [],
        total: 0,
        prod_id: ''
      };
    default:
      return state;
  }
};
