import React from "react";
import { FormGroup } from "reactstrap";

export const WelcomeM = ({ info: { message, small, className },lang }) => {
  return (
    <FormGroup className={`animated fadeInPlace ${className}`} row>
      <legend>
        {message[lang]}
        <br />
        <small className="text-muted">{small[lang]}</small>
      </legend>
    </FormGroup>
  );
};
