import {
  REQUEST_ADDRESS,
  CANCEL_ADDRESS,
  SUCCESS_ADDRESS,
  ERROR_ADDRESS,
  RESET_ADDRESS,
  SET_SEL_ADDRESS
  // SET_ALL_ADDRESSES,
  // SET_ONE_ADDRESS,
  // SET_SEL_ADDRESS
} from "../actions/address-actions";

export const addressReducer = (
  state = {
    selAddress: "",
    isFetching: false,
    message: false,
    error: false
  },
  action
) => {
  const { type, isFetching, message, error, selAddress } = action;
  switch (type) {
    case SET_SEL_ADDRESS:
      return { ...state, selAddress };
    case REQUEST_ADDRESS:
      return { ...state, isFetching };
    case CANCEL_ADDRESS:
      return { ...state, isFetching };
    case SUCCESS_ADDRESS:
      return { ...state, message };
    case ERROR_ADDRESS:
      return { ...state, error };
    case RESET_ADDRESS:
      return { ...state, error, message };

    default:
      return { ...state };
  }
};
