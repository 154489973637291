import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Footer } from '../sections';
import { LABELS_VERIFY_PAYMENT } from '../../constants/labels';

import '../../style/order-page.css';

function VerifyPayment({ success, lang }) {
  return (
    <>
      <Container className="bg-light" fluid>
        <Container>
          <Row className="order_details pt-5">
            <Col className="d-flex flex-column justify-content-center">
              <h1 className="text_orange font-weight-bold">
                {success
                  ? LABELS_VERIFY_PAYMENT.THANK_YOU[lang]
                  : LABELS_VERIFY_PAYMENT.CANCEL[lang]}
                <br />
              </h1>
              <h4>
                Te recordamos que el tiempo de entrega es de 10 a 15 días
                hábiles. <br /> Te enviaremos un correo con instrucciones de
                entrega.{' '}
              </h4>
              <Row className="pt-4">
                <Col>
                  {' '}
                  <Button tag={Link} to={`/`} color="primary" className="px-5">
                    Ir a inicio{' '}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default VerifyPayment;
