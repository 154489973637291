import React, { Component } from "react";
import { Col, FormGroup, Input, Label, FormText } from "reactstrap";

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  } //constructor
  isHover = () => {
    this.setState({
      isHovered: true
    });
  };
  isNotHover = () => {
    this.setState({
      isHovered: false
    });
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.getFiles({ target: { files: e.dataTransfer.files } });
      e.dataTransfer.clearData();
    }
  };
  render() {
    const {
      title,
      gotFiles,
      getFiles,
      inputCol,
      isRow = true,
      labelCol,
      className,
      classLbl,
      formText,
      hidden,
      ...rest
    } = this.props;
    const { isHovered } = this.state;
    return (
      <FormGroup className={`${className} text-left`} row={isRow}>
        <Label hidden={hidden} className={classLbl} sm={labelCol}>
          {title}
        </Label>
        <Col sm={inputCol}>
          <div className="custom-file">
            <Input
              type="file"
              onChange={getFiles}
              onDragEnter={this.isHover}
              onDragLeave={this.isNotHover}
              onDrop={this.handleDrop}
              onDragOver={this.isHover}
              className="custom-file-input"
              {...rest}
            />
            <Label
              className={`custom-file-label animated fadeInUp
                      ${gotFiles > 1 ? "got-files" : ""}
                      ${gotFiles === 1 ? "got-file" : ""}
                      ${isHovered ? "hover" : ""}`}
            />
          </div>
          <FormText color="muted">
            {formText
              ? formText
              : "Para seleccionar más de un archivo, seleccione con la tecla shift o control"}
          </FormText>
        </Col>
      </FormGroup>
    );
  }
}
