import React from 'react';
import { EditableTable } from '../../elements';

function ProductsTable({ startUpdateProduct, allProducts, editAction }) {
  return (
    <EditableTable
      tableElements={allProducts}
      rowDataKeys={['images', 'short_name', 'price', 'sku']}
      colHead={['Imagen', 'Nombre Corto ', 'Precio', 'SKU']}
      imgFolder="collections"
      searchEnabled={['stock', 'price', 'short_name', 'long_name', 'sku']}
      className="mt-2"
      editAction={editAction}
      // Aquí van los métodos que van a actualizar los productos
      startUpdateRow={startUpdateProduct}
    />
  );
}

export default ProductsTable;
