import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from '../../../hooks';
import {
  DEBOUNCE_TIME,
  PAGINATION_ITEMS,
  PAGINATION_LINKS
} from '../../../constants';

const searchEnabled = ['firstname', 'lastname', 'telephone', 'cte_distributor'];

function UsersTable({
  startActivateUser,
  startDeactivateUser,
  lang,
  allUsers,
  isFetchingOne,
  className
}) {
  const [selUser, setSelUser] = useState('');
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState(0);
  const [paginIndex, setPagIndex] = useState(0);
  const [byRoleAsc, setByRoleAsc] = useState(false);

  const searchDebounce = useDebounce(search, DEBOUNCE_TIME.SEARCH);

  const pageCount = Math.ceil(allUsers.length / PAGINATION_ITEMS);
  useEffect(() => {
    setPagIndex(0);
  }, [searchDebounce]);

  const pagSect = Math.ceil(pageCount / PAGINATION_LINKS);

  const decPag = () => {
    const nextIndex = paginIndex - 1;
    if (nextIndex >= 0) setPagIndex(nextIndex);
  };

  const incPag = () => {
    const nextIndex = paginIndex + 1;
    if (nextIndex < pagSect) setPagIndex(nextIndex);
  };
  const sortingFunction = (a, b) =>
    byRoleAsc ? b.role - a.role : a.role - b.role;

  const filterFunction = (el) => {
    if (searchDebounce !== '') {
      return Object.entries(el)
        .filter(([key]) => searchEnabled.indexOf(key) > -1)
        .some(([key, val]) => {
          switch (typeof val) {
            case 'number':
              return (
                val
                  .toString()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .indexOf(search.toLowerCase()) !== -1
              );
            case 'string':
              return (
                val
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .indexOf(search.toLowerCase()) !== -1
              );
            default:
              return true;
          }
        });
    } else {
      return true;
    }
  };
  const handleGetSearch = ({ target: { value } }) => {
    setSearch(value);
  };
  const handleSortByUser = () => {
    setByRoleAsc(!byRoleAsc);
  };
  return (
    <Card className={className}>
      <CardBody>
        <Row className="mb-4">
          <Col xs={12} md={6}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon="search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                onChange={handleGetSearch}
                value={search}
                disabled={!allUsers.length}
                placeholder="búsqueda"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Correo</th>
                  <th>Teléfono</th>
                </tr>
              </thead>
              <tbody>
                {allUsers
                  .sort(sortingFunction)
                  .filter((el) => el.role > 1)
                  .filter(filterFunction)
                  .slice(
                    pagination * PAGINATION_ITEMS,
                    (pagination + 1) * PAGINATION_ITEMS
                  )
                  .map(({ firstname, email, lastname, telephone }, i) => (
                    <tr key={i}>
                      <td className="align-middle">
                        <b>{`${firstname} ${lastname}`}</b>
                      </td>
                      <td className="align-middle">{email}</td>
                      <td className="align-middle">{telephone}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Pagination>
            {pageCount > PAGINATION_LINKS && (
              <PaginationItem disabled={paginIndex === 0}>
                <PaginationLink first onClick={decPag} />
              </PaginationItem>
            )}
            {[...Array(pageCount).keys()]
              .slice(
                paginIndex * PAGINATION_LINKS,
                (paginIndex + 1) * PAGINATION_LINKS
              )
              .map((el, i) => (
                <PaginationItem active={el === pagination} key={i}>
                  <PaginationLink
                    onClick={() => setPagination(parseInt(el, 10))}
                  >
                    {parseInt(el, 10) + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            {pageCount > PAGINATION_LINKS && (
              <PaginationItem disabled={paginIndex === pagSect - 1}>
                <PaginationLink onClick={incPag} last />
              </PaginationItem>
            )}
          </Pagination>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UsersTable;
