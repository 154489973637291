import { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATES_DICTIONARY } from '../../constants';
import { LABELS_FORM_ADDRESS } from '../../constants/platform';

function AddressForm({
  startAddAddress,
  lang,
  inline,
  getExtValue,
  formExtVals,
  onSubmit,
  className,
  id_user,
  disabled = false
}) {
  const [formVals, setFormVals] = useState({
    city: '',
    line_1: '',
    line_2: '',
    postal_code: '',
    state: ''
  });
  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };
  const startSubmitAddress = (e) => {
    e.preventDefault();
    startAddAddress(id_user, {
      ...formVals
    });
  };
  return (
    <Form
      onSubmit={onSubmit ? onSubmit : startSubmitAddress}
      className={`${className}`}
    >
      <FormGroup>
        <Label for="line_1">{LABELS_FORM_ADDRESS.LINE_ONE[lang]} </Label>
        <Input
          onChange={inline ? getExtValue : getValue}
          value={inline ? formExtVals['line_1'] : formVals['line_1']}
          required
          type="text"
          name="line_1"
          id="line_1"
        />
      </FormGroup>
      <FormGroup>
        <Label for="line_2">{LABELS_FORM_ADDRESS.LINE_TWO[lang]}</Label>
        <Input
          onChange={inline ? getExtValue : getValue}
          value={inline ? formExtVals['line_2'] : formVals['line_2']}
          required
          type="text"
          name="line_2"
          id="line_2"
        />
      </FormGroup>
      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for="city">{LABELS_FORM_ADDRESS.CITY[lang]}</Label>
            <Input
              onChange={inline ? getExtValue : getValue}
              value={inline ? formExtVals['city'] : formVals['city']}
              required
              type="text"
              name="city"
              id="city"
            />
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="state">{LABELS_FORM_ADDRESS.STATE[lang]}</Label>
            <Input
              onChange={inline ? getExtValue : getValue}
              value={inline ? formExtVals['state'] : formVals['state']}
              required
              type="select"
              name="state"
              id="state"
            >
              <option value="">Selecciona una opción</option>
              {STATES_DICTIONARY.map(({ value, label }, i) => (
                <option value={value} key={i}>
                  {label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="postal_code">
              {LABELS_FORM_ADDRESS.POSTAL_CODE[lang]}
            </Label>
            <Input
              onChange={inline ? getExtValue : getValue}
              value={
                inline ? formExtVals['postal_code'] : formVals['postal_code']
              }
              required
              pattern="^[0-9]{5}"
              maxLength="5"
              type="text"
              name="postal_code"
              id="postal_code"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button disabled={disabled} block type="submit">
            {/* {LABELS_FORM_ADDRESS.SAVE_ADDRESS[lang]}&nbsp; */}
            {LABELS_FORM_ADDRESS.SAVE_ADDRESS[lang]}&nbsp;
            <FontAwesomeIcon icon="save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default AddressForm;
