import {
  REQUEST_CHECKOUT,
  CANCEL_CHECKOUT,
  SUCCESS_CHECKOUT,
  ERROR_CHECKOUT,
  RESET_CHECKOUT,
  SET_ORDER_CHECKOUT,
  SET_CHECKOUT_SESSION
} from '../actions/checkout-actions';

export const checkoutReducer = (
  state = {
    isFetching: false,
    message: false,
    error: false,
    createdOrder: false,
    checkoutSession: null
  },
  action
) => {
  const { type, isFetching, message, error, createdOrder, checkoutSession } =
    action;
  switch (type) {
    case REQUEST_CHECKOUT:
      return { ...state, isFetching };
    case CANCEL_CHECKOUT:
      return { ...state, isFetching };
    case SUCCESS_CHECKOUT:
      return { ...state, message };
    case ERROR_CHECKOUT:
      return { ...state, error };
    case RESET_CHECKOUT:
      return { ...state, message, error };
    case SET_ORDER_CHECKOUT:
      return { ...state, createdOrder };
    case SET_CHECKOUT_SESSION:
      return { ...state, checkoutSession };
    default:
      return { ...state };
  }
};
