import React from 'react';
import { Link} from 'react-router-dom';
import { Container,Col, Row } from 'reactstrap';

import papeltapiz from '../../images/landing_products/papeltapiz.png';
import pisoslaminados from '../../images/landing_products/laminados.png';
import alfombras from '../../images/landing_products/alfombras.png';
import persiana from '../../images/landing_products/cortinas.png';
import mueblessobrediseno from '../../images/landing_products/mueblessobrediseno.png';
import decking from '../../images/landing_products/decking.png';
import pisosvinilicos from '../../images/landing_products/vinilicos.png';
import pastosintetico from '../../images/landing_products/pasto.png';

import '../../style/products_landing.css';

const products = [
  {
    id:25,
    title: 'Papel Tapiz',
    bg: papeltapiz,
    to: '/wallpaper',
    overlay: 'overlay_orange'
  },
  {
    title: 'Pisos Laminados',
    bg: pisoslaminados,
    to: '/laminateFloor',
    overlay: ' overlay_green'
  },
  {
    title: 'Alfombras',
    bg: alfombras,
    to: '/rug',
    overlay: ' overlay_blue'
  },
  {
    title: 'Persianas y Cortinas',
    bg: persiana,
    to: '/blinds',
    overlay: ' overlay_pink'
  },
  {
    title: 'Muebles Sobre Diseño',
    bg: mueblessobrediseno,
    to: '/furniture',
    overlay: 'overlay_green'
  },
  {
    title: 'Deck',
    bg: decking,
    to: '/deckingProd',
    overlay: 'overlay_pink'
  },
  {
    title: 'Pisos Vinílicos',
    bg: pisosvinilicos,
    to: '/vinyl',
    overlay: 'overlay_orange'
  },
  {
    title: 'Pasto Sintético y Muros Verdes',
    bg: pastosintetico,
    to: '/synthetic',
    overlay: 'overlay_blue'
  }
];

function Products() {

  return (
    <Container
      className="d-flex flex-column justify-content-center bg_white pt-5"
      fluid
    >
      <Row className="d-flex flex-column justify-content-center text-center ">
        <h1 className="mt-2 text_dark_pink font-weight-bold">Nuestros productos</h1>
        <h6 className=" text-dark">Encuentra tu categoría</h6>
      </Row>
      <Row className="d-flex my-3 justify-content-center px-md-5 px-0">
        {products.map(({ title, bg, to, overlay }, i) => (
          <Col sm={6} md={3} className='d-flex px-2 my-2 justify-content-center align-items-center products_img_mobile text-center '>
            <Link className="rel_cont" to={`/products/${i}`} key={i}>
              <img src={bg} alt="background" className="img-fluid" />
              <h3 className="text-white font-weight-bold centering front_img ">{title}</h3>
              <div className={overlay}>
                  <h3 className="text-white font-weight-bold centering front_img">{title}</h3>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container> 
  );
}

export default Products;
