import React from 'react';
import { Container, Row, Col, NavLink } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { ContactFormCont } from '../../containers';
import { Footer } from '../../components/sections';
import cotizacionImg from '../../images/services_page/cotizacion2.png';
import instalacionImg from '../../images/services_page/instalacion2.png';
import mantenimientoImg from '../../images/services_page/mantenimiento2.png';
import reparacionImg from '../../images/services_page/reparacion2.png';
import '../../style/services_page.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const services = [
  {
    title: 'Cotización',
    subTitle: ' Te ayudamos en todo el proceso',
    text: ' Hacemos cotizaciones directamente en su domicilio sin cargo alguno y le llevamos muestras para que usted pueda tomar una mejor decisión o si lo prefiere se le pueden enviar vía correo electrónico donde le desglosamos las características de lo que desea cotizar, tiempos de entrega y formas de pago, dando una mayor seguridad y certeza de lo que está adquiriendo.',
    bg: cotizacionImg
  },
  {
    title: 'Instalación',
    subTitle: ' Brindamos para ti este servicio de calidad',
    text: ' Hacemos cotizaciones directamente en su domicilio sin cargo alguno y le llevamos muestras para que usted pueda tomar una mejor decisión o si lo prefiere se le pueden enviar vía correo electrónico donde le desglosamos las características de lo que desea cotizar, tiempos de entrega y formas de pago, dando una mayor seguridad y certeza de lo que está adquiriendo.',
    bg: instalacionImg
  },
  {
    title: 'Mantenimiento',
    subTitle: ' Los dejamos como nuevo',
    text: '  Hacemos cotizaciones directamente en su domicilio sin cargo alguno y le llevamos muestras para que usted pueda tomar una mejor decisión o si lo prefiere se le pueden enviar vía correo electrónico donde le desglosamos las características de lo que desea cotizar, tiempos de entrega y formas de pago, dando una mayor seguridad y certeza de lo que está adquiriendo.',
    bg: mantenimientoImg
  },
  {
    title: 'Reparación',
    subTitle: '¿Tienes algún problema? Contáctanos',
    text: '  Hacemos cotizaciones directamente en su domicilio sin cargo alguno y le llevamos muestras para que usted pueda tomar una mejor decisión o si lo prefiere se le pueden enviar vía correo electrónico donde le desglosamos las características de lo que desea cotizar, tiempos de entrega y formas de pago, dando una mayor seguridad y certeza de lo que está adquiriendo.',
    bg: reparacionImg
  }
];

function ServicesDescription(props) {
  const { id } = useParams();

  return (
    <>
      <Container
        className=" "
        style={{
          backgroundImage: ` url(${services[id].bg})`,
          backgroundRepeat: 'no-repeat',
          marginTop: '5%',
          backgroundSize: 'auto',
          backgroundPosition: 'top',
          minHeight: '45vh'
        }}
        fluid
      >
        <Row className=" px-4 pt-lg-3">
          <Col
            xl={1}
            md={1}
            lg={1}
            className="px-md-5 pt-5 pb-0 ml-md-5  mt-5 marg_movil_return "
          >
            <NavLink className="p-md-5 m-5" href="/services">
              <div className="text-white overlay_serv_return">
                <FontAwesomeIcon
                  size="2x"
                  className="rotate90"
                  icon={['fas', 'level-down-alt']}
                />
                <p>Regresar</p>
              </div>
            </NavLink>
          </Col>
          <Col md={8} lg={9} className=" mt-md-5 pt-md-5 marg_movil_services">
            <NavLink href="/services">
              <Row className="justify-content-center ">
                <h2 className="text-center text-white font-weight-bold">
                  {services[id].title}
                </h2>
              </Row>
            </NavLink>

            <Row className="justify-content-center">
              <p className="text-center text-white ">{services[id].subTitle}</p>
            </Row>
            <Row className="px-lg-0 mx-lg-0 mt-lg-4">
              <p className="text-justify  text-md-center text-white ">
                {services[id].text}
              </p>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="d-flex justify-content-center ">
          <Col>
            <ContactFormCont />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default ServicesDescription;
