import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  FormText,
  Input,
  Button
} from 'reactstrap';
import { AnimSend } from '../elements';
import { TagsInput, UploadFiles } from './questTypes';
import { EditableImg } from './editableEls';
import { LABELS_FORM_PRODUCT } from '../../constants/labels';
import { unitEquivalentsMtrs } from '../../constants/units';

import data from '../../assets/bodym/sending.json';

const initialForm = {
  short_name: '',
  long_name: '',
  description: '',
  categories: '',
  repetition_factor: 0,
  repetition_factor_unit: 'cm',
  width: 0,
  width_unit: 'cm',
  height: 0,
  height_unit: 'cm',
  units: [],
  price: 0,
  object: 'product',
  sku: '',
  brand: '',
  active: true,
  tags: [],
  colors: [],
  package_dimensions_width: 0,
  package_dimensions_length: 0,
  package_dimensions_height: 0,
  needs_shipping: true
};

function EditableProductForm({
  set_sel_form,
  allProducts,
  start_update_product,
  createProduct,
  addToast,
  cancelRequest,
  allCategories,
  isFetchingOne,
  selectProduct,
  lang
}) {
  const [formVals, setFormVals] = useState(initialForm);
  useEffect(() => {
    setFormVals({
      ...selectProduct[0],
      categories: selectProduct[0].categories[0]._id,
      repetition_factor: selectProduct[0].repetition_factor[0].value,
      repetition_factor_unit: selectProduct[0].repetition_factor[0].unit,
      width: selectProduct[0].width.value,
      width_unit: selectProduct[0].width.unit,
      height: selectProduct[0].height.value,
      height_unit: selectProduct[0].height.unit,
      package_dimensions_width: selectProduct[0].package_dimensions[0],
      package_dimensions_length: selectProduct[0].package_dimensions[1],
      package_dimensions_height: selectProduct[0].package_dimensions[2]
    });
  }, []);
  useEffect(() => {
    if (process.env.REACT_APP_PREFILL_DATA) {
      setFormVals((form) => ({
        ...form,
        short_name: 'nombre corto',
        long_name: 'nombre largo',
        description: 'descripción',
        categories: 'categoría',
        repetition_factor: 2.4,
        repetition_factor_unit: 'cm',
        width: 4.5,
        width_unit: 'cm',
        height: 0.56,
        height_unit: 'cm',
        units: ['meters', 'meters'],
        price: 0,
        object: 'product',
        sku: '',
        brand: '',
        active: false,
        tags: ['Egipcio'],
        colors: ['Naranja'],
        package_dimensions_width: 0,
        package_dimensions_length: 0,
        package_dimensions_height: 0,
        needs_shipping: true
      }));
    }
  }, [setFormVals]);
  const getValue = ({ target: { name, value, type, checked } }) => {
    const val = type === 'checkbox' ? checked : value;
    setFormVals({
      ...formVals,
      [name]: val
    });
  };
  const endAnim = () => {
    cancelRequest();
  };

  const startSend = (e) => {
    e.preventDefault();
    const {
      short_name,
      long_name,
      description,
      categories,
      repetition_factor,
      width,
      height,
      units,
      price,
      object,
      sku,
      brand,
      active,
      tags,
      colors,
      package_dimensions_width,
      package_dimensions_length,
      package_dimensions_height,
      needs_shipping
    } = formVals;
    const data = {
      short_name,
      long_name,
      price,
      repetition_factor: {
        value: repetition_factor,
        unit: repetition_factor_unit
      },
      object,
      width: { unit: width_unit, value: width },
      height: { unit: height_unit, value: height },
      units,
      dimensions: [width, height],
      sku,
      brand,
      active,
      tags,
      description,
      categories: [categories],
      colors,
      currency: 'mxn',
      package_dimensions: [
        package_dimensions_width,
        package_dimensions_length,
        package_dimensions_height
      ],
      needs_shipping
    };
    start_update_product(selectProduct[0]._id, data);
    set_sel_form('');
  };

  const {
    short_name,
    long_name,
    description,
    categories,
    repetition_factor,
    repetition_factor_unit,
    width,
    width_unit,
    height_unit,
    height,
    price,
    sku,
    brand,
    active,
    tags,
    colors,
    package_dimensions_width,
    package_dimensions_length,
    package_dimensions_height,
    needs_shipping
  } = formVals;

  return (
    <Form onSubmit={startSend}>
      {isFetchingOne ? (
        <Row className="justify-content-center">
          <Col xs={12} sm={6}>
            <AnimSend data={data} endAnim={endAnim} />
          </Col>
        </Row>
      ) : (
        <>
          <FormGroup row>
            <Label for="short_name" sm={3}>
              {LABELS_FORM_PRODUCT.SHORTNAME[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={short_name}
                required
                type="text"
                name="short_name"
                id="short_name"
                placeholder="nombre corto"
              />
              <FormText color="muted">
                {LABELS_FORM_PRODUCT.SHORTNAME_EXPL[lang]}
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="long_name" sm={3}>
              {LABELS_FORM_PRODUCT.LONGNAME[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={long_name}
                required
                type="text"
                name="long_name"
                id="long_name"
                placeholder="nombre largo"
              />
              <FormText color="muted">
                {LABELS_FORM_PRODUCT.LONGNAME_EXPL[lang]}
              </FormText>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="repetition_factor" sm={3}>
              {LABELS_FORM_PRODUCT.REPETITION_FACTOR[lang]}*
            </Label>
            <Col>
              <Input
                onChange={getValue}
                value={repetition_factor}
                required
                type="number"
                step="any"
                name="repetition_factor"
                id="repetition_factor"
                placeholder={
                  LABELS_FORM_PRODUCT.REPETITION_FACTOR.PLACEHOLDER[lang]
                }
              />{' '}
              <FormText color="muted">
                {LABELS_FORM_PRODUCT.REPETITION_FACTOR_EXPL[lang]}
              </FormText>
            </Col>
            <Col>
              <Input
                onChange={getValue}
                value={repetition_factor_unit}
                name="repetition_factor_unit"
                id="repetition_factor_unit"
                type="select"
              >
                {Object.keys(unitEquivalentsMtrs).map((key) => (
                  <option value={key}>{key}</option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="price" sm={3}>
              {LABELS_FORM_PRODUCT.PRICE[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={price}
                required
                type="number"
                name="price"
                id="price"
                placeholder={LABELS_FORM_PRODUCT.PRICE.PLACEHOLDER[lang]}
              />{' '}
              <FormText color="muted">
                {LABELS_FORM_PRODUCT.PRICE_EXPL[lang]}
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="sku" sm={3}>
              {LABELS_FORM_PRODUCT.SKU[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={sku}
                required
                type="text"
                name="sku"
                id="sku"
                placeholder="SKU"
              />
              <FormText color="muted">
                {LABELS_FORM_PRODUCT.SKU_EXPL[lang]}
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="brand" sm={3}>
              {LABELS_FORM_PRODUCT.BRAND[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={brand}
                // required
                type="text"
                name="brand"
                id="brand"
                placeholder="Marca del producto"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="active" sm={3}>
              {LABELS_FORM_PRODUCT.ACTIVE[lang]}*
            </Label>
            <Col sm={9}>
              <Label check="check" className="switch">
                <input
                  type="checkbox"
                  onChange={getValue}
                  name="active"
                  checked={active}
                />
                <span className="slider round" />
              </Label>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="needs_shipping" sm={3}>
              {LABELS_FORM_PRODUCT.SHIPPABLE[lang]}*
            </Label>
            <Col sm={9}>
              <Label check="check" className="switch">
                <input
                  type="checkbox"
                  onChange={getValue}
                  name="needs_shipping"
                  checked={needs_shipping}
                />
                <span className="slider round" />
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleSelect" sm={3}>
              {LABELS_FORM_PRODUCT.CATEGORY[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={categories}
                type="select"
                name="categories"
                id="categories"
              >
                <option>Elige tu catálogo</option>
                {allCategories?.map((e, i) => (
                  <option key={e._id} value={e._id}>
                    {e.name.es}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="description" sm={3}>
              {LABELS_FORM_PRODUCT.DESCRIPTION[lang]}*
            </Label>
            <Col sm={9}>
              <Input
                onChange={getValue}
                value={description}
                type="textarea"
                name="description"
                id="description"
                placeholder={LABELS_FORM_PRODUCT.DESCRIPTION_EXPL[lang]}
                required
              />
              <FormText>{LABELS_FORM_PRODUCT.DESCRIPTION_EXPL[lang]}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="width" sm={3}>
              {LABELS_FORM_PRODUCT.WIDTH[lang]}*
            </Label>
            <Col>
              <Input
                onChange={getValue}
                value={width}
                type="number"
                name="width"
                id="width"
                step="any"
                placeholder={LABELS_FORM_PRODUCT.WIDTH[lang]}
                required
              />
            </Col>
            <Col>
              <Input
                onChange={getValue}
                value={width_unit}
                name="width_unit"
                id="width_unit"
                type="select"
              >
                {Object.keys(unitEquivalentsMtrs).map((key) => (
                  <option value={key}>{key}</option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="height" sm={3}>
              {LABELS_FORM_PRODUCT.HEIGHT[lang]}*
            </Label>
            <Col>
              <Input
                onChange={getValue}
                value={height}
                type="number"
                step="any"
                name="height"
                id="height"
                placeholder={LABELS_FORM_PRODUCT.HEIGHT[lang]}
                required
              />
            </Col>
            <Col>
              <Input
                onChange={getValue}
                value={height_unit}
                name="height_unit"
                id="height_unit"
                type="select"
              >
                {Object.keys(unitEquivalentsMtrs).map((key) => (
                  <option value={key}>{key}</option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="package_dimensions" sm={3}>
              {LABELS_FORM_PRODUCT.PACKAGE_DIMENSIONS[lang]}*
            </Label>
            <Col sm={3}>
              <Input
                onChange={getValue}
                value={package_dimensions_width}
                type="number"
                name="package_dimensions_width"
                id="package_dimensions_width"
                placeholder={LABELS_FORM_PRODUCT.PACKAGE_DIMENSIONS_EXPL[lang]}
                required
              />
            </Col>
            <Col sm={3}>
              <Input
                onChange={getValue}
                value={package_dimensions_length}
                type="number"
                name="package_dimensions_length"
                id="package_dimensions_length"
                placeholder={LABELS_FORM_PRODUCT.PACKAGE_DIMENSIONS_EXPL[lang]}
                required
              />
            </Col>
            <Col sm={3}>
              <Input
                onChange={getValue}
                value={package_dimensions_height}
                type="number"
                name="package_dimensions_height"
                id="package_dimensions_height"
                placeholder={LABELS_FORM_PRODUCT.PACKAGE_DIMENSIONS_EXPL[lang]}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup className="justify-content-end" row>
            <Col xs={9}>
              <FormText>
                {LABELS_FORM_PRODUCT.PACKAGE_DIMENSIONS_EXPL[lang]}
              </FormText>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>{LABELS_FORM_PRODUCT.COLORS[lang]}</Label>
            <Col sm={9}>
              <TagsInput
                getValue={getValue}
                addToast={addToast}
                nameInput="colors"
                buttonColor="info"
                lang={lang}
                tags={colors}
              />
              <FormText>{LABELS_FORM_PRODUCT.COLORS_EXPL[lang]}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{LABELS_FORM_PRODUCT.TAGS[lang]}*</Label>
            <Col sm={9}>
              <TagsInput
                getValue={getValue}
                addToast={addToast}
                nameInput="tags"
                buttonColor="info"
                lang={lang}
                tags={tags}
              />
              <FormText>{LABELS_FORM_PRODUCT.TAGS_EXPL[lang]}</FormText>
            </Col>
          </FormGroup>
          <FormGroup>
            <Button
              // onClick={this.startSend}
              block="block"
              color="primary"
              disabled={short_name === '' || long_name === '' || repetition_factor === '' || width === '' || height === '' || price === '' || repetition_factor === '' || categories === '' ? true : false}
              type="submit"
            >
              Actualizar Producto
            </Button>
          </FormGroup>
        </>
      )}
    </Form>
  );
}

export default EditableProductForm;
