import React from 'react';
import { connect } from 'react-redux';
import { SideBar } from '../../components/platform';
import { USER_ROLES } from '../../constants/platform';
import { reset_signup } from '../../redux/actions/signup-actions';

function SideBarCont({ reset_signup, ...rest }) {
  return <SideBar reset_signup={reset_signup} {...rest} />;
}

const mapStateToProps = ({
  globalReducer: { lang },
  loginReducer: { user }
}) => ({
  lang,
  user_role: user?.isAdmin
    ? USER_ROLES.ADMIN
    : user?.role === 2
    ? USER_ROLES.MANAGER
    : USER_ROLES.USER
});

const mapActionsToProps = {
  reset_signup
};

export default connect(mapStateToProps, mapActionsToProps)(SideBarCont);
