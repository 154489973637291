import { fab } from '@fortawesome/free-brands-svg-icons';

import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faDownload,
  faLanguage,
  faPhoneVolume,
  faArrowAltCircleDown,
  faCopy,
  faLevelDownAlt,
  faEye,
  faEyeSlash,
  faSignOutAlt,
  faUser,
  faAngleDown,
  faTruckMoving,
  faHome,
  faPlusCircle,
  faBox,
  faUsers,
  faPlus,
  faSearch,
  faCog,
  faShoppingCart,
  faSortUp,
  faStoreSlash,
  faMinusCircle,
  faCalculator,
  faTimes,
  faEquals,
  faSpinner,
  faSave,
  faInfo,
  faBars,
  faCaretUp,
  faCaretDown,
  faSync
} from '@fortawesome/free-solid-svg-icons';

import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';

export const fontAwesomeLibrary = [
  fab,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faMapMarkerAlt,
  faEnvelope,
  faDownload,
  faLanguage,
  faPhoneVolume,
  faArrowAltCircleDown,
  faCopy,
  faLevelDownAlt,
  faEye,
  faEyeSlash,
  faSignOutAlt,
  faUser,
  faAngleDown,
  faTruckMoving,
  faHome,
  faPlusCircle,
  faBox,
  faUsers,
  faPlus,
  faSearch,
  faCog,
  faShoppingCart,
  faSortUp,
  faStoreSlash,
  faMinusCircle,
  faCalculator,
  faTimes,
  faEquals,
  faSpinner,
  faSave,
  faInfo,
  faBars,
  faCaretUp,
  faCaretDown,
  faSync
];
