import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ContactFormCont } from '../../containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Contact() {
  return (
    <>
      <Container fluid className="bg-light vh-100 pt-5">
        <Container className="vh-100 d-flex justify-content-center flex-column ">
          <Row>
            {' '}
            <Link to="/" className="text-dark">
              <FontAwesomeIcon
                size="lg"
                className="text-dark mr-1"
                icon={['fas', 'chevron-left']}
              />
              Regresar
            </Link>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={8} className="text-center my-2">
              <h2 className="display-4 text_main_color font-weight-bold">
                {/*Contacto*/}
                Neo Carpets
              </h2>
              <p className="text_orange font-weight-bold">
                Contáctanos para darte mas información
              </p>
            </Col>
          </Row>

          <hr className="underline_title " />

          <Row className="d-flex justify-content-center ">
            <Col sm={12} md={7}>
              <ContactFormCont />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Contact;
