import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Collapse,
  // CardHeader,
  CardFooter,
  CardBody,
  CardText,
  CardTitle,
  Button,
  ListGroupItem,
  ListGroup
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditableProfile } from "../../forms";
import { AddressFormCont } from "../../../containers";
// import {optDate} from '../../../utils';
import {
  LABELS_PLATFORM,
  MAX_USER_ADDRESSES
} from "../../../constants/platform";
import profile from "../../../assets/img/utils/profile_1.jpg";
import "../../../style/profile.css";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      addAddress: false
    };
  }
  toggleEdit = () => {
    this.setState({
      edit: !this.state.edit
    });
  };
  handleAddAddress = () => {
    this.setState({
      addAddress: !this.state.addAddress
    });
  };
  render() {
    const {
      className,
      user: {
        _id,
        firstname,
        lastname,
        // birthDate,
        telephone,
        email,
        profile_image,
        addresses = []
      },
      lang,
      ...rest
    } = this.props;
    const { edit, addAddress } = this.state;
    return (
      <Container className={`${className} animated fadeIn mb-2 text-center`}>
        <Row className="justify-content-center">
          {edit ? (
            <Col className="justify-content-center" xs={12} lg={12}>
              <EditableProfile
                updateUser={this.props.updateUser}
                toggleChangedUser={this.props.toggleChangedUser}
                cancelRequest={this.props.cancelRequest}
                toggleEdit={this.toggleEdit}
                user={this.props.user}
                lang={lang}
                {...rest}
              />
              <br />
              <Button onClick={this.toggleEdit} color="link">
                Terminar{" "}
              </Button>
            </Col>
          ) : (
            <Col className="justify-content-center" xs={12} lg={12}>
              <Fragment>
                <div className="media mx-auto d-flex justify-content-center overlapping_profile_image">
                  <img
                    src={profile_image ? profile_image : profile}
                    className="img_circular rounded-circle img-fluid"
                    width="140px"
                    alt="fotografia usuario"
                  />
                </div>
                <Card className={`pt-5 profile_card`}>
                  <CardBody className="d-flex flex-column justify-content-center">
                    <CardTitle>
                      {profile_image || firstname || lastname ? null : (
                        <Button onClick={this.props.fillProfile} color="link">
                          Añadir Nombre 
                        </Button>
                      )}
                      <br />
                      {firstname ? (
                        <Fragment>
                          <i className="fas fa-user" />{" "}
                          {`${firstname} ${lastname}`}
                        </Fragment>
                      ) : null}
                    </CardTitle>
                    {telephone ? (
                      <CardText
                        tag={"div"}
                        className="text-center d-flex flex-column">
                        <div>
                          <FontAwesomeIcon icon="mobile" />
                          &nbsp;{`  ${telephone} `}
                        </div>
                        <br />
                        <div>
                          <FontAwesomeIcon icon="at" />
                          &nbsp;{`  ${email} `}
                        </div>
                        <br />
                        {/* <div><FontAwesomeIcon icon="birthday-cake"/>&nbsp;{`  ${new Date(birthDate).toLocaleString('es-MX',optDate)} `}</div> */}
                      </CardText>
                    ) : null}
                    <ListGroup>
                      {addresses.map(
                        (
                          { line_1, state, city, postal_code, _id: idAddress },
                          o
                        ) => (
                          <ListGroupItem key={o}>
                            {`${line_1}, ${state}, ${city}, ${postal_code}`}{" "}
                            <br />
                            <Button
                              block
                              color="link"
                              onClick={() =>
                                this.props.setSelAddress(idAddress)
                              }
                              size="sm">
                              {LABELS_PLATFORM.EDIT[lang]}
                            </Button>
                          </ListGroupItem>
                        )
                      )}
                    </ListGroup>
                    {addresses.length < MAX_USER_ADDRESSES ? (
                      <Button color="link" onClick={this.handleAddAddress}>
                        Añadir Domicilio
                      </Button>
                    ) : null}

                    <Collapse isOpen={addAddress}>
                      <AddressFormCont
                        lang={lang}
                        // formExtVals={formVals}
                        // onSubmit={onSubmitAddress}
                        // getExtValue={getValue}
                        id_user={_id}
                        id="address_form"
                        className="py-2"
                      />
                    </Collapse>
                  </CardBody>
                  <CardFooter>
                    {firstname ? (
                      <Button onClick={this.toggleEdit} color="link">
                        Editar
                      </Button>
                    ) : null}
                  </CardFooter>
                </Card>
              </Fragment>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}
