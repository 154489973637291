import React from 'react';
import { Badge, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATUS_PRODUCT_ORDER_LABEL } from '../../constants/labels';
import { LABELS_PLATFORM, STATUS_PRODUCT } from '../../constants/platform';
import { formatNmxn } from '../../utils';

function ProductTableRow({
  allCategories,
  addStatusOrderProduct,
  product: { _id, images, short_name, price, sku, categories },
  product,
  statusProductOrder,
  quantity,
  canEdit,
  lang,
  orderId,
  order,
  isFetchingProduct,
  fetchingProductId,
  productOrderId,
  restrictedEditStatus
}) {
  const categoryProduct = allCategories?.filter(
    (e) => e._id === categories[0]
  )[0].name.es;

  const estado = statusProductOrder.slice(-1)[0]?.status ;
  console.log(estado, "hello")
  return (
    <tr>
      <td className="align-middle">
        {images && (
          <img
            src={images[1] === undefined ? images[0] : images[1]}
            alt={`prod ${short_name}`}
            className="img-fluid rounded"
            style={{ maxWidth: '60px' }}
          />
        )}
      </td>
      <td className="align-middle">{categoryProduct}</td>
      <td className="align-middle">{sku}</td>

{/*       {canEdit ? (
        <td className="align-middle px-2">
          {productOrderId === fetchingProductId && isFetchingProduct ? (
            <FontAwesomeIcon spin icon="cog" />
          ) : (
            <Input onChange={handleChangeStatus} value={statusProductOrder.slice(-1)[0]?.status} type="select">
              <option value="">{LABELS_PLATFORM.CHOOSE_VALUE[lang]}</option>

              {restrictedEditStatus
                ? Object.entries(STATUS_PRODUCT)
                    .slice(0, restrictedEditStatus)
                    .map(([k, v], i) => (
                      <option key={i} value={k}>
                        {v}
                      </option>
                    ))
                : Object.entries(STATUS_PRODUCT).map(([k, v], i) => (
                    <option key={i} value={k}>
                      {v}
                    </option>
                  ))}
            </Input>
          )}
        </td>
      ) : null} */}
      <td className="align-middle">{`${quantity} x ${price}`}</td>
      <td className="align-middle">{`$${formatNmxn(+quantity * +price)}`}</td>
    </tr>
  );
}

export default ProductTableRow;
