import React from "react";
import { connect } from "react-redux";
import { FormModal } from "../../components/platform";
import { set_sel_form } from "../../redux/actions/platform-actions";

function FormModalCont({ set_sel_form, ...rest }) {
  const toggle = () => {
    set_sel_form("");
  };
  return <FormModal toggle={toggle} {...rest} />;
}

const mapStateToProps = ({
  platformReducer: { sel_form },
  globalReducer: { lang }
}) => ({
  lang,
  sel_form,
  form_modal: sel_form !== "" ? true : false
});

const mapActionsToProps = {
  set_sel_form
};

export default connect(mapStateToProps, mapActionsToProps)(FormModalCont);
