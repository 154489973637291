import { useState } from 'react';

import { NavLink, Link } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfileNavCont, CartNavCont } from '../../containers';

import {
  PLATFORM_ROUTES,
  PLATF_SECTIONS_MENU,
  USER_ROLES
} from '../../constants/platform';
import logo from '../../images/footer/logoneocarpets.svg';
import '../../style/navb.css';

function Navb({
  token,
  user_role,
  reset_signup,
  log_out,
  location: { pathname },
  lang
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const closeToggle = () => {
    setIsOpen(false);
  };

  const logOut = () => {
    reset_signup();
    closeToggle();
  };

  const routeLinks = [
    {
      to: '/products',
      name: 'Productos'
    },
    {
      to: '/configurator',
      name: 'Diseña tu espacio'
    },
    {
      to: '/services',
      name: 'Servicios'
    },
    {
      to: '/login',
      name: 'Iniciar Sesión',
      logged: {
        to: '/platform',
        name: 'Plataforma'
      }
    }
  ];
  const isPlatform = pathname.indexOf(PLATFORM_ROUTES.PLATFORM) > -1;
  const isAuthenticated = !!token;

  return (
    <Container>
      <Navbar
        className="bg-white navb py-0"
        fixed="top"
        expand="lg"
        color="faded"
      >
        <NavbarBrand tag={Link} to="/" onClick={closeToggle}>
          <img
            src={logo}
            alt="main logo"
            className="navi_margin img-fluid logo_nav"
          />
        </NavbarBrand>
        <NavbarToggler className="bg-dark text-light" onClick={toggle}>
          <FontAwesomeIcon icon={isOpen ? 'times' : 'bars'} />
        </NavbarToggler>
        <Collapse className="" isOpen={isOpen} navbar>
          <Nav className="ml-auto " navbar>
            <NavItem className="align-self-center justify-content-center">
              <NavLink
                className="mx-md-2 mx-1 text-center text-dark "
                onClick={closeToggle}
                tag={Link}
                to="/"
              >
                <FontAwesomeIcon className="mr-lg-3" icon="home" />
              </NavLink>
            </NavItem>
            {routeLinks.map(({ to, name, scroll, logged }, i) => (
              <NavItem className="mx-md-3 ml-lg-auto" key={i}>
                <NavLink
                  className="mx-md-2 mx-1 text-center text-dark "
                  onClick={closeToggle}
                  tag={scroll ? 'a' : Link}
                  href={scroll ? scroll : to}
                  to={isAuthenticated && logged ? logged.to : to}
                >
                  <h6 className="mb-0">
                    {isAuthenticated && logged ? logged.name : name}
                  </h6>
                </NavLink>
              </NavItem>
            ))}
            {user_role === USER_ROLES.ADMIN ? null : <CartNavCont />}
          </Nav>
          {isAuthenticated && isPlatform ? (
            <>
              <Nav navbar className=" d-none d-lg-flex">
                <ProfileNavCont
                  className={`ml-2 ${isOpen ? 'text-light' : ''}`}
                />
              </Nav>
              <Nav
                navbar
                className="d-flex d-lg-none flex-row justify-content-around"
              >
                {PLATF_SECTIONS_MENU.map(({ to, name, visible_for_roles }, i) =>
                  visible_for_roles.indexOf(user_role) > -1 ? (
                    <NavItem key={i} className="mr-2">
                      <NavLink onClick={closeToggle} tag={Link} to={to}>
                        {name[lang]}
                      </NavLink>
                    </NavItem>
                  ) : null
                )}
                <NavItem className="text-center">
                  <NavLink onClick={logOut} tag={Link} to="/login">
                    <FontAwesomeIcon className="mr-3" icon="sign-out-alt" />
                    Salir
                  </NavLink>
                </NavItem>
              </Nav>
            </>
          ) : null}
        </Collapse>
      </Navbar>
    </Container>
  );
}

export default Navb;
