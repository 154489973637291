import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import cotizacionImg from '../../../../images/services_page/cotizacion.png';
import instalacionImg from '../../../../images/services_page/instalacion.png';
import mantenimientoImg from '../../../../images/services_page/mantenimiento.png';
import reparacionImg from '../../../../images/services_page/reparacion.png';
import '../../../../style/services_page.css';

import cotizacionImgR from '../../../../images/services_page/cotizacion2.png';
import instalacionImgR from '../../../../images/services_page/instalacion2.png';
import mantenimientoImgR from '../../../../images/services_page/mantenimiento2.png';
import reparacionImgR from '../../../../images/services_page/reparacion2.png';

const services = [
  {
    title: 'Cotización',
    text: ' Te ayudamos en todo el proceso',
    bg: cotizacionImg,
    bgR: cotizacionImgR
  },
  {
    title: 'Instalación',
    text: ' Brindamos para ti este servicio de calidad',
    bg: instalacionImg,
    bgR: instalacionImgR
  },
  {
    title: 'Mantenimiento',
    text: ' Los dejamos como nuevo',
    bg: mantenimientoImg,
    bgR: mantenimientoImgR
  },
  {
    title: 'Reparación',
    text: '¿Tienes algún problema? Contáctanos',
    bg: reparacionImg,
    bgR: reparacionImgR
  }
];

function servicesPage() {
  return (
    <Container className="" fluid>
      <Row className="">
        <Col
          sm={12}
          className="d-flex p-0 m-0 justify-content-center pt-5 flex-column flex-sm-row vh-100"
        >
          {services.map(({ title, text, bg, bgR }, i) => (
            <Col xs={12} sm={3} key={i} className="p-0 m-0 container-img-zoom">
              <Link className="zoom p-0 m-0" to={`/services/${i}`}>
                <img src={bg} alt="" className="d-none d-sm-inline zoom-in" />
                <img
                  src={bgR}
                  alt=""
                  className="d-sm-none zoom-in services_card_size"
                />
                <h2 className="text-white center_serv_title font-weight-bold">{title}</h2>
                <p className="text-white line-decor"> ─── </p>
                <p className="text-center text-white center_serv_subtitle">
                  {text}
                </p>
              </Link>
            </Col>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default servicesPage;
