import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button
} from 'reactstrap';

import img1 from '../../images/configurador/config1.png';
import img2 from '../../images/configurador/config2.png';

const collections = [
  {
    img: img1,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img2,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img1,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img2,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img1,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img2,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img1,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  },
  {
    img: img2,
    title: 'Aventura papel tapiz',
    subtitle: 'Winter Vol.1',
    currentPrice: '1,250.00mts',
    oldPrice: '1,250.00mts'
  }
];

function Collection() {
  return (
    <Container className="mt-5" fluid>
      <Row className="">
        <Col sm={12} className="my-5 text-center">
          <h2 className="font-weight-bold">Productos</h2>
        </Col>
      </Row>
      <Row className="py-2 border-top border-bottom  border-secondary">
        <Col sm={4} className="d-flex">
          <Label sm={5} className="font-weight-bold">
            ORDENAR POR
          </Label>
          <Input sm={7} type="select" name="orderSelect" id="orderSelect">
            <option>Alfabéticamente, A-Z</option>
            <option>Alfabéticamente, Z-A</option>
          </Input>
        </Col>

        <Col sm={6}></Col>
        <Col sm={2} className="d-flex align-items-center">
          <p className="m-0">20 articulos</p>
        </Col>
      </Row>

      <Row className="mt-5">
        {collections.map(
          ({ img, title, subtitle, currentPrice, oldPrice }, i) => (
            <Col sm={3} key={i}>
              <Card className="mb-4">
                <CardImg className="" src={img} alt="Img" />
                <CardBody>
                  <CardTitle tag="h3"></CardTitle>
                  <CardSubtitle className="font-weight-bold">
                    {title}
                    <br />
                    {subtitle}
                  </CardSubtitle>

                  <CardText className="font-weight-bold">
                    <p>
                      {currentPrice}
                      &nbsp;&nbsp;
                      <strike className="text_orange">{oldPrice}</strike>
                    </p>
                  </CardText>
                  <Button className="btn-primary">Oferta</Button>
                </CardBody>
              </Card>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
}

export default Collection;
