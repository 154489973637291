import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import hero from '../../images/carousel/hero.png';
import tapiz1 from '../../images/carousel/tapiz1.jpg';
import tapiz2 from '../../images/carousel/tapiz2.png';
import tapiz3 from '../../images/carousel/tapiz3.jpg';
import muebles1 from '../../images/carousel/muebles1.png';
import muebles2 from '../../images/carousel/muebles2.png';
import muebles3 from '../../images/carousel/muebles3.jpg';
import piso1 from '../../images/carousel/piso1.png';
import piso2 from '../../images/carousel/piso2.png';
import piso3 from '../../images/carousel/piso3.png';
import pasto1 from '../../images/carousel/pasto1.jpg';
import pasto2 from '../../images/carousel/pasto2.jpg';
import pasto3 from '../../images/carousel/pasto3.png';

import '../../style/carousel.css';

const items = [
  {
    src: tapiz1,
    src1: tapiz2,
    src2: tapiz3,
    altText: 'Tapiz',
    caption: 'Tapiz',
    key: '1'
  },
  {
    src: muebles1,
    src1: muebles2,
    src2: muebles3,
    altText: 'Muebles',
    caption: 'Muebles',
    key: '2'
  },
  {
    src: piso1,
    src1: piso2,
    src2: piso3,
    altText: 'Pisos',
    caption: 'Pisos',
    key: '3'
  },
  {
    src: pasto1,
    src1: pasto2,
    src2: pasto3,
    altText: 'Muros Verdes',
    caption: 'Muros Verdes',
    key: '4'
  }
];

const CarouselSlide = () => {
  const [indexPage, setIsIndexPage] = useState(0);

  const totalPages = items.length;

  // const decPag = () => {
  //   const prevIndex = indexPage - 1;
  //   if (prevIndex >= 0) {
  //     setIsIndexPage(prevIndex);
  //   } else {
  //     setIsIndexPage(0);
  //   }
  // };

  const incPag = useCallback(() => {
    const nextIndex = indexPage + 1;
    if (nextIndex < totalPages) {
      setIsIndexPage(nextIndex);
    } else {
      setIsIndexPage(0);
    }
  }, [indexPage, totalPages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      incPag();
    }, 10000);
    return () => clearTimeout(timer);
  }, [indexPage, incPag]);

  return (
    <Container className="vh-100" fluid>
      <Row>
        <div className="d-flex flex-column justify-content-center text_custom_caru text-md-left text-center">
          <h1 className="text_pink custom_landing font-weight-bold">
            Encuentra el papel tapiz de tus sueños.
          </h1>
          <div className="mobile_only">
            <p className="font-weight-light">
              Personaliza muros a tu gusto y descubre tus opciones
            </p>
            <Link to="/configurator">
              <Button
                color="primary"
                className="rounded-pill  mr-md-auto px-4 py-3"
              >
                Visualiza tu espacio
              </Button>
            </Link>
          </div>
        </div>
      </Row>

      <Row className=" justify-content-between">
        <>
          <div className="d-md-flex d-none flex-column align-self-end custom_first_cont">
            <motion.div
              key={indexPage}
              initial={{ x: 50, opacity: 0.5 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -800, opacity: 0.5 }}
            >
              <img
                src={items[indexPage].src}
                alt={items[indexPage].altText}
                className="img-fluid shadow-lg custom_first_image"
              />
            </motion.div>
          </div>

          <div className="custom_second_cont">
            <div className="">
              <motion.div
                key={indexPage}
                initial={{ x: -900, opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 50, opacity: 0.5 }}
              >
                <img
                  src={items[indexPage].src1}
                  alt={items[indexPage].altText}
                  className="img-fluid shadow-lg custom_second_image"
                />
                <img
                  src={hero}
                  alt=""
                  className="d-md-inline d-none img-fluid hero_img"
                />
              </motion.div>
            </div>
          </div>

          <div className="d-md-flex d-none flex-column">
            <motion.div
              key={indexPage}
              initial={{ x: 600, opacity: 0.5 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 60, opacity: 0.5 }}
            >
              <img
                src={items[indexPage].src2}
                alt={items[indexPage].altText}
                className="img-fluid shadow-lg custom_third_image"
              />
            </motion.div>
          </div>
        </>
      </Row>

      <Row className=" custom_indicator">
        <Col className="d-flex text-left" md={4}>
          <ol className=" carousel-indicators">
            {items.map((_el, i) => (
              <>
                <div className="carousel-font ml-2">
                  {items[i].altText}
                  <li
                    key={i}
                    className={i === indexPage ? 'active' : ''}
                    onClick={() => setIsIndexPage(i)}
                  ></li>
                </div>
              </>
            ))}
          </ol>
        </Col>
      </Row>
    </Container>
  );
};
export default CarouselSlide;
