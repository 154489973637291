export const START_POST_ADDRESS = "START_POST_ADDRESS";
export const START_UPDATE_ADDRESS = "START_UPDATE_ADDRESS";
export const REQUEST_ADDRESS = "REQUEST_ADDRESS";
export const CANCEL_ADDRESS = "CANCEL_ADDRESS";
export const SUCCESS_ADDRESS = "SUCCESS_ADDRESS";
export const ERROR_ADDRESS = "ERROR_ADDRESS";
export const RESET_ADDRESS = "RESET_ADDRESS";
export const SET_ALL_ADDRESSES = "SET_ALL_ADDRESSES";
export const SET_ONE_ADDRESS = "SET_ONE_ADDRESS";
export const SET_SEL_ADDRESS = "SET_SEL_ADDRESS";

export const start_post_address = (dataAddress) => ({
  type: START_POST_ADDRESS,
  dataAddress
});

export const update_address = (idAddress, dataAddress) => ({
  type: START_UPDATE_ADDRESS,
  idAddress,
  dataAddress
});

export const set_sel_address = (selAddress) => ({
  type: SET_SEL_ADDRESS,
  selAddress
});

export const request_address = () => ({
  type: REQUEST_ADDRESS,
  isFetching: true
});

export const cancel_address = () => ({
  type: CANCEL_ADDRESS,
  isFetching: false
});

export const success_address = (message) => ({
  type: SUCCESS_ADDRESS,
  message
});

export const error_address = (error) => ({
  type: ERROR_ADDRESS,
  error
});

export const reset_address = () => ({
  type: RESET_ADDRESS,
  message: false,
  error: false
});
