import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'reactstrap';
import { UserAccountCont } from '../../containers';
import { USER_SIGNUP_MESSAGES } from '../../constants/messages';
import {
  SLIDE_MENU_NAMES_CONTENT,
  PLATFORM_ROUTES
} from '../../constants/platform';

function InlineSignup({ lang, setSlideContent, proceedToVerify }) {
  useEffect(() => {
    if (proceedToVerify) {
      setSlideContent(SLIDE_MENU_NAMES_CONTENT.VERIFY);
    }
  }, [proceedToVerify, setSlideContent]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Alert color="info">
            {USER_SIGNUP_MESSAGES.CREATE_ACCOUNT[lang]}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert>
            <Link to={`${PLATFORM_ROUTES.LOGIN}`}>
              {USER_SIGNUP_MESSAGES.ACCESS_ACCOUNT[lang]}
            </Link>
          </Alert>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <UserAccountCont />
        </Col>
      </Row>
    </Container>
  );
}

export default InlineSignup;
