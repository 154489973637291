import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import confi1 from '../../images/configurador/confi1.jpg';
import confi2 from '../../images/configurador/confi2.jpeg';
import confi3 from '../../images/configurador/confi3.jpg';
import confi4 from '../../images/configurador/confi4.jpeg';
import imgOn from '../../images/configurador/confi0.png';
import tap from '../../images/configurador/tap.svg';

import '../../style/configurator.css';

function Configurator() {
  return (
  
 
      <Container   className="d-flex bg_dark flex-column justify-content-center pb-5"   fluid>
        
        <Row className="my-5">
          <Col className="text-center">
            <h1 className="text_orange font-weight-bold">Visualizador</h1>
            <h6 className="text-white">Personaliza tu espacio y visualiza  </h6><img src={tap} alt="tap" className="tap d-flex d-md-none" />
          </Col>
        </Row>
        
        <Row className="d-flex p-0 m-0 mb-md-5 justify-content-arround flex-column flex-md-row">
          <Col sm={3} className="container_img p-0 d-none d-md-flex ">
            <div className="">
              <img
                src={confi1}
                alt=""
                className="img-fluid z-btm img_tapiz"
              />

              <img
                src={imgOn}
                alt=""
                className="img-fluid z-top pt-lg-5 mt-lg-5 img_confi zoom"
              />
            </div>
            <div className="overlay_config">
            </div>
              <Link to="/configurator">
                <Button className="  p-3 border-0  px-5 py-3  rounded-pill btn-primary overlay_btn">
                  <h6 className="mb-0">Ir al configurador</h6>
                </Button>
              </Link>
          </Col>

          <Col sm={3} className="container_img p-0 d-none d-md-flex ">
            <div className="">
              <img
                src={confi2}
                alt=""
                className="img-fluid z-btm img_tapiz"
              />

              <img
                src={imgOn}
                alt=""
                className="img-fluid z-top pt-lg-5 mt-lg-5  img_confi zoom"
              />
            </div>
            <div className="overlay_config">
            </div>
              <Link to="/configurator">
                <Button className=" p-3 border-0  px-5 py-3  rounded-pill btn-primary overlay_btn">
                  <h6 className="mb-0">Ir al configurador</h6>
                </Button>
              </Link>
          </Col>

          <Col sm={3} className="container_img p-0 d-flex">
            <div className="">
              <img
                src={confi3}
                alt=""
                className="img-fluid z-btm  img_tapiz"
              />

              <img
                src={imgOn}
                alt=""
                className="img-fluid z-top pt-lg-5 mt-lg-5  img_confi zoom"
              />
            </div>
            <div className="overlay_config">
            </div>
              <Link to="/configurator">
                <Button className=" p-3 border-0  px-5 py-3  rounded-pill btn-primary overlay_btn">
                  <h6 className="mb-0">Ir al configurador</h6>
                </Button>
              </Link>
          </Col>

          <Col sm={3} className="container_img p-0 d-flex">
            <div className="">
              <img
                src={confi4}
                alt=""
                className="img-fluid z-btm img_tapiz"
              />

              <img
                src={imgOn}
                alt=""
                className="img-fluid z-top pt-lg-5 mt-lg-5  img_confi zoom"
              />
            </div>
            <div className="overlay_config">
            </div>
              <Link to="/configurator">
                <Button className=" border-0 px-5 py-3  rounded-pill btn-primary overlay_btn">
                  <h6 className="mb-0">Ir al configurador</h6>
                </Button>
              </Link>
          </Col>



        </Row>

        <Row>
          <Col className="text-white text-center d-none d-sm-block">
            <FontAwesomeIcon size="1x" className="" icon={['fas', 'sort-up']} />
            <h6>Selecciona uno</h6>
          </Col>
        </Row>
      </Container>
     
  );
}

export default Configurator;
