import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Landing,
  DeckingR,
  ProductsR,
  ServicesPage,
  ProductsPage,
  ConfiguratorPage,
  ServicesDescription,
  Contact,
  CollectionPage,
  CalculatorPage,
  Login,
  OrderPage,
  ResetPass
} from './components/routes';

import { Toaster } from './components/elements';
import {
  PlatformCont,
  NavbCont,
  SlideMenuCont,
  CartCont,
  CheckoutCont,
  CreatedOrderCont,
  ShippingAddressCont,
  UnitsCalculatorCont,
  InlineSignupCont,
  InlineVerifyCont,
  VerifyCont,
  CheckLoginCont,
  VerifyPaymentCont
} from './containers';
import { WhatsappIcon } from './components/elements';
import { useScrollToTop } from './hooks';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fontAwesomeLibrary } from './config/fontawesome';
import {
  PLATFORM_ROUTES,
  SLIDE_MENU_CONTENT,
  SLIDE_MENU_NAMES_CONTENT,
  USER_ROLES
} from './constants/platform';

import 'animate.css';
import './App.css';

library.add(...fontAwesomeLibrary);

function App({
  location,
  user_role,
  token,
  match,
  slide_content,
  lang = 'es'
}) {
  useScrollToTop();
  const renderSlideMenuCont = (content) => {
    switch (content) {
      case SLIDE_MENU_NAMES_CONTENT.CART:
        return <CartCont />;
      case SLIDE_MENU_NAMES_CONTENT.CHECKOUT:
        return <CheckoutCont />;
      case SLIDE_MENU_NAMES_CONTENT.CREATED_ORDER:
        return <CreatedOrderCont />;
      case SLIDE_MENU_NAMES_CONTENT.UNITS_CALCULATOR:
        return <UnitsCalculatorCont />;
      case SLIDE_MENU_NAMES_CONTENT.ADD_ADDRESS:
        return <ShippingAddressCont />;
      case SLIDE_MENU_NAMES_CONTENT.SIGNUP:
        return <InlineSignupCont />;
      case SLIDE_MENU_NAMES_CONTENT.VERIFY:
        return <InlineVerifyCont />;
      default:
        return null;
    }
  };
  return (
    <main scroll="no" className="App">
      <CheckLoginCont />
      <NavbCont match={match} location={location} />
      <Toaster />
      {user_role === USER_ROLES.ADMIN ? null : <WhatsappIcon />}

      <SlideMenuCont
        title={
          SLIDE_MENU_CONTENT[slide_content]
            ? SLIDE_MENU_CONTENT[slide_content].title[lang]
            : ''
        }
      >
        {renderSlideMenuCont(slide_content)}
      </SlideMenuCont>
      <TransitionGroup appear={true} exit={true}>
        <CSSTransition
          key={location.key}
          timeout={token ? 0 : 400}
          classNames={token ? '' : 'fadeTranslate'}
        >
          <div className="wrapper">
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/products" component={ProductsR} />
              <Route exact path="/decking" component={DeckingR} />
              <Route exact path="/services" component={ServicesPage} />

              <Route path="/login" component={Login} />
              <Route
                path="/reset_password/:reset_pass_token"
                component={ResetPass}
              />

              <Route path={`/${PLATFORM_ROUTES.PLATFORM}`}>
                <PlatformRoute
                  exact
                  token={token}
                  component={PlatformCont}
                  path={`/${PLATFORM_ROUTES.PLATFORM}`}
                />
                <PlatformRoute
                  token={token}
                  component={PlatformCont}
                  path={`/${PLATFORM_ROUTES.PLATFORM}/:section`}
                />
              </Route>
              <Route exact path="/collection" component={CollectionPage} />
              <Route exact path="/calculator" component={CalculatorPage} />
              <Route exact path="/configurator" component={ConfiguratorPage} />
              <Route exact path="/contact" component={Contact} />

              <Route path="/orders/:orderId">
                <OrderPage />
              </Route>

              <Route path="/products/:id">
                <ProductsPage />
              </Route>
              <Route path="/services/:id">
                <ServicesDescription />
              </Route>
              <Route path="/verify/:userId/:verificationCode">
                <VerifyCont />
              </Route>
              <Route path="/payment">
                <VerifyPaymentCont />
              </Route>
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
}

const setUserRole = (user = {}) => {
  if (!user) {
    return null;
  }
  return user.isAdmin
    ? USER_ROLES.ADMIN
    : user.role === 2
    ? USER_ROLES.MANAGER
    : USER_ROLES.USER;
};

// export default connect(
//   ({
//     platformReducer,
//     loginReducer: { token, user },
//     globalReducer: { lang }
//   }) => ({
//     ...platformReducer,
//     token,
//     user_role: setUserRole(user),
//     lang
//   }),
//   {
//     set_lang,
//     add_toast
//   }
// )(NavbCont);

const PlatformRoute = ({ token, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/${PLATFORM_ROUTES.LOGIN}`} />
        )
      }
    />
  );
};

// const mapStateToProps = ({ loginReducer, platformReducer }) => ({
//   ...loginReducer,
//   ...platformReducer
// });

export default connect(
  ({
    platformReducer,
    loginReducer: { token, user },
    globalReducer: { lang }
  }) => ({
    ...platformReducer,
    token,
    user_role: setUserRole(user),
    lang
  }),
  {}
)(App);
