export const START_UPDATE_USER = 'START_UPDATE_USER';
export const START_ACTIVATE_USER = 'START_ACTIVATE_USER';
export const START_DEACTIVATE_USER = 'START_DEACTIVATE_USER';
export const START_ADD_ADDRESSS_USER = 'START_ADD_ADDRESSS_USER';
export const REQUEST_USER = 'REQUEST_USER';
export const CANCEL_USER = 'CANCEL_USER';

export const REQUEST_ONE_USER = 'REQUEST_ONE_USER';
export const CANCEL_ONE_USER = 'CANCEL_ONE_USER';

export const SUCCESS_USER = 'SUCCESS_USER';
export const ERROR_USER = 'ERROR_USER';
export const RESET_USER = 'RESET_USER';
export const RESET_ONE_USER = 'RESET_ONE_USER';

export const START_ADD_POINTS_USER = 'START_ADD_POINTS_USER';
export const START_GET_BALANCE_USER = 'START_GET_BALANCE_USER';
export const START_GET_USAGE_USER = 'START_GET_USAGE_USER';
export const SET_BALANCE_USER = 'SET_BALANCE_USER';

export const START_SEARCH_USERS = 'START_SEARCH_USERS';
export const LIST_USERS = 'LIST_USERS';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_FITLERED_USERS = 'SET_FITLERED_USERS';
export const SET_SUGGESTED_USERS = 'SET_SUGGESTED_USERS';
export const SET_STATUS_USER = 'SET_STATUS_USER';

export const list_users = () => ({
  type: LIST_USERS
});

export const start_get_usage_user = (user_id) => ({
  type: START_GET_USAGE_USER,
  user_id
});
export const start_search_users = (searchURL) => ({
  type: START_SEARCH_USERS,
  searchURL
});

export const set_filtered_users = (filteredUsers) => ({
  type: SET_FITLERED_USERS,
  filteredUsers
});

export const set_suggested_users = (suggestedUsers) => ({
  type: SET_SUGGESTED_USERS,
  suggestedUsers
});

export const set_all_users = (allUsers) => ({
  type: SET_ALL_USERS,
  allUsers
});

export const start_activate_user = (dataVerify) => ({
  type: START_ACTIVATE_USER,
  dataVerify
});

export const start_deactivate_user = (dataDeactivate) => ({
  type: START_DEACTIVATE_USER,
  dataDeactivate
});

export const post_data_user = (dataUser, images) => ({
  type: START_UPDATE_USER,
  dataUser,
  images
});

export const start_add_address_user = (user_id, dataAddress) => ({
  type: START_ADD_ADDRESSS_USER,
  user_id,
  dataAddress
});

export const start_add_points_user = (user_id, dataPoints) => ({
  type: START_ADD_POINTS_USER,
  user_id,
  dataPoints
});

export const check_balance_user = (user_id) => ({
  type: START_GET_BALANCE_USER,
  user_id
});

export const set_balance_user = (userBalance) => ({
  type: SET_BALANCE_USER,
  userBalance
});

export const request_user = () => ({
  type: REQUEST_USER,
  isFetching: true
});

export const cancel_user = () => ({
  type: CANCEL_USER,
  isFetching: false
});

export const request_one_user = () => ({
  type: REQUEST_ONE_USER,
  isFetchingOne: true
});

export const cancel_one_user = () => ({
  type: CANCEL_ONE_USER,
  isFetchingOne: false
});

export const success_user = (message) => ({
  type: SUCCESS_USER,
  message
});

export const error_user = (error) => ({
  type: ERROR_USER,
  error
});

export const reset_user = () => ({
  type: RESET_USER,
  message: false,
  error: false,
  isFetching: false
});

export const reset_one_user = () => ({
  type: RESET_ONE_USER,
  message: false,
  error: false,
  isFetchingOne: false
});

export const set_user_balance = (userBalance) => ({
  type: SET_BALANCE_USER,
  userBalance
});

export const set_status_user = (statusUser) => ({
  type: SET_STATUS_USER,
  statusUser
});
