import React from 'react';

import { Container } from 'reactstrap';
import { Footer } from '../../components/sections';
import { Products } from '../../components/landing';

function ProductsR() {
  return (
    <>
      <Container fluid className="pt-5 px-0 mx-0">
        <Products />
      </Container>
      <Footer />
    </>
  );
}

export default ProductsR;
