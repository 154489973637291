import React, { useState } from "react";
import { Row, Collapse, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shorten } from "../../utils";

import "../../style/toggle_text.css";

function ToggleText({
  tag: Tag = "div",
  maxChars,
  className,
  children = "",
  light,
  links
}) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const needsShortening = children.length > maxChars;
  return (
    <Tag
      onClick={toggle}
      className={`toggle_text ${open ? "active" : ""} ${className}`}>
      <Row>
        {needsShortening ? (
          <Col>
            {!open && shorten(children, maxChars)}
            <Collapse isOpen={open}>
              {open ? (
                <>
                  <div>{children}</div>
                  <div>
                    {links.map(({ ref, text }, i) => (
                      <a
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ref}>
                        {text}
                      </a>
                    ))}
                  </div>{" "}
                </>
              ) : (
                children.slice(maxChars)
              )}
            </Collapse>
          </Col>
        ) : (
          <Col>{children}</Col>
        )}
      </Row>
      {needsShortening && (
        <Row className="justify-content-center">
          <Col className={`text-center ${light ? "text-light" : "text-dark"}`}>
            <FontAwesomeIcon icon={["fas", open ? "angle-up" : "angle-down"]} />
          </Col>
        </Row>
      )}
    </Tag>
  );
}

export default ToggleText;
