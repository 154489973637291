import React from 'react';
import { connect } from 'react-redux';
import { Platform } from '../../components/routes';
import { USER_ROLES } from '../../constants/platform';

function PlatformCont({ ...rest }) {
  return <Platform {...rest} />;
}

const mapStateToProps = ({
  platformReducer,
  globalReducer: { lang },
  loginReducer: { user, token }
}) => ({
  ...platformReducer,
  lang,
  isAuthenticated: !!user && !!token,
  user_role: user?.isAdmin
    ? USER_ROLES.ADMIN
    : user?.role === 2
    ? USER_ROLES.MANAGER
    : USER_ROLES.USER
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(PlatformCont);
