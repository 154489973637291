import {
  REQUEST_ORDER,
  CANCEL_ORDER,
  REQUEST_ONE_ORDER,
  CANCEL_ONE_ORDER,
  SUCCESS_ORDER,
  ERROR_ORDER,
  RESET_ORDER,
  SET_ALL_ORDERS,
  SET_SELECT_ORDER,
  REQUEST_ONE_ORDER_PRODUCT,
  CANCEL_ONE_ORDER_PRODUCT,
  SET_FETCHING_PRODUCT_ID,
  SET_ORDER_CHECKOUT
} from '../actions/order-actions';

export const orderReducer = (
  state = {
    isFetching: false,
    isFetchingOne: false,
    message: null,
    error: null,
    allOrders: null,
    selectOrder: null,
    isFetchingProduct: false,
    fetchingProductId: null,
    orderCheckout: null
  },
  action
) => {
  const {
    type,
    isFetching,
    isFetchingOne,
    message,
    error,
    allOrders,
    selectOrder,
    isFetchingProduct,
    fetchingProductId,
    orderCheckout
  } = action;
  switch (type) {
    case REQUEST_ORDER:
      return { ...state, isFetching };
    case CANCEL_ORDER:
      return { ...state, isFetching };
    case REQUEST_ONE_ORDER:
      return { ...state, isFetchingOne };
    case CANCEL_ONE_ORDER:
      return { ...state, isFetchingOne };
    case REQUEST_ONE_ORDER_PRODUCT:
      return { ...state, isFetchingProduct };
    case CANCEL_ONE_ORDER_PRODUCT:
      return { ...state, isFetchingProduct };
    case SET_FETCHING_PRODUCT_ID:
      return { ...state, fetchingProductId };
    case SET_ORDER_CHECKOUT:
      return { ...state, orderCheckout };
    case SUCCESS_ORDER:
      return { ...state, message };
    case ERROR_ORDER:
      return { ...state, error };
    case SET_ALL_ORDERS:
      return { ...state, allOrders };
    case SET_SELECT_ORDER:
      return { ...state, selectOrder };
    case RESET_ORDER:
      return { ...state, isFetching, isFetchingOne, error, message };

    default:
      return { ...state };
  }
};
