import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
  FormText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class InputSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getLocalValue = ({ target: { value, name } }) => {
    const {
      charact: { multilanguage },
      lang,
      value: propVal
    } = this.props;
    let val = {
      target: {
        name,
        value: multilanguage ? { ...propVal, [lang]: value } : value
      }
    };
    this.props.getValue(val);
  };
  render() {
    const {
      charact: {
        required,
        sizeSM,
        forL,
        id,
        label,
        valid,
        invalid,
        icon,
        type,
        rango,
        pattern,
        limit,
        name,
        regExp,
        longQ,
        multilanguage
      },
      value,
      lang
    } = this.props;
    let testR = "";
    const localVal = multilanguage ? value[lang] : value;
    const maxVal = limit ? limit : rango ? rango[1] : -1;
    if (regExp) {
      testR = new RegExp(regExp);
    }
    return (
      <FormGroup row>
        <Label sm={sizeSM[0]} size="lg" for={forL} id={id}>
          <FontAwesomeIcon icon={icon} />
          &nbsp;
          {label[lang]}
        </Label>
        <Col xs={12} sm={sizeSM[1]}>
          <Input
            onChange={this.getLocalValue}
            className={type === "range" ? "slider_range" : "custom"}
            value={localVal}
            valid={valid ? testR.test(localVal) : null}
            invalid={
              invalid && localVal.length >= 10 ? !testR.test(localVal) : null
            }
            pattern={pattern ? pattern : null}
            id={id}
            type={type}
            autoFocus
            required={required}
            min={rango && rango[0]}
            max={maxVal}
            name={name}
          />
          <FormFeedback className="animated fadeIn" valid>
            Súper
          </FormFeedback>
          <FormFeedback>Ingresa 10 dígitos</FormFeedback>
          <FormText>{longQ[lang]}</FormText>
        </Col>
      </FormGroup>
    );
  }
}
