import React from 'react';
import { Footer } from '..';
import { Collection } from '../landing';

function CollectionPage() {
  return (
    <>
      <Collection />
      <Footer />
    </>
  );
}

export default CollectionPage;
