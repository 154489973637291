import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { UsersTable } from '../../components/platform/tables';
import {
  list_users,
  start_activate_user,
  start_deactivate_user
} from '../../redux/actions/user-actions';

function UsersTableCont({
  start_activate_user,
  start_deactivate_user,
  list_users,
  allUsers,
  ...rest
}) {
  useEffect(() => {
    if (allUsers && !allUsers.length) {
      list_users();
    }
  }, [allUsers, list_users]);
  return (
    <UsersTable
      startActivateUser={start_activate_user}
      startDeactivateUser={start_deactivate_user}
      allUsers={allUsers}
      {...rest}
    />
  );
}

const mapStateToProps = ({ globalReducer: { lang }, userReducer }) => ({
  ...userReducer,
  lang
});

const mapActionsToProps = {
  list_users,
  start_activate_user,
  start_deactivate_user
};

export default connect(mapStateToProps, mapActionsToProps)(UsersTableCont);
