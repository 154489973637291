import React from "react";
import { connect } from "react-redux";
import { ProfileForm } from "../../components/forms";
import { cancel_user, post_data_user } from "../../redux/actions/user-actions";
import { set_sel_form } from "../../redux/actions/platform-actions";

function ProfileFormCont({
  form_modal,
  set_sel_form,
  post_data_user,
  cancel_user,
  ...rest
}) {
  const cancelRequest = () => {
    cancel_user();
    if (form_modal) {
      set_sel_form("");
    }
  };
  return (
    <ProfileForm
      updateUser={post_data_user}
      cancelRequest={cancelRequest}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  userReducer,
  platformReducer: { sel_form },
  globalReducer: { lang }
}) => ({
  ...userReducer,
  form_modal: sel_form !== "" ? true : false,
  lang
});

const mapActionsToProps = {
  cancel_user,
  post_data_user,
  set_sel_form
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileFormCont);
