import React, { Component } from "react";
import {
  InputGroup,
  Input,
  ButtonGroup,
  Button,
  Label,
  ListGroupItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class EditableDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      prevValue: ""
    };
  }
  componentDidUpdate = (prevProps) => {
    const { editAll, setData } = this.props;
    const { prevValue } = this.state;
    if (!prevProps.editAll && editAll) {
      this.setState(() => {
        return { editing: true };
      });
    }
    if (setData && setData !== "" && prevValue === "") {
      this.setState({
        prevValue: setData
      });
    }
  };
  enableEdit = () => {
    this.setState({ editing: true });
  };
  cancelEditing = () => {
    const { nameInput } = this.props;
    const { prevValue } = this.state;
    this.setState({
      editing: false
    });
    this.props.editValue({ target: { value: prevValue, name: nameInput } });
  };
  doneEditing = () => {
    this.setState({
      editing: false
    });
  };
  keyHandler = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      this.doneEditing();
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  };
  getValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    const { setData, className, title, editedValue, nameInput } = this.props;
    const { editing, prevValue } = this.state;
    let date = "",
      ymd = "";
    if (editedValue !== "") {
      const [y, m, d] = editedValue.split("-");
      date = new Date(`${y} ${parseInt(m, 10)} ${parseInt(d, 10)}`);
      ymd = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    } else {
      date = new Date(setData);
      ymd = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    }

    return (
      <ListGroupItem
        onKeyUp={this.keyHandler}
        className={`${className} py-0`}
        color={editedValue !== "" && editedValue !== prevValue ? "info" : ""}>
        <Label>
          <b>{title}</b>
        </Label>
        {editing ? (
          <InputGroup className="my-2 justify-content-center animated fadeIn">
            <Input
              bsSize="sm"
              onChange={this.props.editValue}
              value={editedValue}
              name={nameInput}
              type="date"
            />
            <ButtonGroup>
              <Button size="sm" onClick={this.doneEditing} color="primary">
                <FontAwesomeIcon icon="check" />
              </Button>
              <Button size="sm" onClick={this.cancelEditing}>
                <FontAwesomeIcon icon="times" />
              </Button>
            </ButtonGroup>
          </InputGroup>
        ) : (
          <p className="card-text">
            {ymd} &nbsp;
            <Button color="link" onClick={this.enableEdit}>
              <FontAwesomeIcon icon="edit" /> Editar
            </Button>
          </p>
        )}
      </ListGroupItem>
    );
  }
}
