import React from "react";
import {
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
  FormText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InputHandle({
  charact: { id, type, required, name, forL, sizeSM, icon, label, longQ },
  value,
  getHandle,
  localLang
}) {
  return (
    <FormGroup row>
      <Label sm={sizeSM[0]} size="lg" for={forL} id={id}>
        <FontAwesomeIcon icon={icon} />
        &nbsp;
        {label}
      </Label>
      <Col xs={12} sm={sizeSM[1]}>
        <Input
          onChange={getHandle}
          className={"custom"}
          value={value}
          id={id}
          type={type ? type : "text"}
          autoFocus
          required={required}
          name={name}
        />
        <FormFeedback className="animated fadeIn" valid>
          Súper
        </FormFeedback>
        <FormFeedback>Ingresa 10 dígitos</FormFeedback>
        <FormText>{longQ}</FormText>
      </Col>
    </FormGroup>
  );
}

export default InputHandle;
