import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/signup-actions';
import { set_user_login, set_token_login } from '../actions/login-actions';
import { set_status_user } from '../actions/user-actions';
import { add_toast } from '../actions/toasts-actions';
import { api_public, local } from '../../services';
import { getStateLang, getSignupStopFetch } from './utils';

function* postSignupData({ dataSignup }) {
  yield put(actions.reset_signup());
  yield put(actions.request_signup());
  const lang = yield getStateLang();
  const stopFetchOnEnd = yield getSignupStopFetch();
  try {
    const signup = yield call(api_public.postSignUp, dataSignup);
    console.log({ signup });
    yield call(local.setLocalToken, signup.token);
    yield put(set_user_login(signup.user));
    yield put(set_token_login(signup.token));
    if (signup.error) {
      yield put(actions.error_signup(signup.error[lang]));
      yield put(
        add_toast({
          text: signup.error[lang],
          error: true
        })
      );
      // yield put(actions.cancel_signup())
    } else {
      yield put(actions.success_signup(signup.message[lang]));
      yield put(
        add_toast({
          error: false,
          text: signup.message[lang]
        })
      );
      yield put(set_user_login(signup.user));
      yield put(actions.set_proceed_to_verify(true));
    }
  } catch (error) {
    yield put(actions.error_signup(error));
    yield put(
      add_toast({
        text: error.toString(),
        error: true
      })
    );
  } finally {
    if (stopFetchOnEnd) {
      yield put(actions.cancel_signup());
    }
  }
}

function* postPreregisterData({ dataSignup }) {
  yield put(actions.reset_signup());
  yield put(actions.request_signup());
  const lang = yield getStateLang();
  try {
    const signup = yield call(api_public.postSignUp, dataSignup);
    if (signup.error) {
      yield put(actions.error_signup(signup.error[lang]));
      yield put(
        add_toast({
          text: signup.error[lang],
          error: true
        })
      );
      yield put(actions.cancel_signup());
    } else {
      yield put(actions.success_signup(signup.message[lang]));
      yield put(
        add_toast({
          error: false,
          text: signup.message[lang]
        })
      );
      yield put(actions.cancel_signup());
    }
  } catch (error) {
    yield put(actions.error_signup(error));
    yield put(
      add_toast({
        text: error.toString(),
        error: true
      })
    );
    yield put(actions.cancel_signup());
  }
}

function* postVerifyData({ dataVerify }) {
  yield put(actions.request_signup());
  try {
    const lang = yield getStateLang();
    const signup = yield call(api_public.postVerify, dataVerify);
    console.log('signup', signup);

    if (signup.error) {
      yield put(actions.error_signup(signup.error[lang]));
      yield put(
        add_toast({
          text: signup.error[lang],
          error: true
        })
      );
      yield put(actions.cancel_signup());
    } else {
      yield put(actions.success_signup(signup.message[lang]));
      yield call(local.setLocalToken, signup.token);
      yield put(set_user_login(signup.user));
      yield put(set_token_login(signup.token));
      yield put(
        add_toast({
          error: false,
          text: signup.message[lang]
        })
      );
      yield put(actions.set_proceed_to_checkout(true));
      yield put(actions.cancel_signup());
      yield put(set_status_user('ACTIVE'));
    }
  } catch (error) {
    yield put(actions.error_signup(error));
    yield put(
      add_toast({
        text: error.toString(),
        error: true
      })
    );
    yield put(actions.cancel_signup());
  }
}

export function* watchSignup() {
  yield takeLatest(actions.START_SIGNUP, postSignupData);
  yield takeLatest(actions.START_PREREGISTER, postPreregisterData);
  yield takeLatest(actions.START_VERIFY_SIGNUP, postVerifyData);
}
