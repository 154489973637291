import React from 'react';
import { connect } from 'react-redux';
import { Submenu } from '../../components/platform/sections';
import { set_sel_form } from '../../redux/actions/platform-actions';

function SubmenuCont({ set_sel_form, formToSet, formToSetCategory, ...rest }) {
  const createAction = () => {
    set_sel_form(formToSet);
  };
  const createCategory = () => {
    set_sel_form(formToSetCategory);
  };
  return (
    <Submenu
      createAction={createAction}
      createCategory={createCategory}
      {...rest}
    />
  );
}

const mapStateToProps = ({ platformReducer, globalReducer: { lang } }) => ({
  ...platformReducer,
  lang
});

const mapActionsToProps = {
  set_sel_form
};

export default connect(mapStateToProps, mapActionsToProps)(SubmenuCont);
