import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  Button,
  ButtonGroup,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remEmpty } from '../../utils';
import {
  DEBOUNCE_TIME,
  PAGINATION_ITEMS,
  PAGINATION_LINKS
} from '../../constants';
import { useDebounce } from '../../hooks';
import '../../style/editable_table.css';

function EditableTable({
  startUpdateRow,
  editAction,
  tableElements = [],
  rowDataKeys = [],
  searchEnabled = [],
  className,
  colHead,
  imgFolder = 'img'
}) {
  console.log(tableElements);
  const [formVals, setFormVals] = useState(
    rowDataKeys.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {})
  );
  const [editRowId, setEditRow] = useState('');
  const [search, setSearch] = useState('');
  const [sorted, setSorted] = useState({ key: '', dir: false });
  const [pagination, setPagination] = useState(0);
  const [paginIndex, setPagIndex] = useState(0);
  const [pageCount, setPageCount] = useState(
    tableElements ? Math.ceil(tableElements.length / PAGINATION_ITEMS) : 0
  );
  const searchDebounce = useDebounce(search, DEBOUNCE_TIME.SEARCH);

  const pagSect = Math.ceil(pageCount / PAGINATION_LINKS);

  const decPag = () => {
    const nextIndex = paginIndex - 1;
    if (nextIndex >= 0) setPagIndex(nextIndex);
  };

  const incPag = () => {
    const nextIndex = paginIndex + 1;
    if (nextIndex < pagSect) setPagIndex(nextIndex);
  };

  const handleSetEdit = (id) => {
    setEditRow(id);
  };

  const getValue = ({ target: { value, name } }) => {
    setFormVals({
      ...formVals,
      [name]: value
    });
  };

  const handleGetSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleDoneEdit = () => {
    const cleanVals = remEmpty(formVals);
    startUpdateRow(editRowId, cleanVals);
    setEditRow('');
  };

  const getInputType = (detVal) => {
    switch (typeof detVal) {
      case 'number':
        return 'number';
      default:
        return 'text';
    }
  };

  const handleSorting = (key) => {
    setSorted({
      key,
      dir: !sorted.dir
    });
  };

  const resetSorted = () => {
    setSorted({
      key: '',
      dir: false
    });
  };

  const toggleActive = (productId, active) => {
    startUpdateRow(productId, { active: !active });
  };

  const renderTableElements = useMemo(() => {
    const filterFunction = (el) => {
      if (searchDebounce !== '') {
        return Object.entries(el)
          .filter(([key]) => searchEnabled.indexOf(key) > -1)
          .some(([key, val]) => {
            switch (typeof val) {
              case 'number':
                return (
                  val
                    .toString()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(search.toLowerCase()) !== -1
                );
              case 'string':
                return (
                  val
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(search.toLowerCase()) !== -1
                );
              default:
                return true;
            }
          });
      } else {
        return true;
      }
    };

    const sortingFunction = (a, b) => {
      if (sorted.key !== '') {
        const sortType = tableElements[0][sorted.key];
        if (typeof sortType === 'number') {
          if (sorted.dir) return a[sorted.key] - b[sorted.key];
          return b[sorted.key] - a[sorted.key];
        } else if (typeof sortType === 'string') {
          const valA = sorted.dir ? a[sorted.key] : b[sorted.key]; // ignore upper and lowercase
          const valB = sorted.dir ? b[sorted.key] : a[sorted.key]; // ignore upper and lowercase
          valA.toUpperCase();
          valB.toUpperCase();
          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }
          // names must be equal
          return 0;
        }
      }
      return a.shortname - b.shortname;
    };
    const createFilteredEls = (els) => {
      const toSortFilter = els
        ?.sort(sortingFunction)
        ?.filter(filterFunction)
        ?.slice(
          pagination * PAGINATION_ITEMS,
          (pagination + 1) * PAGINATION_ITEMS
        );
      return toSortFilter;
    };
    return createFilteredEls(tableElements);
  }, [
    pagination,
    search,
    searchDebounce,
    searchEnabled,
    sorted.dir,
    sorted.key,
    tableElements
  ]);

  useEffect(() => {
    setPagIndex(0);
    if (renderTableElements && tableElements) {
      if (searchDebounce !== '') {
        setPageCount(Math.ceil(renderTableElements.length / PAGINATION_ITEMS));
      } else {
        setPageCount(Math.ceil(tableElements.length / PAGINATION_ITEMS));
      }
    }
  }, [renderTableElements, searchDebounce, tableElements]);

  return (
    <Card className={className} body>
      <Row className="mb-4">
        <Col xs={6}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FontAwesomeIcon icon="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={handleGetSearch}
              value={search}
              disabled={
                !tableElements || (tableElements && !tableElements.length)
              }
              placeholder="búsqueda"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {tableElements && tableElements.length ? (
            <Table responsive>
              <thead>
                <tr>
                  {/* {colHead.map(({ name }, i) => (
                    <th key={i}>{name}</th>
                  ))} */}
                  {Object.entries(tableElements[0])
                    .filter(([enk]) => rowDataKeys.indexOf(enk) > -1)
                    .map(([k, v], o) => (
                      <th className="text-capitalize" key={o}>
                        {Array.isArray(v) && v.length ? (
                          colHead ? (
                            colHead[o]
                          ) : (
                            k
                          )
                        ) : (
                          <span onClick={() => handleSorting(k)}>
                            {colHead ? colHead[o] : k}
                          </span>
                        )}
                        {sorted.key === k ? (
                          <>
                            {sorted.dir ? (
                              <span>
                                <FontAwesomeIcon
                                  className="ml-1"
                                  icon="caret-down"
                                />
                              </span>
                            ) : (
                              <span>
                                <FontAwesomeIcon
                                  className="ml-1"
                                  icon="caret-up"
                                />
                              </span>
                            )}
                            <Button
                              className="ml-1"
                              size="sm"
                              onClick={resetSorted}
                              close
                            />
                          </>
                        ) : null}
                      </th>
                    ))}
                  <th colSpan="2">Acciones</th>
                </tr>
              </thead>

              <tbody>
                {renderTableElements.map((el, i) => (
                  <tr
                    className={el.active ? '' : 'text-muted bg-light'}
                    key={el._id || i}
                  >
                    {Object.entries(el)
                      .filter(([enk]) => rowDataKeys.indexOf(enk) > -1)
                      .map(([k, v], o) => (
                        <td key={o}>
                          {editRowId === el._id ? (
                            Array.isArray(v) && v.length ? (
                              v[0].indexOf(imgFolder) > -1 ? (
                                <img
                                  className="img-fluid table_img"
                                  src={v[1] === undefined ? v[0] : v[1]}
                                  alt="prod"
                                />
                              ) : (
                                v.slice(-1)[0].status
                              )
                            ) : (
                              <Input
                                type={getInputType(v)}
                                name={k}
                                value={formVals[k]}
                                placeholder={v}
                                onChange={getValue}
                              />
                            )
                          ) : (
                            <>
                              {Array.isArray(v) && v.length ? (
                                v[0].indexOf(imgFolder) > -1 ? (
                                  <img
                                    className="img-fluid table_img"
                                    src={v[1] === undefined ? v[0] : v[1]}
                                    alt="prod"
                                  />
                                ) : (
                                  v.slice(-1)[0].status
                                )
                              ) : (
                                v
                              )}
                            </>
                          )}
                        </td>
                      ))}
                    <td>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            editAction(el._id);
                          }}
                          color="secondary"
                          outline
                          size="sm"
                        >
                          Editar
                        </Button>

                        {editRowId === el._id && (
                          <Button
                            onClick={handleDoneEdit}
                            color="primary"
                            outline
                            size="sm"
                          >
                            Hecho
                          </Button>
                        )}
                      </ButtonGroup>
                    </td>
                    <td>
                      <Button
                        onClick={() => toggleActive(el._id, el.active)}
                        size="sm"
                        className="ml-1"
                        color="danger"
                        outline
                      >
                        {el.active ? 'Desactivar' : 'Activar'}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h3>No hay elementos</h3>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Pagination>
          {pageCount > PAGINATION_LINKS && (
            <PaginationItem disabled={paginIndex === 0}>
              <PaginationLink first onClick={decPag} />
            </PaginationItem>
          )}
          {[...Array(pageCount).keys()]
            .slice(
              paginIndex * PAGINATION_LINKS,
              (paginIndex + 1) * PAGINATION_LINKS
            )
            .map((el, i) => (
              <PaginationItem active={el === pagination} key={i}>
                <PaginationLink onClick={() => setPagination(parseInt(el, 10))}>
                  {parseInt(el, 10) + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          {pageCount > PAGINATION_LINKS && (
            <PaginationItem disabled={paginIndex === pagSect - 1}>
              <PaginationLink onClick={incPag} last />
            </PaginationItem>
          )}
        </Pagination>
      </Row>
    </Card>
  );
}

export default EditableTable;
