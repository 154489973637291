import React from "react";
import { Row, Col } from "reactstrap";
import { ToggleText } from "../pieces";

function SecBarAd({ className, links, img, title, subtitle }) {
  return (
    <Row className={`${className} w-100`}>
      <Col className="p-2">
        <h6 className="text-center">
          <b>{title}</b>
        </h6>
        <ToggleText links={links} light maxChars={80}>
          {subtitle}
        </ToggleText>
        <img className="img-fluid" src={img.src} alt={img.alt} />
      </Col>
    </Row>
  );
}

export default SecBarAd;
