import React from "react";
import { connect } from "react-redux";
import { ProfileNav } from "../../components/platform/elements";
import { FORM_NAMES } from "../../constants/platform";
import { set_sel_form } from "../../redux/actions/platform-actions";

function ProfileNavCont({ set_sel_form, ...rest }) {
  const fillProfile = () => {
    set_sel_form(FORM_NAMES.PROFILE_UPDATE);
  };
  return <ProfileNav fillProfile={fillProfile} {...rest} />;  
}

const mapStateToProps = ({ loginReducer }) => ({
  ...loginReducer
});

const mapActionsToProps = {
  set_sel_form
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileNavCont);
