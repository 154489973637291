import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  FormText
} from 'reactstrap';
import { LOCAL_MESSAGES } from '../../constants';
import { LABELS_FORM_LOGIN } from '../../constants/platform';
import { AnimFailSucc } from '../elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ResetPasswForm({
  addToast,
  lang,
  startResetP,
  error,
  message,
  isFetching,
  cancelLogin,
  reset_pass_token
}) {
  const [formVals, setFormVals] = useState({
    passW: '',
    passWConf: ''
  });
  const [showPass, setShowPass] = useState(false);
  const passWname = 'passW';
  const passWconfName = 'passWConf';
  const getValue = ({ target: { value, name } }) => {
    setFormVals({ ...formVals, [name]: value });
  };
  const { passW, passWConf } = formVals;
  const handleForm = (e) => {
    e.preventDefault();
    if (passW === '') {
      addToast({
        text: LOCAL_MESSAGES.EMPTY_PASS[lang],
        error: true
      });
    } else if (passW.length < 6) {
      addToast({
        text: LOCAL_MESSAGES.SHORT_PASS[lang],
        error: true
      });
    } else if (passW !== passWConf) {
      addToast({
        text: LOCAL_MESSAGES.PASSW_INVALID[lang],
        error: true
      });
    } else if (passWConf !== '' && passW === passWConf) {
      setFormVals({
        passW: '',
        passWConf: ''
      });
      startResetP({
        new_password: passW,
        reset_password_token: reset_pass_token
      });
    }
  };

  const showPassW = () => {
    setShowPass(!showPass);
  };
  return (
    <Col xs={12} md={6} className="p-5">
      <Card className="animated fadeInPlace my-5">
        <CardHeader className="font-weight-bold text-center">
          {LABELS_FORM_LOGIN.RESET_PASS[lang]}
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleForm}>
            {isFetching ? (
              <Row>
                <Col>
                  <AnimFailSucc error={error} endAnim={cancelLogin} />
                </Col>
              </Row>
            ) : (
              <>
                <FormGroup>
                  <Label>{LABELS_FORM_LOGIN.PASS_EIGHT_CH[lang]}</Label>
                  <InputGroup>
                    <Input
                      onChange={getValue}
                      className=""
                      required
                      minLength="6"
                      // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      autoComplete="new-password"
                      placeholder="contraseña"
                      value={passW}
                      type={showPass ? 'text' : 'password'}
                      name={passWname}
                      id={passWname}
                    />
                    <InputGroupAddon addonType="append">
                      <Button onClick={showPassW} active={showPass}>
                        <FontAwesomeIcon
                          icon={showPass ? 'eye' : 'eye-slash'}
                        />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormText>{LABELS_FORM_LOGIN.PASS_RESTR[lang]}</FormText>
                </FormGroup>
                <FormGroup>
                  <Label>
                    <i className="fas fa-lock" />
                    &nbsp;{LABELS_FORM_LOGIN.CONFIRM_PASS[lang]}
                  </Label>
                  <Input
                    onChange={getValue}
                    className=""
                    required
                    placeholder="confirme la contraseña"
                    autoComplete="new-password"
                    value={passWConf}
                    type={showPass ? 'text' : 'password'}
                    name={passWconfName}
                    id={passWconfName}
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="primary" block type="submit">
                    {LABELS_FORM_LOGIN.RESET_PASS[lang]}
                  </Button>
                </FormGroup>
              </>
            )}
          </Form>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </Col>
  );
}

export default ResetPasswForm;
